/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import axios from "axios";
import FeedPost from "./feedPost";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  _,
  __,
  authentication,
  toaster,
} from "../../Controller/main_controller";
import Select from "react-select";
import Rsidebar from "../../Component/Rasidebar";
import Lsidebar from "../../Component/Lasidebar";
import Nav from "../../Component/Nav";
import EmojiPicker from "emoji-picker-react";
import { FaUserTag } from "react-icons/fa";
import { BsFillEmojiSmileFill } from "react-icons/bs";
import { toast } from "react-toastify";
import { MentionsInput, Mention } from "react-mentions";
import defaultStyle from "./srcdefaultStyle";
import defaultMentionStyle from "./srcdefaultMentionStyle";

export default function Home() {
  const [techno, setTechno] = useState([]);
  const [technoData, SetTechnoData] = useState([]);
  const [userDetails, SetUserDetails] = useState([]);
  const [getpost, setGetpost] = useState([]);
  const [thoughts, setThoughts] = useState(""); // this is for post value
  const [postData, setpostData] = useState(""); // this is for post value
  const [fromindex, setFromIndex] = useState(0); // this is for post value
  const [dataLength, setdataLength] = useState(0);
  const [hasMore, sethasMore] = useState(false);
  const [myBuddiesList, setMyBuddiesList] = useState([]);
  const [tagBuddies, settagBuddies] = useState([]);
  const [buddiesSearchDetails, setbuddiesSearchDetails] = useState("");
  const [feedPost, setFeedPost] = useState(false);
  const [emojiStore, setEmojiStore] = useState("");

  // Feed Post Tag Buddies Part //
  const fetchUsers = (query, callback) => {
    if (!query) return;

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/tagDetails/showMyBuddysList`,
        {
          search_details: query,
        },
        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      )
      .then((res) => {
        const filteredUsers = [];

        res.data.employeeList.map((user) =>
          filteredUsers.push({
            display:
              user.employee_details[0].first_name +
              " " +
              user.employee_details[0].last_name,
            id: user.employee_details[0]._id,
          })
        );

        callback(filteredUsers);
      })
      .catch((error) => {
        // console.error(error);
      });
  };

  const handleChange = (event, newValue, newPlainTextValue, mentions) => {
    setThoughts(event.target.value);
    setpostData(newPlainTextValue);

    let tagBuddiesArray = [];
    for (let index = 0; index < mentions.length; index++) {
      tagBuddiesArray.push({
        employee_code: mentions[index].id,
      });
    }
    settagBuddies(tagBuddiesArray);

    // console.log(newValue, newPlainTextValue, mentions)
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      const userData = await authentication();
      SetUserDetails(userData);
    };
    fetchUserDetails();
  }, []);

  useEffect(() => {
    document.title = "Jobs N More";
    getMyBuddiesList();
  }, []);

  //Get tech list
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/home/getTechList`, {
        headers: {
          "auth-token": localStorage.getItem("authToken"),
        },
      })
      .then((res) => {
        let techList = res.data.techList;

        setTechno({
          value: techList[0]._id,
          label: techList[0].tech_name,
        });

        SetTechnoData([]);

        techList.map((item) => {
          SetTechnoData((pre) => [
            ...pre,
            {
              value: item._id,
              label: item.tech_name,
            },
          ]);
        });
      })
      .catch((error) => {
        // console.error(error);
      });
  }, []);

  const getMyBuddiesList = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/tagDetails/showMyBuddysList`,
        {
          search_details: buddiesSearchDetails,
        },
        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      )
      .then((res) => {
        if (res.data.employeeList.length > 0) {
          setMyBuddiesList(res.data.employeeList);
        } else {
          setMyBuddiesList([]);
        }
      })
      .catch((error) => {
        // console.error(error);
      });
  };

  const feedPostTagBuddies = () => {
    let tagBuddiesArray = [];
    for (let index = 0; index < __("feed_tag_buddies").length; index++) {
      if (__("feed_tag_buddies")[index].checked === true) {
        tagBuddiesArray.push({
          employee_code:
            __("feed_tag_buddies")[index].getAttribute("data-employee-id"),
        });
      }
    }
    settagBuddies(tagBuddiesArray);
  };

  // Save post data
  const handlePost = () => {
    const toastId = toaster("load");
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/home/saveFeedsPost`,
        {
          tech_code: techno.value,
          post_details: postData,
          tag_buddies: tagBuddies,
        },
        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      )
      .then((response) => {
        toaster(response.data.status, toastId, response.data.mssg);

        if (response.data.status === "success") {
          for (let index = 0; index < __("feed_tag_buddies").length; index++) {
            __("feed_tag_buddies")[index].checked = false;
          }
          feedPostTagBuddies();
          setGetpost([]);
          setFromIndex(0);
          setThoughts("");
          setpostData("");
          setFeedPost(true);
        }
      })
      .catch((error) => {
        // console.error(error);
        toaster(
          "error",
          toastId,
          "Some Error Occurred. Please Try After Some Time"
        );
      });
  };

  // Get feed post list by default

  // useEffect(()=>{
  // technoChange()
  // },[])

  const refreshPost = () => {
    setGetpost([]);
    setFromIndex(0);
    setFeedPost(true);
  };

  useEffect(() => {
    setGetpost([]);
    setFromIndex(0);
    technoChange();
  }, [techno]);

  useEffect(() => {
    if (feedPost === true) {
      setGetpost([]);
      setFromIndex(0);
      setFeedPost(false);
      technoChange();
    }
  }, [feedPost]);

  // Get feed post list

  const technoChange = () => {
    // const toastId = toaster('load');

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/home/getFeedsPost`,
        {
          tech_code: techno.value,
          from_index: fromindex,
        },
        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      )
      .then((response) => {
        // toaster("close", toastId);

        if (response.data.status === "success") {
          // console.log(response.data);

          if (response.data.feedsList.length > 0) {
            let newIndex = fromindex + response.data.limit;
            setFromIndex(newIndex);
            setGetpost((pre) => [...pre, ...response.data.feedsList]);
            let nextDataLength = newIndex + response.data.limit;
            setdataLength(nextDataLength);
            sethasMore(true);
          } else {
            setdataLength(fromindex);
            sethasMore(false);
          }
        }
      })
      .catch((error) => {
        // console.error(error);
      });
  };

  const onEmojiClick = (e) => {
    const sym = e.unified.split("-");
    const codeArray = [];
    sym.forEach((el) => {
      codeArray.push("0x" + el);
    });
    let emoji = String.fromCodePoint(...codeArray);
    setEmojiStore(emoji);

    // console.log(emojiObject);
    // setmssg((prevInput) => prevInput + emojiObject.emoji);
    // setShowPicker(false);
  };

  useEffect(() => {
    if (emojiStore != "") {
      setThoughts(thoughts + emojiStore);
      setpostData(postData + emojiStore);
    }
  }, [emojiStore]);

  return (
    <div>
      <Nav />
      <div className="py-2">
        <div className="container-fluid">
          <div className="row justify-content-around">
            <main className="col col-xl-8 order-xl-2 col-lg-12 order-lg-1 col-md-12 col-sm-12 col-12 pt-59">
              <div className="box shadow-sm border rounded bg-white mb-3 osahan-share-post">
                <div className="wrapper" style={{ overflowX: "inherit" }}>
                  <div className="col-lg-12 ">
                    <div
                      className="justify-content-around dropdown-technology"
                    >
                      <div className="align-items-center dropdown-technology-dflex">
                        <b>Choose Your Technology &nbsp;&nbsp;</b>
                        <img
                          className="dropdown-menu-img"
                          src="img/icon/technology.png"
                          alt=""
                        />
                        <div className="select-home-head mtm-2">
                          <Select
                            value={techno}
                            onChange={(e) => {
                              setGetpost([]);
                              setFromIndex(0);
                              setTechno(e);
                            }}
                            options={technoData}
                            isClearable={false}
                            isSearchable={true}
                            name="technology"
                            id="technology"
                            classNamePrefix="react-select"
                            placeholder="Select Technology"
                            noOptionsMessage={() => "No Technology Found"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-content"
                  id="myTabContent"
                  style={{ zIndex: "9" }}
                >
                  <div
                    className="tab-pane fade show active"
                    id="home"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    <div className="p-3 d-flex w-100" href="#">
                      <div className="dropdown-list-image mr-3">
                        <img
                          className="rounded-circle"
                          src="img/icon/smile.svg"
                          alt=""
                        />
                        <div className="status-indicator bg-success"></div>
                      </div>
                      <div className="w-100">
                        <MentionsInput
                          placeholder="Write your thoughts..."
                          className="shadow-none"
                          value={thoughts}
                          onChange={handleChange}
                          style={defaultStyle}
                          onKeyDown={(e) =>
                            e.key === "Enter" ? handlePost(e) : ""
                          }
                          a11ySuggestionsListLabel={"Suggested Mentions"}
                        >
                          <Mention
                            trigger="#"
                            data={fetchUsers}
                            style={defaultMentionStyle}
                          />
                        </MentionsInput>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border-top p-3 d-flex align-items-center">
                  <div
                    className="mr-auto"
                    style={{
                      marginLeft: 15,
                      fontSize: 16,
                      fontWeight: "bold",
                      color: "#888edd",
                      cursor: "pointer",
                    }}
                  >
                    <a
                      href="#"
                      className="text-link mr-2"
                      data-toggle="modal"
                      data-target="#myBuddiesList"
                      style={{ display: "none" }}
                    >
                      <FaUserTag className="text-primary" size={30} />
                    </a>

                    <div className="btn-group dropup">
                      <a
                        style={{ cursor: "pointer" }}
                        className="text-link mr-2"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        data-bs-auto-close="outside"
                      >
                        <BsFillEmojiSmileFill
                          className="text-success"
                          size={25}
                        />
                      </a>
                      <div
                        className="dropdown-menu"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <EmojiPicker
                          pickerStyle={{ width: "100%" }}
                          onEmojiClick={onEmojiClick}
                        />
                      </div>
                    </div>
                    {tagBuddies.length > 0
                      ? "#" + tagBuddies.length + "BuddiesTag"
                      : null}
                  </div>
                  <div className="flex-shrink-1">
                    <button
                      type="button"
                      className="btn btn-sm post-btn"
                      onClick={(e) => {
                        handlePost(e);
                        // setGetpost([]);
                      }}
                    >
                      Post
                    </button>
                  </div>
                </div>
              </div>

              <div
                style={{
                  overflowX: "hidden",
                }}
              >
                <InfiniteScroll
                  dataLength={dataLength} //This is important field to render the next data
                  next={technoChange}
                  hasMore={hasMore}
                  loader={<h4>Loading...</h4>}
                  endMessage={
                    <p style={{ textAlign: "center", color:"white"}}>
                      <b>Yay! You have seen it all</b>
                    </p>
                  }
                >
                  {getpost.length > 0 &&
                    getpost.map((data, i2) => {
                      return (
                        <FeedPost
                          feed_post_data={data}
                          refreshPost={refreshPost}
                          feed_num={i2}
                        />
                      );
                    })}
                </InfiniteScroll>
              </div>

              <div className="text-center"></div>
            </main>

            <Lsidebar userDetails={userDetails} />
            <Rsidebar />
          </div>
        </div>
      </div>

      {/* Show My Buddies List Modal  */}

      <div
        class="modal fade"
        id="myBuddiesList"
        data-backdrop="static"
        data-keyboard="true"
        tabindex="-1"
        aria-labelledby="myBuddiesListLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div className="modal-header" style={{ padding: "10px 15px" }}>
              <h5 class="modal-title" id="myBuddiesListLabel">
                Here you can tag your buddies
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              {myBuddiesList.length > 0 &&
                myBuddiesList.map((data, i3) => {
                  return (
                    <div
                      className="p-3 d-flex align-items-center border-bottom osahan-post-header"
                      key={i3}
                    >
                      <a>
                        <input
                          type="checkbox"
                          className="mr-3 feed_tag_buddies"
                          id={`post_tag_buddies_${i3}`}
                          data-employee-id={data.employee_details[0]._id}
                          onChange={feedPostTagBuddies}
                        />
                      </a>
                      <label
                        for={`post_tag_buddies_${i3}`}
                        className="tag_list_label"
                      >
                        <a className="dropdown-list-image">
                          <img
                            className="rounded-circle show-image"
                            src={`${process.env.REACT_APP_API_URL}/${data.employee_details[0].employee_image}`}
                            alt
                          />
                        </a>
                      </label>
                      <label
                        className="font-weight-bold"
                        for={`post_tag_buddies_${i3}`}
                      >
                        <a className="text-truncate">
                          {data.employee_details[0].first_name}{" "}
                          {data.employee_details[0].last_name}
                        </a>
                        <div className="small text-gray-500">
                          {data.employee_details[0].employee_type}
                        </div>
                      </label>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
