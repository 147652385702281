import React, { useState, useEffect } from "react";
import { _, authentication, toaster } from "../../Controller/main_controller";
import axios from "axios";
import Nav from "../../Component/Nav";
import Rsidebar from "../../Component/Rasidebar";
import Lsidebar from "../../Component/Lasidebar";
import MyFeedPost from "./MyFeedPost";

const PostDetails = () => {
  const [userDetails, SetUserDetails] = useState([]);
  const [getPost, setGetpost] = useState([]);
  useEffect(() => {
    const fetchUserDetails = async () => {
      const userData = await authentication();
      SetUserDetails(userData);
    };
    fetchUserDetails();
  }, []);

  useEffect(() => {
    var path = window.location.pathname;
    var parts = path.split("/");
    var desiredValue = parts[2]; // The value you're looking for
    console.log(desiredValue);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/home/getFeedsPostDetails`,
        {
          feeds_post_code: desiredValue,
        },
        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      )
      .then((response) => {
        console.log(response);
        if (response.data.status === "success") {

          setGetpost(response.data.feedsList);
        }
        if (response.data.status === "error") {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  return (
    <>
      <Nav />
      <div className="py-2">
        <div className="container-fluid">
          <div className="row justify-content-around">
            <main className="col col-xl-8 order-xl-2 col-lg-12 order-lg-1 col-md-12 col-sm-12 col-12 mt-54">

              {getPost.length > 0 &&
                getPost.map((data, i) => {
                  return <MyFeedPost feed_post_data={data} feed_num={i} />;
                })}

            </main>

            <Lsidebar userDetails={userDetails} />
            <Rsidebar />
          </div>
        </div>
      </div>
    </>
  );
};

export default PostDetails;
