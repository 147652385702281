import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { GrAttachment } from "react-icons/gr";

const ChatBox = ({ chatUserDetails, getLastMessage, chat_employee_code, userDetails, setchatEmployeeCode }) => {
  
  const [chatDetails, setchatDetails] = useState(chatUserDetails!=null && getLastMessage!=null ? [chatUserDetails, getLastMessage] : []);

  useEffect(() => {
    console.log(chatDetails);
  }, []);

  //Get Chat list
  // const getChatDetails = () => {
  //   axios
  //     .post(
  //       `${process.env.REACT_APP_API_URL}/api/message/getChatDetails`,
  //       {
  //         chat_employee_code: chat_employee_code,
  //       },
  //       {
  //         headers: {
  //           "auth-token": localStorage.getItem("authToken"),
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       if (res.data.chatUserDetails && res.data.getLastMessage) {
  //         setchatDetails([res.data.chatUserDetails, res.data.getLastMessage]);
  //       }
  //     })
  //     .catch((error) => {
  //       toast.error("Some Error Occurred. Please Try After Some Time", {
  //         position: toast.POSITION.TOP_RIGHT,
  //       });
  //     });
  // };

  const selectEmployee = (employee_id) => {
    setchatEmployeeCode("");

    setTimeout(function () {
      setchatEmployeeCode(employee_id);
    }, 10);
  };

  return (
    <>
      {chatDetails.length > 0 ? (
        <div
          onClick={() => selectEmployee(chatDetails[0]._id)}
          className="p-3 d-flex align-items-center border-bottom osahan-post-header overflow-hidden"
          style={{ cursor: "pointer" }}
        >
          <div className="dropdown-list-image mr-3">
            <img
              className="rounded-circle show-image"
              src={`${process.env.REACT_APP_API_URL}/${chatDetails[0].employee_image}`}
              alt=""
            />
          </div>
          <div className="font-weight-bold mr-1 overflow-hidden">
            <div className="text-truncate">
              {chatDetails[0].first_name} {chatDetails[0].last_name}
            </div>

            {(() => {
              if (chatDetails[1].from_employee_code === userDetails._id) {
                return (
                  <div className="small text-truncate overflow-hidden text-black-50">
                    {chatDetails[1].mssg.length > 0 ? (
                      chatDetails[1].mssg
                    ) : (
                      <GrAttachment size={15} className="text-success" />
                    )}
                  </div>
                );
              } else {
                return (
                  <>
                    <div
                      className={`small text-truncate overflow-hidden text-black-50 ${
                        chatDetails[1].seen === "No" ? "text-highlight" : ""
                      } `}
                    >
                      {chatDetails[1].mssg}
                    </div>
                  </>
                );
              }
            })()}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ChatBox;
