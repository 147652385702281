/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/img-redundant-alt */

import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import MyFeedPost from "./MyFeedPost";
import { _, authentication, toaster } from "../../Controller/main_controller";
import Nav from "../../Component/Nav";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

import Avatar from "react-avatar-edit";

export default function Profile() {
  const refElement = useRef("");
  const [userName, setUserName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [employeeType, setEmployeeType] = useState("");
  const [employeeImage, setEmployeeImage] = useState("");
  const [email, setEmail] = useState("");
  const [phnmbr, setPhNumber] = useState("");
  const [lookingjob, setLookingJob] = useState("");
  const [noticePeriod, setNoticePeriod] = useState("");
  const [immediateJoiner, setImmediateJoiner] = useState("");
  const [fresher, setFresher] = useState("");
  const [cv, setCv] = useState("");
  const [location, setLocation] = useState("");
  const [company, setCompany] = useState("");
  const [technology, setTechnology] = useState("");
  const [experience, setExperience] = useState("");
  const [achievement, setAchievement] = useState("");
  const [progressbar, setProgressBar] = useState(0);
  const [progressbar1, setProgressBar1] = useState(0);
  const [progressbar2, setProgressBar2] = useState(0);
  const [getpost, setGetpost] = useState([]);
  const [employeeStatus, setEmployeeStatus] = useState("");

  const [userDetails, SetUserDetails] = useState([]);

  //Download a file
  const fileUrl = `${process.env.REACT_APP_API_URL}/${cv}`;
  const downloadFile = (url) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const blobURL = window.URL.createObjectURL(new Blob([blob]));
        const fileName = url.split("/").pop();
        const aTag = document.createElement("a");
        aTag.href = blobURL;
        aTag.setAttribute("download", fileName);
        document.body.appendChild(aTag);
        aTag.click();
        aTag.remove();
      });
  };

  //avatar

  // const [src, setSrc] = useState(null);
  const [preview, setPreview] = useState(null);

  const onClose = () => {
    setPreview(null);
  };
  const onCrop = (view) => {
    setPreview(view);
  };

  const saveImage = () => {
    const toastId = toaster("load");
    document.getElementById("progress").style.display = "flex";

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/profileDetails/insertAvatar`,
        {
          avatar: preview,
        },
        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
          },
          onUploadProgress: (event) => {
            setProgressBar(Math.round(100 * event.loaded) / event.total);
          },
        }
      )
      .then((res) => {
        toaster(res.data.status, toastId, res.data.mssg);
        if (res.data.status === "success") {
          refElement.current.click();
          getProfileDetails();
          getFeedsPost();
          setTimeout(function () {
            document.getElementById("progress").style.display = "none";
          }, 900);
        }
        if (res.data.status === "error") {
          setTimeout(function () {
            document.getElementById("progress").style.display = "none";
          }, 900);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      const userData = await authentication();
      SetUserDetails(userData);
    };
    fetchUserDetails();
    document.title = "My Profile";
  }, []);

  //Fetch profile details
  useEffect(() => {
    getProfileDetails();
  }, []);

  const getProfileDetails = () => {
    // const toastId = toaster('load');
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/profileDetails/getMyProfileDetails`,
        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      )
      .then((res) => {
        setEmployeeStatus(res.data.profileDetails[0].employee_status);
        setUserName(res.data.profileDetails[0].user_name);
        setFirstName(res.data.profileDetails[0].first_name);
        setLastName(res.data.profileDetails[0].last_name);
        setEmployeeType(res.data.profileDetails[0].employee_type);
        setEmployeeImage(res.data.profileDetails[0].employee_image);
        setEmail(res.data.profileDetails[0].email_id);
        setPhNumber(res.data.profileDetails[0].ph_num);
        setLookingJob(res.data.profileDetails[0].looking_job);
        setNoticePeriod(res.data.profileDetails[0].notice_period);
        setImmediateJoiner(res.data.profileDetails[0].immediate_joinner);
        setFresher(res.data.profileDetails[0].fresher);
        setCv(res.data.profileDetails[0].resume);
        setAchievement(res.data.profileDetails[0].achievement);
        setLocation(
          res.data.profileDetails[0].location.length > 0
            ? `${res.data.profileDetails[0].location[0].area},${res.data.profileDetails[0].location[0].city},${res.data.profileDetails[0].location[0].state}`
            : ""
        );
        setCompany(
          res.data.profileDetails[0].company_details.length > 0
            ? res.data.profileDetails[0].company_details[0].company_name
            : ""
        );
        setTechnology(
          res.data.profileDetails[0].technology.length > 0
            ? res.data.profileDetails[0].technology[0].tech_name
            : ""
        );
        setExperience(
          res.data.profileDetails[0].experience_master.length > 0
            ? res.data.profileDetails[0].experience_master[0].experience
            : ""
        );

        if (res.data.profileDetails[0].employee_type === "Hr") {
          document.getElementById("cv").style.display = "none";
          document.getElementById("tech").style.display = "none";
          document.getElementById("lookingjob").style.display = "none";
          document.getElementById("noticePeriod").style.display = "none";
          document.getElementById("immediateJoiner").style.display = "none";
          document.getElementById("fresher").style.display = "none";
          document.getElementById("experience").style.display = "none";
          document.getElementById("location").style.display = "none";
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // const handleFile = (e) => {
  //   const toastId = toaster("load");
  //   document.getElementById("progress").style.display = "flex";

  //   const file = e.target.files[0];
  //   const formdata = new FormData();
  //   formdata.append("employee_image", file);
  //   axios
  //     .post(
  //       `${process.env.REACT_APP_API_URL}/api/profileDetails/updateProfilePhoto`,
  //       formdata,
  //       {
  //         headers: {
  //           "auth-token": localStorage.getItem("authToken"),
  //           "Content-Type": "multipart/form-data",
  //         },
  //         onUploadProgress: (event) => {
  //           setProgressBar(Math.round(100 * event.loaded) / event.total);
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       toaster(res.data.status, toastId, res.data.mssg);
  //       if (res.data.status === "success") {
  //         getProfileDetails();
  //         getFeedsPost();
  //         setTimeout(function () {
  //           document.getElementById("progress").style.display = "none";
  //         }, 900);
  //       }
  //       if (res.data.status === "error") {
  //         setTimeout(function () {
  //           document.getElementById("progress").style.display = "none";
  //         }, 900);
  //       }
  //     })
  //     .catch((err) =>
  //       toaster(
  //         "error",
  //         toastId,
  //         "Some Error Occurred. Please Try After Some Time"
  //       )
  //     );
  // };

  const handleCv = (e) => {
    const toastId = toaster("load");
    document.getElementById("progress1").style.display = "flex";
    const file = e.target.files[0];
    const formdata = new FormData();
    formdata.append("resume", file);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/profileDetails/uploadResume`,
        formdata,
        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (event) => {
            setProgressBar1(Math.round(100 * event.loaded) / event.total);
          },
        }
      )
      .then((res) => {
        toaster(res.data.status, toastId, res.data.mssg);

        if (res.data.status === "success") {
          getProfileDetails();
          setTimeout(function () {
            document.getElementById("progress1").style.display = "none";
          }, 900);
        }
        if (res.data.status === "error") {
          setTimeout(function () {
            document.getElementById("progress1").style.display = "none";
          }, 900);
        }
      })
      .catch((err) =>
        toaster(
          "error",
          toastId,
          "Some Error Occurred. Please Try After Some Time"
        )
      );
  };

  const handleAchievement = (e) => {
    const toastId = toaster("load");
    document.getElementById("progress2").style.display = "flex";
    const file = e.target.files[0];
    const formdata = new FormData();
    formdata.append("achievement", file);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/profileDetails/uploadAchievement`,
        formdata,
        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (event) => {
            setProgressBar2(Math.round(100 * event.loaded) / event.total);
          },
        }
      )
      .then((res) => {
        toaster(res.data.status, toastId, res.data.mssg);
        if (res.data.status === "success") {
          getProfileDetails();
          setTimeout(function () {
            document.getElementById("progress2").style.display = "none";
          }, 900);
        }
        if (res.data.status === "error") {
          setTimeout(function () {
            document.getElementById("progress2").style.display = "none";
          }, 900);
        }
      })
      .catch((err) =>
        toaster(
          "error",
          toastId,
          "Some Error Occurred. Please Try After Some Time"
        )
      );
  };

  // Fetch feed post

  const getFeedsPost = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/profileDetails/getMyFeedsPost`,
        {
          from_index: 0,
        },
        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      )
      .then((response) => {
        if (response.data.status === "success") {
          setGetpost(response.data.feedsList);
        }
        if (response.data.status === "error") {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // Fetch feed post
  useEffect(() => {
    getFeedsPost();
  }, []);

  const removeCv = () => {
    const toastId = toaster("load");
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/profileDetails/removeResume`, {
        headers: {
          "auth-token": localStorage.getItem("authToken"),
        },
      })
      .then((res) => {
        toaster(res.data.status, toastId, res.data.mssg);

        getProfileDetails();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const removeAchievement = () => {
    const toastId = toaster("load");
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/profileDetails/removeAchievement`,
        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      )
      .then((res) => {
        toaster(res.data.status, toastId, res.data.mssg);

        getProfileDetails();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <Nav />
      <div class="py-2">
        <div class="container-fluid ">
          <div class="row">
            <aside class="col col-xl-3 order-xl-1 col-lg-12 order-lg-1 col-12">
              <div
                class="box mb-3 shadow-sm border rounded profile-box text-center mt-54"
                style={{ backgroundColor: "#ffd9f3" }}
              >
                <div class="py-4 px-3 border-bottom">
                  {employeeImage && (
                    <img
                      src={`${process.env.REACT_APP_API_URL}/${employeeImage}`}
                      class="img-fluid mt-2 mb-2 "
                      alt="Responsive image"
                      style={{
                        width: "200px",
                        maxWidth: "100%",
                        height: "200px",
                        borderRadius: "50%",
                      }}
                    />
                  )}

                  <div
                    className="progress"
                    id="progress"
                    style={{ display: "none" }}
                  >
                    <div
                      className="progress-bar progress-bar-striped progress-bar-animated"
                      role="progressbar"
                      aria-label="progressbar"
                      aria-valuenow={progressbar}
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{ width: `${progressbar}%` }}
                    ></div>
                  </div>
                  <div className="p-3 rounded  vh-50 m-auto">
                    {/* <label
                      for="file"
                      className="btn btn-outline-info btn-sm btn-block"
                    >
                      <i class="feather-camera"></i> Upload
                    </label>
                    <input
                      type="file"
                      id="file"
                      accept=".jpg, .jpeg, .png"
                      onChange={handleFile}
                      style={{ display: "none", visibility: "none" }}
                    /> */}
                    <button
                      className="btn btn-outline-info btn-sm btn-block"
                      data-toggle="modal"
                      data-target="#staticBackdrop10"
                    >
                      <i class="feather-camera"></i> Upload
                    </button>
                    <em
                      style={{
                        color: "GrayText",
                      }}
                    >
                      *Only .jpg, .png, .jpeg file accepted
                    </em>
                  </div>

                  {/* <br /> */}

                  <h4 class="font-weight-bold text-dark mb-1 mt-z">
                    {userName}
                  </h4>
                  <p class="mb-0 text-bold">{employeeType}</p>
                  <div style={{ marginRight: "15px" }}>
                    {employeeStatus === "One Year Jobless" ? (
                      <>
                        <h6 class="font-weight-bold text-dark mb-1">
                          <img
                            src="img/icon/status.png"
                            alt=""
                            style={{ height: 35, width: 35 }}
                          />
                          ({employeeStatus})
                        </h6>
                      </>
                    ) : null}
                    {employeeStatus === "Serving Notice Period" ? (
                      <>
                        <h6 class="font-weight-bold text-dark mb-1">
                          <img
                            src="img/icon/icon2.png"
                            alt=""
                            style={{ height: 35, width: 35 }}
                          />
                          ({employeeStatus})
                        </h6>
                      </>
                    ) : null}
                    {employeeStatus === "Looking For Job" ? (
                      <>
                        <h6 class="font-weight-bold text-dark mb-1">
                          <img
                            src="img/icon/icon3.png"
                            alt=""
                            style={{ height: 35, width: 35 }}
                          />
                          ({employeeStatus})
                        </h6>
                      </>
                    ) : null}
                    {employeeStatus === "Immediate Joiner" ? (
                      <>
                        <h6 class="font-weight-bold text-dark mb-1">
                          <img
                            src="img/icon/icon4.png"
                            alt=""
                            style={{ height: 35, width: 35 }}
                          />
                          ({employeeStatus})
                        </h6>
                      </>
                    ) : null}
                  </div>
                </div>


                <div class="col-12 border-bottom p-3 text-left">
                  <h6 class="font-weight-bold text-dark mb-1">
                    <img
                      src="img/icon/user.jpg"
                      alt=""
                      class="icon-image"
                    />
                    {` ${firstName} ${lastName}`}
                  </h6>
                </div>

                <div class="col-12 border-bottom p-3 text-left">
                  <h6 class="font-weight-bold text-dark mb-1 text-overflow-hide">
                    <img
                      src="img/icon/email.jpg"
                      alt=""
                      class="icon-image"
                    />{" "}
                    {email}
                  </h6>
                </div>
                <div class="col-12 p-3 text-left">
                  <h6 class="font-weight-bold text-dark mb-1">
                    <img
                      src="img/icon/call.png"
                      alt=""
                      class="icon-image"
                    />
                    {" " + phnmbr}
                  </h6>
                </div>
                <div class="col-12 border-top p-3 text-left" id="location">
                  <h6 class="font-weight-bold text-dark mb-1">
                    <img
                      src="img/icon/home.jpg"
                      alt=""
                      class="icon-image"
                    />{" "}
                    {location}
                  </h6>
                </div>
                <div class="col-12 border-top p-3 text-left" id="tech">
                  <h6 class="font-weight-bold text-dark mb-1">
                    <img
                      src="img/icon/tech.png"
                      alt=""
                      class="icon-image"
                    />{" "}
                    Skills: {technology}
                  </h6>
                </div>
                <div class="col-12 border-top p-3 text-left" id="lookingjob">
                  <h6 class="font-weight-bold text-dark mb-1">
                    <img
                      src="img/icon/icon3.png"
                      alt=""
                      style={{ height: 25, width: 25 }}
                      class="icon-image"
                    />{" "}
                    Looking For Job: {lookingjob}
                  </h6>
                </div>
                <div class="col-12 border-top p-3 text-left" id="noticePeriod">
                  <h6 class="font-weight-bold text-dark mb-1">
                    <img
                      src="img/icon/icon2.png"
                      alt=""
                      style={{ height: 25, width: 25 }}
                      class="icon-image"
                    />{" "}
                    Notice Period : {noticePeriod}
                  </h6>
                </div>
                <div
                  class="col-12 border-top p-3 text-left"
                  id="immediateJoiner"
                >
                  <h6 class="font-weight-bold text-dark mb-1">
                    <img
                      src="img/icon/icon4.png"
                      alt=""
                      class="icon-image"
                      style={{ height: 25, width: 25 }}
                    />{" "}
                    Immediate Joinner : {immediateJoiner}
                  </h6>
                </div>
                <div class="col-12 border-top p-3 text-left" id="fresher">
                  <h6 class="font-weight-bold text-dark mb-1">
                    <img
                      src="img/icon/ex.png"
                      alt=""
                      class="icon-image"
                    />{" "}
                    Fresher : {fresher}
                  </h6>
                </div>
                <div class="col-12 border-top p-3 text-left" id="experience">
                  <h6 class="font-weight-bold text-dark mb-1">
                    <img
                      src="img/icon/ex.png"
                      alt=""
                      class="icon-image"
                    />{" "}
                    Experience : {experience}
                  </h6>
                </div>
                <div class="col-12 border-top p-3 text-left">
                  <h6 class="font-weight-bold text-dark mb-1">
                    <img
                      src="img/icon/company.png"
                      alt=""
                      class="icon-image"
                    />{" "}
                    Company : {company}
                  </h6>
                </div>
              </div>
            </aside>
            <aside class="col col-xl-3 order-xl-3 col-lg-6 order-lg-3 col-md-6 col-sm-6 col-12 display-none-desktop">
              <div class="overflow-hidden text-center p-6 mb-2">
                <Link
                  to="/edit-profile"
                  class="d-flex align-items-center font-weight-bold btn  rounded p-3 "
                  style={{ backgroundColor: "#ffd9f3" }}
                >
                  <div class="mr-3">
                    <div class="icon-circle-profile">
                      <i class="feather-edit left-menu-icon "></i>
                    </div>
                  </div>
                  <div>
                    <h6 class=" mt-2 ml-1">Edit Profile</h6>
                  </div>
                </Link>
              </div>
              <div
                class="shadow-sm border rounded  job-item mb-3"
                id="cv"
                style={{ backgroundColor: "#ffd9f3" }}
              >
                <div class="d-flex align-items-center  justify-content-between p-3 job-item-header">
                  <div class="d-flex align-items-center">
                    <div class="overflow-hidden mr-2">
                      <h6 class="font-weight-bold text-dark mb-0 text-truncate">
                        Resume
                      </h6>
                    </div>
                    {cv !== "" && cv !== "resume/no_image.png" ? (
                      <div className="text-center bg-white py-3">
                        <a onClick={() => downloadFile(fileUrl)}>
                          <i className="btn btn-outline-success text-light-hov fa fa-download"></i>
                        </a>
                      </div>
                    ) : null}
                  </div>
                  <img
                    src={`${process.env.REACT_APP_API_URL}/resume/no_image.png`}
                    class="img-fluid mt-2"
                    alt="Responsive image"
                    style={{
                      width: "70px",
                      maxWidth: "100%",
                      height: "70px",
                      borderRadius: "10%",
                    }}
                  />
                </div>
                <div
                  className="progress"
                  id="progress1"
                  style={{ display: "none", width: "90%", margin: "auto" }}
                >
                  <div
                    className="progress-bar progress-bar-striped progress-bar-animated"
                    role="progressbar"
                    aria-label="progressbar"
                    aria-valuenow={progressbar1}
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ width: `${progressbar1}%` }}
                  ></div>
                </div>

                <div
                  className=" rounded vh-50 "
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    gap: "20px",
                  }}
                >
                  <label
                    data-toggle="tooltip"
                    data-placement="top"
                    data-original-title="Upload New Picture"
                    for="filecv"
                    className="btn btn-outline-info "
                  >
                    <i className="feather-image"></i>
                    <input
                      type="file"
                      id="filecv"
                      accept=".jpg, .jpeg, .png, .pdf, .docx"
                      onChange={handleCv}
                      style={{ display: "none", visibility: "none" }}
                    />
                    &nbsp; Upload
                  </label>

                  {cv !== "" && cv !== "resume/no_image.png" ? (
                    <button
                      data-toggle="tooltip"
                      data-placement="top"
                      data-original-title="Delete"
                      type="submit"
                      className="btn btn-outline-danger"
                      onClick={removeCv}
                    >
                      <i className="feather-trash-2"></i>
                      &nbsp; Remove
                    </button>
                  ) : null}
                </div>

                <div className="d-flex justify-content-center align-items-center mt-2 mb-2">
                  <em
                    style={{
                      marginLeft: 5,
                      color: "GrayText",
                    }}
                  >
                    *Only .jpg, .png, .jpeg, .pdf, .docx file accepted
                  </em>
                </div>
              </div>
              <div
                class="shadow-sm border rounded  job-item mb-3"
                style={{ backgroundColor: "#ffd9f3" }}
              >
                <div class="d-flex align-items-center justify-content-between p-3 job-item-header">
                  <div class="overflow-hidden mr-2">
                    <h6 class="font-weight-bold text-dark mb-0 text-truncate">
                      Achievement
                    </h6>
                  </div>
                  {achievement && (
                    <img
                      src={`${process.env.REACT_APP_API_URL}/${achievement}`}
                      class="img-fluid mt-2"
                      alt="Responsive image"
                      style={{
                        width: "70px",
                        maxWidth: "100%",
                        height: "70px",
                        borderRadius: "10%",
                      }}
                    />
                  )}
                </div>
                <div
                  className="progress"
                  id="progress2"
                  style={{ display: "none", width: "90%", margin: "auto" }}
                >
                  <div
                    className="progress-bar progress-bar-striped progress-bar-animated"
                    role="progressbar"
                    aria-label="progressbar"
                    aria-valuenow={progressbar2}
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ width: `${progressbar2}%` }}
                  ></div>
                </div>
                <div
                  className=" rounded vh-50 "
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    gap: "20px",
                  }}
                >
                  <label
                    data-toggle="tooltip"
                    data-placement="top"
                    data-original-title="Upload New Picture"
                    for="fileach"
                    className="btn btn-outline-info "
                  >
                    <i className="feather-image"></i>
                    <input
                      type="file"
                      accept=".jpg, .jpeg, .png"
                      id="fileach"
                      onChange={handleAchievement}
                      style={{ display: "none", visibility: "none" }}
                    />
                    &nbsp; Upload
                  </label>
                  {achievement !== "" &&
                    achievement !== "achievement/no_image.png" ? (
                    <button
                      data-toggle="tooltip"
                      data-placement="top"
                      data-original-title="Delete"
                      type="submit"
                      className="btn btn-outline-danger"
                      onClick={removeAchievement}
                    >
                      <i className="feather-trash-2"></i>
                      &nbsp; Remove
                    </button>
                  ) : null}
                </div>

                <div className="d-flex justify-content-center align-items-center mt-2 mb-2">
                  <em
                    style={{
                      marginLeft: 5,
                      color: "GrayText",
                    }}
                  >
                    *Only .jpg, .png, .jpeg, .pdf, .docx file accepted
                  </em>
                </div>
              </div>
            </aside>
            <main class="col col-xl-6 order-xl-2 col-lg-12 order-lg-2 col-md-12 col-sm-12 col-12">
              {getpost.length != 0 &&
                getpost.map((data, i2) => {
                  return (
                    <MyFeedPost
                      feed_post_data={data}
                      getFeedsPost={getFeedsPost}
                      feed_num={i2}
                    />
                  );
                })}
            </main>
            <aside class="col col-xl-3 order-xl-3 col-lg-6 order-lg-3 col-md-6 col-sm-6 col-12 display-none-mobile">
              <div class="overflow-hidden text-center p-6 mb-2">
                <Link
                  to="/edit-profile"
                  class="d-flex align-items-center font-weight-bold btn  rounded p-3 "
                  style={{ backgroundColor: "#ffd9f3" }}
                >
                  <div class="mr-3">
                    <div class="icon-circle-profile">
                      <i class="feather-edit left-menu-icon "></i>
                    </div>
                  </div>
                  <div>
                    <h6 class=" mt-2 ml-1">Edit Profile</h6>
                  </div>
                </Link>
              </div>
              <div
                class="shadow-sm border rounded  job-item mb-3"
                id="cv"
                style={{ backgroundColor: "#ffd9f3" }}
              >
                <div class="d-flex align-items-center  justify-content-between p-3 job-item-header">
                  <div class="d-flex align-items-center">
                    <div class="overflow-hidden mr-2">
                      <h6 class="font-weight-bold text-dark mb-0 text-truncate">
                        Resume
                      </h6>
                    </div>
                    {cv !== "" && cv !== "resume/no_image.png" ? (
                      <div className="text-center bg-white py-3">
                        <a onClick={() => downloadFile(fileUrl)}>
                          <i className="btn btn-outline-success text-light-hov fa fa-download"></i>
                        </a>
                      </div>
                    ) : null}
                  </div>
                  <img
                    src={`${process.env.REACT_APP_API_URL}/resume/no_image.png`}
                    class="img-fluid mt-2"
                    alt="Responsive image"
                    style={{
                      width: "70px",
                      maxWidth: "100%",
                      height: "70px",
                      borderRadius: "10%",
                    }}
                  />
                </div>
                <div
                  className="progress"
                  id="progress1"
                  style={{ display: "none", width: "90%", margin: "auto" }}
                >
                  <div
                    className="progress-bar progress-bar-striped progress-bar-animated"
                    role="progressbar"
                    aria-label="progressbar"
                    aria-valuenow={progressbar1}
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ width: `${progressbar1}%` }}
                  ></div>
                </div>

                <div
                  className=" rounded vh-50 "
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    gap: "20px",
                  }}
                >
                  <label
                    data-toggle="tooltip"
                    data-placement="top"
                    data-original-title="Upload New Picture"
                    for="filecv"
                    className="btn btn-outline-info "
                  >
                    <i className="feather-image"></i>
                    <input
                      type="file"
                      id="filecv"
                      accept=".jpg, .jpeg, .png, .pdf, .docx"
                      onChange={handleCv}
                      style={{ display: "none", visibility: "none" }}
                    />
                    &nbsp; Upload
                  </label>

                  {cv !== "" && cv !== "resume/no_image.png" ? (
                    <button
                      data-toggle="tooltip"
                      data-placement="top"
                      data-original-title="Delete"
                      type="submit"
                      className="btn btn-outline-danger"
                      onClick={removeCv}
                    >
                      <i className="feather-trash-2"></i>
                      &nbsp; Remove
                    </button>
                  ) : null}
                </div>

                <div className="d-flex justify-content-center align-items-center mt-2 mb-2">
                  <em
                    style={{
                      marginLeft: 5,
                      color: "GrayText",
                    }}
                  >
                    *Only .jpg, .png, .jpeg, .pdf, .docx file accepted
                  </em>
                </div>
              </div>
              <div
                class="shadow-sm border rounded  job-item mb-3"
                style={{ backgroundColor: "#ffd9f3" }}
              >
                <div class="d-flex align-items-center justify-content-between p-3 job-item-header">
                  <div class="overflow-hidden mr-2">
                    <h6 class="font-weight-bold text-dark mb-0 text-truncate">
                      Achievement
                    </h6>
                  </div>
                  {achievement && (
                    <img
                      src={`${process.env.REACT_APP_API_URL}/${achievement}`}
                      class="img-fluid mt-2"
                      alt="Responsive image"
                      style={{
                        width: "70px",
                        maxWidth: "100%",
                        height: "70px",
                        borderRadius: "10%",
                      }}
                    />
                  )}
                </div>
                <div
                  className="progress"
                  id="progress2"
                  style={{ display: "none", width: "90%", margin: "auto" }}
                >
                  <div
                    className="progress-bar progress-bar-striped progress-bar-animated"
                    role="progressbar"
                    aria-label="progressbar"
                    aria-valuenow={progressbar2}
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ width: `${progressbar2}%` }}
                  ></div>
                </div>
                <div
                  className=" rounded vh-50 "
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    gap: "20px",
                  }}
                >
                  <label
                    data-toggle="tooltip"
                    data-placement="top"
                    data-original-title="Upload New Picture"
                    for="fileach"
                    className="btn btn-outline-info "
                  >
                    <i className="feather-image"></i>
                    <input
                      type="file"
                      accept=".jpg, .jpeg, .png"
                      id="fileach"
                      onChange={handleAchievement}
                      style={{ display: "none", visibility: "none" }}
                    />
                    &nbsp; Upload
                  </label>
                  {achievement !== "" &&
                    achievement !== "achievement/no_image.png" ? (
                    <button
                      data-toggle="tooltip"
                      data-placement="top"
                      data-original-title="Delete"
                      type="submit"
                      className="btn btn-outline-danger"
                      onClick={removeAchievement}
                    >
                      <i className="feather-trash-2"></i>
                      &nbsp; Remove
                    </button>
                  ) : null}
                </div>

                <div className="d-flex justify-content-center align-items-center mt-2 mb-2">
                  <em
                    style={{
                      marginLeft: 5,
                      color: "GrayText",
                    }}
                  >
                    *Only .jpg, .png, .jpeg, .pdf, .docx file accepted
                  </em>
                </div>
              </div>
            </aside>
          </div>
        </div>
      </div>
      {/* modal body */}
      <div
        class="modal fade"
        id="staticBackdrop10"
        data-backdrop="static"
        data-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">
                Do you want to Upload Your Photo?
              </h5>
              <button
                type="button"
                class="close"
                ref={refElement}
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <br />
              <div className="d-sm-inline-block form-inline m-auto my-md-0 mw-100 navbar-search">
                <center>
                  <div
                    style={{
                      display: "block",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Avatar
                      // src={`${process.env.REACT_APP_API_URL}/${employeeImage}`}
                      class="img-fluid mt-2 mb-2 ml-5"
                      id="avatar_id_size"
                      alt="Responsive image"
                      label="Upload Your Picture"
                      onCrop={onCrop}
                      onClose={onClose}
                      width={470}
                      height={300}
                    labelStyle={
                      {color: 'red',textAlign:"center", display: "flex !important",
                      width: "100%",
                      alignContent: "center",
                      justifycontent: "center",
                      alignItems: "center",
                      cursor:"pointer",
                      height: "100%",
                      
                    }
                    }
                    />
                    <br />
                    <button
                      onClick={saveImage}
                      className="btn btn-outline-info ml-5"
                    >
                      Save Picture
                    </button>
                  </div>
                </center>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
