import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export function _(x) {
  return document.querySelector(x);
}

export function __(x) {
  return document.getElementsByClassName(x);
}

export function toaster(status, current_toaster_id, mssg) {
  if (status === "load") {
    _("#loader").style.display = "block";
    const toaster_id = toast.loading("Please wait...");
    return toaster_id;
  }
  if (status === "close") {
    toast.dismiss(current_toaster_id);
    _("#loader").style.display = "none";
  }
  if (status === "success") {
    toast.update(current_toaster_id, {
      render: mssg,
      type: toast.TYPE.SUCCESS,
      isLoading: false,
      autoClose: true,
      closeButton: true,
    });
    _("#loader").style.display = "none";
  }
  if (status === "error") {
    toast.update(current_toaster_id, {
      render: mssg,
      type: toast.TYPE.ERROR,
      isLoading: false,
      autoClose: true,
      closeButton: true,
    });
    _("#loader").style.display = "none";
  }
  if (status === "access denied") {
    toast.update(current_toaster_id, {
      render: mssg,
      type: toast.TYPE.ERROR,
      isLoading: false,
      autoClose: true,
      closeButton: true,
    });
    _("#loader").style.display = "none";
    setTimeout(function () {
      window.location.href = "/";
    }, 1000);
  }
}

export async function authentication() {
  const navigate = useNavigate;
  let userDetails;
  await axios
    .get(`${process.env.REACT_APP_API_URL}/api/getUserDetails`, {
      headers: {
        "auth-token": localStorage.getItem("authToken"),
      },
    })
    .then((res) => {
      if (res.data.userDetails) {
        userDetails = res.data.userDetails;
      } else {
        localStorage.removeItem("authToken");
        userDetails = [];
        navigate("/");
      }
    })
    .catch((error) => {
      console.error(error);
    });
  return userDetails;
}
