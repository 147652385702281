/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { authentication, toaster } from "../../Controller/main_controller";
import { BiRun } from "react-icons/bi";
import { CgCalendarDates } from "react-icons/cg";
import Select from "react-select";
import { BsCardChecklist } from "react-icons/bs";
import { FaEdit } from "react-icons/fa";
import { RiDeleteBin3Fill } from "react-icons/ri";
import Nav from "../../Component/Nav";
import Rsidebar from "../../Component/Rasidebar";
import Lsidebar from "../../Component/Lasidebar";
import { useNavigate } from "react-router-dom";

const InternshipJobPost = () => {
  const navigate = useNavigate();
  const [getLocation, setgetLocation] = useState([]);
  const [getLocationList, setgetLocationList] = useState([]);
  const [getArea, setArea] = useState(null);
  const [getAreaList, setgetAreaList] = useState([]);

  const [description, setDescription] = useState("");
  const [walkingJobsListg, setwalkingJobsListg] = useState([]);
  const [mode, setmode] = useState("Submit");
  const [walkingJobId, setWalkingJobId] = useState("");

  // Get user details

  const [userDetails, SetUserDetails] = useState("");
  //Protecting this page
  const fetchUserDetails = async () => {
    const userData = await authentication();
    SetUserDetails(userData);
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  // Title
  useEffect(() => {
    document.title = "Walking Job Post";
  }, []);

  // get My job postlist
  useEffect(() => {
    const toastId = toaster("close");
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/internshipPost/getMyInternshipPostList`,
        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      )
      .then((res) => {
        toast.dismiss();

        setwalkingJobsListg(res.data.internshipList);
      })
      .catch((error) => {
        toaster(
          "error",
          toastId,
          "Some Error Occurred. Please Try After Some Time"
        );
      });
  }, []);

  const reload = () => {
    const toastId = toaster("close");
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/internshipPost/getMyInternshipPostList`,
        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      )
      .then((res) => {
        toast.dismiss();

        setwalkingJobsListg(res.data.internshipList);
      })
      .catch((error) => {
        toaster(
          "error",
          toastId,
          "Some Error Occurred. Please Try After Some Time"
        );
      });
  };

  // get service area list
  useEffect(() => {
    const toastId = toaster("load");

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/internshipPost/getServiceAreaList`,
        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      )
      .then((res) => {
        let areaList = res.data.serviceAreaList;
        setgetAreaList([]);
        areaList.map((item) => {
          setgetAreaList((pre) => [
            ...pre,
            {
              value: item._id,
              label: item.service_area,
            },
          ]);
        });
      })
      .catch((error) => {
        toaster(
          "error",
          toastId,
          "Some Error Occurred. Please Try After Some Time"
        );
      });
  }, []);

  // get locationlist
  useEffect(() => {
    const toastId = toaster("close");
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/internshipPost/getLocationList`,
        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      )
      .then((res) => {
        toaster("close", toastId);
        let LocationList = res.data.locationList;

        setgetLocationList([]);
        LocationList.map((item) => {
          setgetLocationList((pre) => [
            ...pre,
            {
              value: item._id,
              label: item.state + ", " + item.city + ", " + item.area,
            },
          ]);
        });
      })
      .catch((error) => {
        toaster(
          "error",
          toastId,
          "Some Error Occurred. Please Try After Some Time"
        );
      });
  }, []);

  const handelsubmit = (event) => {
    let locvalue = [];
    for (let index = 0; index < getLocation.length; index++) {
      var element = getLocation[index].value;
      locvalue.push({ location_code: element });
    }
    event.preventDefault();
    if (locvalue.length <= 0) {
      return 0;
    }
    if (getArea <= 0) {
      return 0;
    }
    if (description <= 0) {
      return 0;
    }
    const toastId = toaster("load");
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/internshipPost/saveInternshipPost`,
        {
          locations: locvalue,
          service_area_code: getArea.value,
          description: description,
        },
        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      )
      .then((response) => {
        toaster(response.data.status, toastId, response.data.mssg);

        if (response.data.status === "success") {
          setArea(null);
          setDescription("");
          setgetLocation(null);

          setmode("Submit");
          reload();
          navigate("/thankyou");
        }
      })
      .catch((error) => {
        toaster(
          "error",
          toastId,
          "Some Error Occurred. Please Try After Some Time"
        );
      });
  };

  useEffect(() => { }, [getArea]);

  useEffect(() => { }, [getLocation]);

  // const handelUpdate = (item) => {
  const handelEdit = (item) => {
    setArea(null);
    const toastId = toaster("load");
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/internshipPost/getInternshipPostDetails`,
        {
          internship_code: item,
        },
        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      )
      .then((response) => {
        toaster(response.data.status, toastId, response.data.mssg);
        if (response.data.status === "success") {
          let jobDetails = response.data.internshipDetails[0];

          setDescription(response.data.internshipDetails[0].description);

          setArea({
            value: jobDetails.service_area_details[0]._id,
            label: jobDetails.service_area_details[0].service_area,
          });

          const locationArray = [];
          for (let id = 0; id < jobDetails.location.length; id++) {
            const item = jobDetails.location[id];

            locationArray.push({
              label: item.state + ", " + item.city + ", " + item.area,
              value: item._id,
            });
          }

          setgetLocation(locationArray);

          var element = document.getElementById("profile-tab");
          element.classList.add("active");
          document.getElementById("home-tab").classList.remove("active");
          var elementMain = document.getElementById("home");
          elementMain.classList.add("active", "show");
          document.getElementById("profile").classList.remove("active");
          setmode("Update");

          setWalkingJobId(item);
        }
      })
      .catch((error) => {
        toaster(
          "error",
          toastId,
          "Some Error Occurred. Please Try After Some Time"
        );
      });
  };

  const hadleupdate = (e) => {


    let locvalue = [];
    for (let index = 0; index < getLocation.length; index++) {
      var element = getLocation[index].value;
      locvalue.push({ location_code: element });
    }
    e.preventDefault();
    if (locvalue.length <= 0) {
      return 0;
    }
    if (getArea <= 0) {
      return 0;
    }
    if (description <= 0) {
      return 0;
    }
    const toastId = toaster("load");
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/internshipPost/updateInternshipPostDetails`,
        {
          internship_code: walkingJobId,
          locations: locvalue,
          service_area_code: getArea.value,
          description: description,
        },
        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      )
      .then((response) => {
        toaster(response.data.status, toastId, response.data.mssg);
        if (response.data.status === "success") {
          setWalkingJobId("");
          setArea(null);
          setDescription("");
          setgetLocation(null);

          reload();
        }
        if (response.data.status === "error") {
          toast.error(`${response.data.mssg}`);
        }
      })
      .catch((error) => {
        toaster(
          "error",
          toastId,
          "Some Error Occurred. Please Try After Some Time"
        );
      });
  };

  const handleDelete = (item) => {
    const toastId = toaster("load");
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/internshipPost/deleteInternshipJobPost`,
        {
          internship_code: item,
        },

        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      )
      .then((response) => {
        toaster(response.data.status, toastId, response.data.mssg);
        // toast.dismiss();

        if (response.data.status === "success") {
          reload();
        }
      })
      .catch((error) => {
        toaster(
          "error",
          toastId,
          "Some Error Occurred. Please Try After Some Time"
        );
      });
  };

  return (
    <>
      <Nav /> {/* html start */}
      <div className="container-fluid my-2">
        <div className="row justify-content-around ">
          <main
            className="col rounded col-xl-8 order-xl-2 col-lg-12 order-lg-1 col-md-12 col-sm-12 col-12 pb-5 pt-59 rounded bg-light"
          >
            <ul
              className="nav nav-justified border-bottom osahan-line-tab mb-5"
              id="myTab"
              role="tablist"
            >
              <li className="nav-item text-dark-mobile">
                <a
                  className="nav-link active"
                  id="profile-tab"
                  data-toggle="tab"
                  href="#home"
                  role="tab"
                  aria-controls="home"
                  aria-selected="false"
                >
                  <svg id="widthjob-post" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 256 256" class="asidebar_icon" height="20" width="20" xmlns="http://www.w3.org/2000/svg"><path d="M224,56V92.23a48,48,0,1,0-64,71.57h0V192H40a8,8,0,0,1-8-8V56a8,8,0,0,1,8-8H216A8,8,0,0,1,224,56Z" opacity="0.2"></path><path d="M248,128a56,56,0,1,0-96,39.14V224a8,8,0,0,0,11.58,7.16L192,216.94l28.42,14.22A8,8,0,0,0,232,224V167.14A55.81,55.81,0,0,0,248,128ZM192,88a40,40,0,1,1-40,40A40,40,0,0,1,192,88Zm3.58,112.84a8,8,0,0,0-7.16,0L168,211.06V178.59a55.94,55.94,0,0,0,48,0v32.47ZM136,192a8,8,0,0,1-8,8H40a16,16,0,0,1-16-16V56A16,16,0,0,1,40,40H216a16,16,0,0,1,16,16,8,8,0,0,1-16,0H40V184h88A8,8,0,0,1,136,192Zm-16-56a8,8,0,0,1-8,8H72a8,8,0,0,1,0-16h40A8,8,0,0,1,120,136Zm0-32a8,8,0,0,1-8,8H72a8,8,0,0,1,0-16h40A8,8,0,0,1,120,104Z"></path></svg>
                  &nbsp; Internship Job Post
                </a>
              </li>
              <li className="nav-item text-dark-mobile">
                <a
                  className="nav-link"
                  id="home-tab"
                  data-toggle="tab"
                  href="#profile"
                  role="tab"
                  aria-controls="profile"
                  aria-selected="true"
                >
                  <BsCardChecklist size={30} className="text-primary" />
                  &nbsp; List Job
                </a>
              </li>
            </ul>
            <div className="tab-content" id="myTab p-3">
              <div
                className="tab-pane fade show active"
                id="home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">Post a Job</h3>
                  </div>
                  <div className="card-body ">
                    <form>
                      <div className="row">
                        {/* <div className="col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="description"
                              style={{ paddingLeft: "8px" }}
                            >
                              Walkin Date
                            </label>

                            <input
                              className="form-control"
                              type="date"
                              value={wDate}
                              onChange={(e) => setwDate(e.target.value)}
                            />
                          </div>
                        </div> */}
                        <div className="col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="description"
                              style={{ paddingLeft: "8px" }}
                            >
                              Location
                            </label>

                            <Select
                              onChange={(e) => setgetLocation(e)}
                              value={getLocation}
                              id="salaryRange"
                              classNamePrefix="react-select"
                              options={getLocationList}
                              isClearable={true}
                              isSearchable={true}
                              isMulti={true}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="description"
                              style={{ paddingLeft: "8px" }}
                            >
                              Service Area
                            </label>
                            <Select
                              onChange={(e) => setArea(e)}
                              value={getArea}
                              id="servicearea"
                              classNamePrefix="react-select"
                              options={getAreaList}
                              isClearable={true}
                              isSearchable={true}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label htmlFor="description">Job Description</label>
                            <textarea
                              className="form-control"
                              id="description"
                              placeholder="Enter the job description"
                              rows="5"
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                              required
                            ></textarea>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <button
                            type="submit"
                            id="subnitUpdate"
                            className="btn apply-btn"
                            onClick={
                              mode === "Submit" ? handelsubmit : hadleupdate
                            }
                          >
                            {mode === "Submit" ? "Submit" : "Update"}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="profile"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
              >
                <div className="table-responsive">
                  <table
                    className="table table-hover"
                    style={{ fontFamily: "sans-serif" }}
                  >
                    <thead>
                      <tr>
                        <th scope="col">Location</th>

                        <th scope="col">ServiceArea</th>

                        <th scope="col">Description</th>

                        <th scope="col">Action</th>
                      </tr>
                    </thead>

                    {walkingJobsListg &&
                      walkingJobsListg.map((item, id) => {
                        return (
                          <tbody>
                            <tr>
                              <td key={id._id}>
                                {item.location.map((item1) => {
                                  return (
                                    <div
                                      key={id}
                                    >{`${item1.area},${item1.city},${item1.state}`}</div>
                                  );
                                })}
                                {/* {item.location.length > 0
                                  ? `${item.location[0].area},${item.location[0].city},${item.location[0].state}`
                                  : ""} */}
                              </td>

                              <td key={id._id}>
                                {item.service_area_details.length > 0
                                  ? item.service_area_details[0].service_area
                                  : ""}
                              </td>

                              <td>{item.description}</td>

                              <td
                                style={{
                                  display: "flex",

                                  alignItems: "center",

                                  justifyContent: "center",
                                }}
                              >
                                <FaEdit
                                  className="text-success mr-2"
                                  style={{ cursor: "pointer" }}
                                  size={20}
                                  onClick={() => handelEdit(item._id)}
                                />
                                <RiDeleteBin3Fill
                                  className="text-danger"
                                  style={{ cursor: "pointer" }}
                                  size={20}
                                  onClick={() => handleDelete(item._id)}
                                />
                              </td>
                            </tr>
                          </tbody>
                        );
                      })}
                  </table>
                </div>
              </div>
            </div>
          </main>
          <Lsidebar userDetails={userDetails} />
          <Rsidebar />
        </div>
      </div>
      {/* html start */}
    </>
  );
};

export default InternshipJobPost;
