/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { WhatsappShareButton, WhatsappIcon } from "react-share";
import { RotatingTriangles } from "react-loader-spinner";
import Nav from "../../Component/Nav";
import { authentication, toaster } from "../../Controller/main_controller";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";


export default function SearchEmployee() {
  const refElement = useRef("");
  const [userDetails, SetUserDetails] = useState("");
  const [techno, setTechno] = useState("");
  const [technoData, SetTechnoData] = useState([]);
  const [experience, setExperience] = useState("");
  const [experienceData, SetExperienceData] = useState([]);
  const [location, setLocation] = useState("");
  const [locationData, SetLocationData] = useState([]);
  const [getpost, setGetpost] = useState([]);
  const [submitCv, setSubmitCv] = useState();
  const [jobDescription, setJobdescription] = useState("");
  const [employeeId, setEmployeeId] = useState("");
  const [sortDate, setSortDate] = useState("All");
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [dismiss, setDismiss] = useState("");

  useEffect(() => {
    document.title = "Search Employee";
  }, []);

  // Get user details
  useEffect(() => {
    const fetchUserDetails = async () => {
      const userData = await authentication();
      SetUserDetails(userData);
    };
    fetchUserDetails();
  }, []);

  //Get tech list
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/searchEmployee/getTechList`,

        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      )
      .then((res) => {
        // console.log(res.data.techList)
        SetTechnoData(res.data.techList);
      })
      .catch((error) => {
        toast.error("Some Error Occurred. Please Try After Some Time", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }, []);

  //Get experience list
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/searchEmployee/getExpList`,

        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      )
      .then((res) => {
        SetExperienceData(res.data.expList);
      })
      .catch((error) => {
        toast.error("Some Error Occurred. Please Try After Some Time", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }, []);

  // get location list
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/searchEmployee/getLocationList`,

        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      )
      .then((res) => {
        SetLocationData(res.data.locationList);
      })
      .catch((error) => {
        toast.error("Some Error Occurred. Please Try After Some Time", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }, []);

  // get job list
  useEffect(() => {
    joblist();
  }, []);

  useEffect(() => {
    joblist();
  }, [experience]);

  useEffect(() => {
    joblist();
  }, [techno]);

  useEffect(() => {
    joblist();
  }, [location]);

  useEffect(() => {
    joblist();
  }, [sortDate]);

  const joblist = () => {
    setLoading(true);
    setGetpost([]);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/searchEmployee/getEmployeeList`,
        {
          tech_code: techno,
          location_code: location,
          exp_code: experience,

          employee_type: sortDate,
        },
        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      )
      .then((response) => {
        setLoading(false);
        if (response.data.status === "success") {
          setGetpost(response.data.employeeList);
        }
        if (response.data.status === "error") {
          // console.log(response.data);
        }
      })
      .catch((error) => {
        // toast.error("Some Error Occurred. Please Try After Some Time", {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
      });
  };

  const clearSearch = () => {
    setLocation("");
    setExperience("");
    setTechno("");
    setSortDate("");
  };


  // getting jobpostid
  const sendEmployeeId = (id) => {
    setEmployeeId(id);
  };

  const sendCv = () => {
    const toastId = toaster("load");


    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/message/sendUsResume`,
        {
          chat_employee_code: employeeId,
          email_id: email
        },
        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      )
      .then((response) => {
        toaster(response.data.status, toastId, response.data.mssg);
        if (response.data.status === "success") {
          refElement.current.click()

        }
      })
      .catch((error) => { });
  };

  return (
    <>
      <Nav />
      <div class="py-1 mt-40">
        <div class="container-fluid body-padding ">
          <div className="search_filter_div">
            <div class="row justify-c-center my-2">
              <div class="col-sm-3">
                <div className="form-group">
                  <label id="nameLabel" className="form-label">
                    Search By Technology
                  </label>
                  <select
                    class="form-control form-control-lg"
                    name="technoList"
                    id="technoList"
                    onChange={(e) => {
                      setTechno(e.target.value);
                    }}
                    value={techno}
                  >
                    <option value="">All</option>
                    {technoData.map((item, id) => {
                      return (
                        <>
                          <option value={item._id} key={id._id}>
                            {item.tech_name}
                          </option>
                        </>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div class="col-sm-3">
                <div className="form-group">
                  <label id="nameLabel" className="form-label">
                    Search By Experience
                  </label>
                  <select
                    class="form-control form-control-lg"
                    name="currentExperience"
                    id="currentExperience"
                    onChange={(e) => {
                      setExperience(e.target.value);
                    }}
                    value={experience}
                  >
                    <option value="">All</option>
                    {experienceData.map((item, id) => {
                      return (
                        <>
                          <option value={item._id} key={id._id}>
                            {item.experience}
                          </option>
                        </>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div class="col-sm-3">
                <div className="form-group">
                  <label id="nameLabel" className="form-label">
                    Search By Location
                  </label>
                  <select
                    class="form-control form-control-lg"
                    name="locationList"
                    id="locationList"
                    onChange={(e) => {
                      setLocation(e.target.value);
                    }}
                    value={location}
                  >
                    <option value="">All</option>
                    {locationData.map((item, id) => {
                      return (
                        <>
                          <option value={item._id} key={id._id}>
                            {`${item.area}, ${item.city}, ${item.state}`}
                          </option>
                        </>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div class="col-sm-3">
                <div className="form-group">
                  <label id="nameLabel" className="form-label">
                    Employee Type
                  </label>
                  <select
                    class="form-control form-control-lg"
                    name="sortBy"
                    id="sortBy"
                    onChange={(e) => setSortDate(e.target.value)}
                  >
                    <option value="All">All</option>
                    <option value="Job Seeker">Job Seeker</option>
                    <option value="Hr">HR</option>
                  </select>
                </div>
              </div>
            </div>

            <div style={{ textAlign: "center", marginBottom: "10px" }}>
              &nbsp;&nbsp;
              <span
                onClick={clearSearch}
                style={{ backgroundColor: "#ff4d4d", color: "#FFF" }}
                className="button_badges"
              >
                <i class="fa-solid fa-magnifying-glass-minus"></i>
                &nbsp; Clear All Search
              </span>
            </div>
          </div>

          <h3 className="search_list_heading">Here Is The Employee List</h3>

          {loading == true ? (
            <center>
              <RotatingTriangles
                visible={true}
                height="80"
                width="80"
                ariaLabel="rotating-triangels-loading"
                wrapperStyle={{}}
                wrapperClass="rotating-triangels-wrapper"
              />
            </center>
          ) : (
            <div class="row mt-2 table-responsive padding-margin-0">
              <table
                class="table table-m-view table-hover bg-light"
                style={{ border: "none", fontFamily: "sans-serif" }}
              >
                <thead class="thead-pink" style={{ backgroundColor: "#ffd9f3" }}>
                  <tr style={{ fontFamily: "sans-serif" }}>
                    <th scope="col">Action</th>
                    <th scope="col" style={{ whiteSpace: "nowrap" }}>Employer Name</th>
                    <th scope="col" style={{ whiteSpace: "nowrap" }}>Employer Type</th>
                    <th scope="col" style={{ whiteSpace: "nowrap" }}>Employer Email</th>
                    <th scope="col" style={{ whiteSpace: "nowrap" }}>Employer Phone Number</th>

                    <th scope="col">Fresher</th>
                    <th scope="col" style={{ whiteSpace: "nowrap" }}>Looking Job</th>
                    <th scope="col" style={{ whiteSpace: "nowrap" }}>Notice Period</th>
                    <th scope="col" style={{ whiteSpace: "nowrap" }}>Immediate Joiner</th>
                    <th scope="col">Location</th>
                    <th scope="col">Technology</th>
                    <th scope="col">Company</th>
                    <th scope="col">Experience</th>
                  </tr>
                </thead>
                {getpost != [] &&
                  getpost.map((data, i2) => {
                    return (
                      <tbody style={{ fontFamily: "sans-serif" }}>
                        <tr key={i2}>
                          <td>
                            <div style={{ marginBottom: '5px' }}>
                              <Link to={`/message/${data._id}`}>
                                <button
                                  type="button"
                                  className="btn btn-info btn-sm btn-block"
                                >
                                  <i className="feather-message-square"></i> Chat
                                </button>
                              </Link>
                            </div>
                            <div>
                              <button
                                type="button"
                                onClick={() => sendEmployeeId(data._id)}
                                data-toggle="modal"
                                data-target="#staticBackdrop6"
                                className="btn btn-success btn-sm btn-block"
                              >
                                <i className="feather-send"></i> Send Resume
                              </button>
                            </div>
                          </td>
                          <Link
                            to={`${process.env.REACT_APP_URL}/profile-details/${data._id}`}
                            target="_blank"
                          >
                            <td
                              style={{ color: "Highlight", fontWeight: "bold" }}
                            >
                              <div style={{ marginBottom: '5px' }}>
                                <img
                                  class="rounded-circle show-image"
                                  src={`${process.env.REACT_APP_API_URL}/${data.employee_image}`}
                                  alt=""
                                  style={{
                                    width: "30px",
                                    maxWidth: "100%",
                                    height: "30px",
                                  }}
                                />
                              </div>
                              <div>
                                {`${data.first_name} ${data.last_name}`}
                              </div>
                            </td>
                          </Link>
                          <td>{data.employee_type}</td>

                          <td>{data.email_id}</td>
                          <td>{data.ph_num}</td>

                          <td>{data.fresher}</td>
                          <td>{data.looking_job}</td>
                          <td>{data.notice_period}</td>
                          <td>{data.immediate_joinner}</td>
                          <td>
                            {data.location.length > 0
                              ? `${data.location[0].area},${data.location[0].city},${data.location[0].state}`
                              : "---"}
                          </td>
                          <td>
                            {data.technology.length > 0
                              ? data.technology[0].tech_name
                              : "--"}
                          </td>
                          <td>
                            {data.company_details.length > 0
                              ? data.company_details[0].company_name
                              : "--"}
                          </td>
                          <td>
                            {data.experience_master.length > 0
                              ? data.experience_master[0].experience
                              : "--"}
                          </td>
                        </tr>
                      </tbody>
                    );
                  })}
              </table>
            </div>
          )}




          <div
            class="modal fade"
            id="staticBackdrop6"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="staticBackdropLabel">
                    Send Us CV Here?
                  </h5>
                  <button
                    type="button"
                    class="close"
                    ref={refElement}
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="row justify-c-center my-2">
                    <div class="col-sm-12">
                      <div className="form-group">
                        <label id="nameLabel" className="form-label">
                          Email ID
                        </label>
                        <input
                          type="email"
                          id="file"
                          placeholder="Enter email id"
                          name="file"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          className="form-control"
                        />

                      </div>
                    </div>
                  </div>

                  <button
                    class="btn btn-success"
                    style={{ width: "100%", borderRadius: "20px" }}
                    onClick={sendCv}

                    // data-dismiss="modal"
                    aria-label="Close"
                  >
                    Send Resume
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
