import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Rsidebar from "../../Component/Rasidebar";
import Lsidebar from "../../Component/Lasidebar";
import Nav from "../../Component/Nav";
import { authentication } from "../../Controller/main_controller";
import { toast } from "react-toastify";
import { RotatingTriangles } from "react-loader-spinner";

export default function Training(props) {
  const [userDetails, SetUserDetails] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.title = "Training";
  });

  // Get user details
  useEffect(() => {
    const fetchUserDetails = async () => {
      const userData = await authentication();
      SetUserDetails(userData);
    };
    fetchUserDetails();
  }, []);

  const [text, setText] = useState("");
  const [data, SetData] = useState([]);
  const changeHandler = (e) => {
    setText(e.target.value);
  };

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/trainings/getTrainingList`, {
        headers: {
          "auth-token": localStorage.getItem("authToken"),
        },
      })
      .then((res) => {
        setLoading(false);
        SetData(res.data.trainingList);
      })
      .catch((error) => {
        toast.error("Some Error Occurred. Please Try After Some Time", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }, []);

  return (
    <>
      <Nav />
      <div className="py-2">
        <div className="container-fluid">
          <div className="row justify-content-around">
            <main className="col col-xl-8 order-xl-2 col-lg-12 order-lg-1 col-md-12 col-sm-12 col-12 mt-54">
              <div className="box shadow-sm border rounded bg-white mb-3 osahan-share-post">
                <ul
                  className="nav nav-justified border-bottom osahan-line-tab traning-text-dark Recommended"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item col-sm-12">
                    <a
                      className="nav-link active"
                      id="activeTab-tab"
                      data-toggle="tab"
                      href="#activeTab"
                      role="tab"
                      aria-controls="Settings"
                      aria-selected="true"
                    >
                      <h4 className="text-mobile-view" style={{ textAlign: "left" }}>
                        {" "}
                        Recommended Trainings :
                      </h4>
                    </a>
                  </li>

                  <li className="nav-item traning-search-input" style={{ padding: "0px 7px" }}>
                    <input
                      type="text"
                      placeholder="Search Trainings by Its Name"
                      className="form-control my-1"
                      value={text}
                      onChange={changeHandler}
                      style={{ backgroundColor: "#f3f3f3" }}
                    />
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="activeTab"
                    role="tabpanel"
                    aria-labelledby="activeTab-tab"
                    style={{
                      padding: "15px",
                    }}
                  >
                    <div class="row">
                      {loading === true ? (
                        <center>
                          <RotatingTriangles
                            visible={true}
                            height="80"
                            width="80"
                            ariaLabel="rotating-triangels-loading"
                            wrapperStyle={{}}
                            wrapperclassName="rotating-triangels-wrapper"
                          />
                        </center>
                      ) : (
                        <>
                          {data.length === 0 ? (
                            <center>
                              <h2>There is no such training list</h2>
                            </center>
                          ) : null}

                          {data
                            .filter((value) => {
                              if (text === "") {
                                return value;
                              } else if (
                                value.title
                                  .toLowerCase()
                                  .trim()
                                  .startsWith(text.toLowerCase())
                              ) {
                                return value;
                              }
                            })
                            .map((item, id) => {
                              return (
                                <div className="col-md-4 mb-4" key={id}>
                                  <div className="card" style={{ display: 'block', textAlign: 'center', paddingTop: '7px', }} >

                                    <div style={{ height: '202px', }}>
                                      <img
                                        src={`${process.env.REACT_APP_API_URL}/${item.trainings_poster}`}
                                        className="card-img-top crdimg"
                                        alt="..."
                                        style={{
                                          width: 'auto',
                                          height: 'auto',
                                          maxHeight: '200px',
                                          minWidth: 'auto',
                                        }}
                                      />
                                    </div>

                                    <div className="card-body">
                                      <h5 className="card-title">{item.title}</h5>
                                      <p className="card-text">
                                        {item.details.substring(0, 100)}...
                                      </p>
                                    </div>
                                    <div className="card-footer">
                                      <Link
                                        to="https://www.redprismgroup.com/online-training"
                                        className="btn btn-block apply-btn"
                                      >
                                        View Details
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </main>

            <Lsidebar userDetails={userDetails} />
            <Rsidebar />
          </div>
        </div>
      </div>
    </>
  );
}
