import React from "react";
import { Link } from "react-router-dom";
import { BsEmojiSmile, BsBagCheck } from "react-icons/bs";
import { AiOutlineUser } from "react-icons/ai";
import { FaWalking } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";
import { LuLayoutDashboard } from "react-icons/lu";
import { PiCertificateDuotone } from "react-icons/pi";
export default function Lsidebar({ userDetails }) {
  return (
    <aside className="col col-xl-2 order-xl-1 col-lg-6 order-lg-2 col-md-6 col-sm-6 col-12 aside-tag">
      <div className="border rounded mb-3 aside_bar">
        <div className="shadow-sm" style={{ paddingTop: "4px" }}>
          <Link
            to="/my-profile"
            className="dropdown-item align-items-center"
            style={{ borderBottom: "0px", marginBottom: "6px" }}
          >
            <div>
              <center>
                <div style={{ height: "98px", }} className="icon-circle-profile">
                  <img
                    src={`${process.env.REACT_APP_API_URL}/${userDetails.employee_image}`}
                    alt=""
                    style={{ height: "100px", }}
                  />
                </div>
              </center>
            </div>

            <div className="text-overflow-hide text-center mb-1">
              <span className="font-weight-bold" style={{ fontSize: "20px" }}>
                {`${userDetails.first_name} ${userDetails.last_name}`}
                <br/>
                
                ({userDetails.employee_type})
              </span>
            </div>

            {userDetails.employee_type != "Hr" ? (
              <div className="text-overflow-hide text-center">
                <div
                  className="icon-circle-profile font-weight-bold"
                  style={{ fontSize: "12px" }}
                >
                  {userDetails.employee_status === "One Year Jobless" ?
                    <>
                      <img
                        src="img/icon/status.png"
                        alt=""
                        style={{ width: '45px', }}
                      />
                      ({userDetails.employee_status})
                    </>
                    : null}

                  {userDetails.employee_status === "Serving Notice Period" ?
                    <>
                      <img
                        src="img/icon/icon2.png"
                        alt=""
                        style={{ width: '45px', }}
                      />
                      ({userDetails.employee_status})
                    </>
                    : null}

                  {userDetails.employee_status === "Looking For Job" ?
                    <>
                      <img
                        src="img/icon/icon3.png"
                        alt=""
                        style={{ width: '45px', }}
                      />
                      ({userDetails.employee_status})
                    </>
                    : null}

                  {userDetails.employee_status === "Immediate Joiner" ?
                    <>
                      <img
                        src="img/icon/icon4.png"
                        alt=""
                        style={{ width: '45px', }}
                      />
                      ({userDetails.employee_status})
                    </>
                    : null}


                </div>
              </div>
            ) : null}
          </Link>

          {userDetails.employee_type === "Hr" ? (
            <Link
              to="/dashboard"
              className="dropdown-item d-flex align-items-center"
            >
              <div>
                <div className="icon-circle-profile">
                  <LuLayoutDashboard size={18} className="asidebar_icon" />
                </div>
              </div>
              <div>
                <span className="font-weight-bold">Dashboard</span>
              </div>
            </Link>
          ) : null}

          <Link
            to="/my-profile"
            className="dropdown-item d-flex align-items-center"
          >
            <div>
              <div className="icon-circle-profile">
                <BsEmojiSmile size={18} className="asidebar_icon" />
              </div>
            </div>
            <div>
              <span className="font-weight-bold">My Profile</span>
            </div>
          </Link>
          <Link
            to="/buddies"
            className="dropdown-item d-flex align-items-center"
          >
            <div>
              <div className="icon-circle-profile">
                <AiOutlineUser size={19} className="asidebar_icon" />
              </div>
            </div>
            <div>
              <span className="font-weight-bold">Buddies</span>
            </div>
          </Link>
          <Link
            to="/job-post"
            className="dropdown-item d-flex align-items-center"
          >
            <div>
              <div className="icon-circle-profile">
                <BsBagCheck size={18} className="asidebar_icon" />
              </div>
            </div>
            <div>
              <span className="font-weight-bold">Job Post</span>
            </div>
          </Link>
          <Link
            to="/walkin-job-post"
            className="dropdown-item d-flex align-items-center"
          >
            <div>
              <div className="icon-circle-profile">
                <FaWalking size={19} className="asidebar_icon" />
              </div>
            </div>
            <div>
              <span className="font-weight-bold">Walk-In Job Post</span>
            </div>
          </Link>
          
          <Link
            to="/internship-job-post"
            className="dropdown-item d-flex align-items-center"
          >
            <div>
              <div className="icon-circle-profile">
                <PiCertificateDuotone size={20} className="asidebar_icon" />
              </div>
            </div>
            <div>
              <span className="font-weight-bold">Internship Job Post</span>
            </div>
          </Link>
          
        </div>
      </div>

      <p className="left_right_pane_content" style={{textAlign:'justify'}}>
        Redprismgroup is one of the best training
        provider for corporate and retail trainings, visit <Link to="https://redprismgroup.com" target="_blank">www.redprismgroup.com</Link> for more information.
      </p>

    </aside>
  );
}
