import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { _, toaster } from "../../Controller/main_controller";
import Select from "react-select";

function RegisterJobSheeker() {
  useEffect(() => {
    document.title = "Job Seeker Registration";
  }, []);

  const [data, SetData] = useState([]);
  const [data1, SetData1] = useState([]);
  const [data2, SetData2] = useState([]);
  const [locationData, SetLocationData] = useState([]);
  const [employeestatus, setEmployeeStatus] = useState("");
  const [StatusIcon, setStatusIcon] = useState();


  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/jobseekerRegister/getCompanyList`
      )
      .then((res) => {
        let companyList = res.data.companyList;
        SetData([]);
        companyList.map((item) => {
          SetData((pre) => [
            ...pre,
            {
              value: item._id,
              label: item.company_name,
            },
          ]);
        });
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/jobseekerRegister/getTechList`)
      .then((res) => {
        let techList = res.data.techList;
        SetData1([]);
        techList.map((item) => {
          SetData1((pre) => [
            ...pre,
            {
              value: item._id,
              label: item.tech_name,
            },
          ]);
        });
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/jobseekerRegister/getExpList`)
      .then((res) => {
        let expList = res.data.expList;
        SetData2([]);
        expList.map((item) => {
          SetData2((pre) => [
            ...pre,
            {
              value: item._id,
              label: item.experience,
            },
          ]);
        });
      });
  }, []);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/jobseekerRegister/getLocationList`
      )
      .then((res) => {
        let locationList = res.data.locationList;
        SetLocationData([]);
        locationList.map((item) => {
          SetLocationData((pre) => [
            ...pre,
            {
              value: item._id,
              label: item.state + ", " + item.city + ", " + item.area,
            },
          ]);
        });
      });
  }, []);

  // form validation

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      location: null,
      term: "",
    },
    validationSchema: yup.object({
      firstName: yup
        .string()
        .matches(/^[A-Za-z]+$/, "This field  must be a letter")
        .required("*Required")
        .min(3, "Minimum 3 characters length")
        .max(15, "Maximum 15 characters length"),
      lastName: yup
        .string()
        .matches(/^[A-Za-z]+$/, "This field  must be a letter")
        .required("*Required")
        .min(3, "Minimum 3 characters length")
        .max(15, "Maximum 15 characters length"),

      email: yup.string().required("*Required").email("Invalid Email"),
      mobile: yup
        .string()
        .required("*Required")
        .matches(/^[0-9]+$/, "This field  must be a number")
        .min(10, "Minimum 10 digits")
        .max(10, "Maximum 10 digits"),
      // location: yup.object().required("*Required"),
      term: yup.string().required("*Required"),
    }),
    onSubmit: (values) => {
      // console.log(values);
    },
  });
  // registration varify Start

  // form validation
  const formik1 = useFormik({
    initialValues: {
      otp: "",
      otpId: "",
    },
    validationSchema: yup.object({
      otp: yup
        .string()
        .required("*Required")
        // .min(6, "Minimum 6 digits")
        .matches(/^[0-9]+$/, "This field  must be a number")
        .max(6, "Maximum 6 digits"),
    }),
    onSubmit: (values) => {
      // console.log(values);
    },
  });

  // registration varify End

  // registration create start

  // form validation

  const formik2 = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: yup.object({
      username: yup
        .string()
        .required("*Required")
        // .matches(/^[A-Za-z]+$/, "This field  must be a letter")
        // .min(3, "Minimum 3 characters length")
        .max(50, "Maximum 50 characters length"),
      password: yup
        .string()
        .required("*Required")
        .matches(/[^\w]/, "One Special character Required")
        .matches(/[0-9]/, "One Number Required")
      // .min(3, "Minimum 3 characters length")
      // .max(10, "Maximum 10 characters length"),
    }),
    onSubmit: (values) => {
      // console.log(values);
    },
  });

  // registration create End

  // registration tech skills start

  // form validation

  const formik3 = useFormik({
    initialValues: {
      currentCompany: null,
      technicalSkills: null,
      fresher: "",
      ExperienceInYear: null,
    },
    validationSchema: yup.object({
      currentCompany: yup.object().required("*Required"),
      technicalSkills: yup.object().required("*Required"),
      fresher: yup.string().required("*Required"),
      // ExperienceInYear: yup.object().required("*Required"),
    }),
    onSubmit: (values) => {
      // console.log(values);
      changeForm("status"); // will be deleted after service call
    },
  });

  // registration tech skills End

  // registration  status start

  const navigate = useNavigate();

  // form validation

  const formik4 = useFormik({
    initialValues: {
      lookingForJob: "",
      noticePeriod: "",
      immediateJoiner: "",
    },
    validationSchema: yup.object({
      lookingForJob: yup.string().required("*Required"),
      // noticePeriod: yup.string().required("*Required"),
      // immediateJoiner: yup.string().required("*Required"),
    }),
    onSubmit: (values) => {
      // console.log(values);
    },
  });

  const handleContact = () => {
    if (
      formik.values.firstName &&
      formik.values.lastName &&
      formik.values.email &&
      formik.values.mobile &&
      formik.values.term
    ) {
      const toastId = toaster("load");
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/jobseekerRegister/emailCheck`,
          {
            email_id: formik.values.email,
          }
        )
        .then((response) => {
          toaster(response.data.status, toastId, response.data.mssg);

          if (response.data.status === "success") {
            sendOtp();
          }
        })
        .catch((error) => {
          toaster(
            "error",
            toastId,
            "Some Error Occurred. Please Try After Some Time"
          );
        });
    }
  };

  const sendOtp = () => {
    const toastId = toaster("load");
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/jobseekerRegister/registerSendOtp`,
        {
          ph_num: formik.values.mobile,
        }
      )
      .then((response) => {
        toaster(response.data.status, toastId, response.data.mssg);

        if (response.data.status === "success") {
          formik1.values.otpId = response.data.otp_id;
          changeForm("verify");
          _("#otp").value = response.data.otp;
          formik1.values.otp = response.data.otp;
        }
      })
      .catch((error) => {
        toaster(
          "error",
          toastId,
          "Some Error Occurred. Please Try After Some Time"
        );
      });
  };

  const otpSuccess = () => {
    if (formik1.values.otp) {
      const toastId = toaster("load");
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/jobseekerRegister/registerOtpCheck`,
          {
            ph_num: formik.values.mobile,
            otp: formik1.values.otp,
            otp_id: formik1.values.otpId,
          }
        )
        .then((response) => {
          toaster(response.data.status, toastId, response.data.mssg);

          if (response.data.status === "success") {
            changeForm("create");
          }
        })
        .catch((error) => {
          toaster(
            "error",
            toastId,
            "Some Error Occurred. Please Try After Some Time"
          );
        });
    }
  };

  const handleUsername = () => {
    if (formik2.values.username && formik2.values.password) {
      const toastId = toaster("load");
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/jobseekerRegister/usernameCheck`,
          {
            user_name: formik2.values.username,
          }
        )
        .then((response) => {
          toaster(response.data.status, toastId, response.data.mssg);

          if (response.data.status === "success") {
            changeForm("skill");
          }
        })
        .catch((error) => {
          toaster(
            "error",
            toastId,
            "Some Error Occurred. Please Try After Some Time"
          );
        });
    }
  };

  const handleApi = () => {
    const toastId = toaster("load");
    // console.log(employeestatus);
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/jobseekerRegister/register`, {
        employee_status: employeestatus,
        status_icon: StatusIcon,
        first_name: formik.values.firstName,
        last_name: formik.values.lastName,
        user_name: formik2.values.username,
        password: formik2.values.password,
        ph_num: formik.values.mobile,
        email_id: formik.values.email,
        company_code: formik3.values.currentCompany.value,
        tech_code: formik3.values.technicalSkills.value,
        exp_code: formik3.values.ExperienceInYear.value,
        location_code: formik.values.location.value,
        looking_job: formik4.values.lookingForJob,
        notice_period: formik4.values.noticePeriod,
        immediate_joinner: formik4.values.immediateJoiner,
        fresher: formik3.values.fresher,
      })
      .then((response) => {
        toaster(response.data.status, toastId, response.data.mssg);

        if (response.data.status === "success") {
          setTimeout(function () {
            navigate("/job-seeker-sign-in");
          }, 1000);
          // console.log(StatusIcon);
        }
      })
      .catch((error) => {
        toaster(
          "error",
          toastId,
          "Some Error Occurred. Please Try After Some Time"
        );
      });
  };

  const changeForm = (formName) => {
    document.getElementById("basic").style.display = "none";
    document.getElementById("verify").style.display = "none";
    document.getElementById("create").style.display = "none";
    document.getElementById("skill").style.display = "none";
    document.getElementById("status").style.display = "none";
    document.getElementById(formName).style.display = "block";
  };
  // registration status End

  return (
    <>
      <div className="container" id="basic">
        <div className="row">
          <div className="col-md-4 dn">
            <div className="pindicator">
              <div className="bullet current ">
                <span
                  onClick={() => changeForm("basic")}
                  className="icon1 tns90"
                >
                  1
                </span>
              </div>
              <div className="bullet ">
                <span className="icon1 tns90">2</span>
              </div>
              <div className="bullet next future">
                <span className="icon1 tns90">3</span>
              </div>
              <div className="bullet future">
                <span className="icon1 tns90">4</span>
              </div>
              <div className="bullet future">
                <span className="icon1 tns90">5</span>
              </div>
            </div>
          </div>
          <div className="col-md-8 mt-3">
            <div className="col-md-12 font-weight-bold text-center h1 text-light">
              Create an Account!
            </div>
            <div className="col-md-12 text-center text-light">( As an Job Seeker )</div>
            <div className="col-md-12 text-center text-light">
              It only takes a couple of minutes to get started!
            </div>
            <div className="col-md-12 text-center">
              <span
                style={{ borderRadius: 10 + "px", backgroundColor: "#fde9f2", color: "#000" }}
                className="px-3 "
              >
                It's free
              </span>
            </div>
            <div className="col-md-12 mt-3 text-center d-flex align-items-center justify-content-center">
              <span className="midil text-light">Please fill up more details</span>
            </div>
            <div className="container">
              <form onSubmit={formik.handleSubmit}>
                <div className="form-row d-flex align-items-center justify-content-center">
                  <div className="col-md-8 mb-3">
                    <label htmlFor="validationDefault01" className="text-light">First name</label>
                    <input
                      type="text"
                      name="firstName"
                      className="form-control"
                      id="validationDefault01"
                      placeholder="First name"
                      onChange={formik.handleChange}
                      value={formik.values.firstName}
                    />

                    {formik.errors.firstName && (
                      <p className="inp-alert">{formik.errors.firstName}</p>
                    )}
                  </div>
                  <div className="col-md-8 mb-3">
                    <label htmlFor="validationDefault02" className="text-light">Last name</label>
                    <input
                      type="text"
                      name="lastName"
                      className="form-control"
                      id="validationDefault02"
                      placeholder="Last name"
                      onChange={formik.handleChange}
                      value={formik.values.lastName}
                    />

                    {formik.errors.lastName && (
                      <p className="inp-alert">{formik.errors.lastName}</p>
                    )}
                  </div>
                  <div className="col-md-8 mb-3">
                    <label htmlFor="Email" className="text-light">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      id="validationDefaultUsername"
                      placeholder="abc@example.com"
                      aria-describedby="inputGroupPrepend2"
                      onChange={formik.handleChange}
                      value={formik.values.email}
                    />
                    {formik.errors.email && (
                      <p className="inp-alert">{formik.errors.email}</p>
                    )}
                  </div>
                  <div className="col-md-8 mb-3">
                    <label htmlFor="phone" className="text-light">Mob No.</label>
                    <input
                      type="number"
                      name="mobile"
                      className="form-control"
                      id="phone"
                      placeholder="Enter Your Mobile No."
                      aria-describedby="inputGroupPrepend2"
                      onChange={formik.handleChange}
                      value={formik.values.mobile}
                    />
                    {formik.errors.mobile && (
                      <p className="inp-alert">{formik.errors.mobile}</p>
                    )}
                  </div>

                  <div className="col-md-8 mb-3">
                    <label htmlFor="location" className="text-light">Location</label>

                    <Select
                      onChange={(e) => (formik.values.location = e)}
                      options={locationData}
                      isClearable={true}
                      isSearchable={true}
                      defaultValue={formik.values.location}
                      name="location"
                      id="location"
                      classNamePrefix="react-select"
                      placeholder="Select Your Location"
                      noOptionsMessage={() => "No Location Found"}
                    />
                    {formik.errors.location && (
                      <p className="inp-alert">{formik.errors.location}</p>
                    )}
                  </div>
                </div>

                <div className="form-group d-flex align-items-center justify-content-center">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      name="term"
                      type="checkbox"
                      value={formik.values.term}
                      onChange={formik.handleChange}
                      id="invalidCheck2"
                    />
                    <label className="form-check-label text-light" htmlFor="invalidCheck2">
                      Agree to terms and conditions
                    </label>
                  </div>
                  {formik.errors.term && (
                    <p className="inp-alert" style={{ marginTop: 10 }}>{formik.errors.term}</p>
                  )}
                </div>
                <div className="d-flex align-items-center justify-content-center">
                  <button
                    className="btn btn-pink mb-5 px-5"
                    type="submit"
                    style={{ fontWeight: "600", fontSize: "16px" }}
                    onClick={handleContact}
                  >
                    Continue
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* registrstion varify page Start */}

      <div className="container" id="verify" style={{ display: "none" }}>
        <div className="row">
          <div className="col-md-4 dn ">
            <div className="pindicator">
              <div className="bullet current ">
                <span
                  onClick={() => changeForm("basic")}
                  className="icon1 tns90"
                >
                  1
                </span>
              </div>
              <div className="bullet current">
                <span
                  onClick={() => changeForm("verify")}
                  className="icon1 tns90"
                >
                  2
                </span>
              </div>
              <div className="bullet next future">
                <span className="icon1 tns90">3</span>
              </div>
              <div className="bullet future">
                <span className="icon1 tns90">4</span>
              </div>
              <div className="bullet future">
                <span className="icon1 tns90">5</span>
              </div>
            </div>
          </div>
          <div className="col-md-8 mt-3">
            {" "}
            <div className="col-md-12 font-weight-bold text-center h1 text-light">
              Create an Account!
            </div>
            <div className="col-md-12 text-center text-light">( As a Job Seeker )</div>
            <div className="col-md-12 text-center text-light">
              It only takes a couple of minutes to get started!
            </div>
            <div className="col-md-12 text-center">
              <span
                style={{ borderRadius: "10px", backgroundColor: "#fde9f2" }}
                className="px-3 "
              >
                It's free
              </span>
            </div>
            <div className="col-md-12 mt-3 text-center d-flex align-items-center justify-content-center">
              <b className="midil text-light">Please Verify yourself</b>
            </div>
            <div className="container">
              <form onSubmit={formik1.handleSubmit}>
                <div className="form-row d-flex align-items-center justify-content-center">
                  <div className="col-md-8 mb-3">
                    <p className="d-flex h4 my-5 justify-content-center">OTP</p>
                    <input
                      type="text"
                      name="otp"
                      id="otp"
                      className="form-control"
                      placeholder="Enter Your 6 Digit OTP"
                      onChange={formik1.handleChange}
                      value={formik1.values.otp}
                    />
                    {formik1.errors.otp && (
                      <p className="inp-alert">{formik1.errors.otp}</p>
                    )}
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-center mt-3">
                  {" "}
                  <button
                    className="btn btn-pink mb-5 px-5"
                    type="submit"
                    style={{ fontWeight: "600", fontSize: "16px" }}
                    onClick={otpSuccess}
                  >
                    Continue
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* registrstion varify page End */}

      {/* registration create start */}

      <div className="container" id="create" style={{ display: "none" }}>
        <div className="row">
          <div className="col-md-4 dn">
            <div className="pindicator">
              <div className="bullet current ">
                <span
                  onClick={() => changeForm("basic")}
                  className="icon1 tns90"
                >
                  1
                </span>
              </div>
              <div className="bullet current">
                <span
                  onClick={() => changeForm("verify")}
                  className="icon1 tns90"
                >
                  2
                </span>
              </div>
              <div className="bullet next future current">
                <span
                  onClick={() => changeForm("create")}
                  className="icon1 tns90"
                >
                  3
                </span>
              </div>
              <div className="bullet future">
                <span className="icon1 tns90">4</span>
              </div>
              <div className="bullet future">
                <span className="icon1 tns90">5</span>
              </div>
            </div>
          </div>
          <div className="col-md-8 mt-3">
            <div className="col-md-12 font-weight-bold text-center h1 text-light">
              Create an Account!
            </div>
            <div className="col-md-12 text-center text-light">( As a Job Seeker )</div>
            <div className="col-md-12 text-center text-light">
              It only takes a couple of minutes to get started!
            </div>
            <div className="col-md-12 text-center">
              <span className="px-3 py-1 backcolor">It's free</span>
            </div>
            <div className="col-md-12 mt-3 text-center d-flex align-items-center justify-content-center">
              <b className="midil backcolor px-3 py-1 text-light">
                Please Verify yourself
              </b>
            </div>
            <div className="container">
              <form onSubmit={formik2.handleSubmit}>
                <div className="form-row d-flex align-items-center justify-content-center">
                  <div className="col-md-8 mb-3">
                    <label htmlFor="text">User Name</label>
                    <input
                      type="text"
                      name="username"
                      className="form-control"
                      id="validationDefaultUsername"
                      placeholder="Enter Your User Name"
                      aria-describedby="inputGroupPrepend2"
                      onChange={formik2.handleChange}
                      value={formik2.values.username}
                    />
                    {formik2.errors.username && (
                      <p className="inp-alert">{formik2.errors.username}</p>
                    )}
                  </div>
                  <div className="col-md-8 mb-3">
                    <label htmlFor="password">Password</label>
                    <input
                      type="password"
                      name="password"
                      className="form-control"
                      id="validationDefaultPassword"
                      placeholder="Create Your Password"
                      aria-describedby="inputGroupPrepend2"
                      onChange={formik2.handleChange}
                      value={formik2.values.password}
                    />
                    {formik2.errors.password && (
                      <p className="inp-alert">{formik2.errors.password}</p>
                    )}
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-center mt-3">
                  <button
                    className="btn btn-pink mb-5 px-5"
                    type="submit"
                    style={{ fontWeight: "600", fontSize: "16px" }}
                    onClick={handleUsername}
                  >
                    Continue
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* registration create end */}

      {/* registration tech skills start */}

      <div className="container" id="skill" style={{ display: "none" }}>
        <div className="row">
          <div className="col-md-4 dn">
            <div className="pindicator">
              <div className="bullet current ">
                <span
                  onClick={() => changeForm("basic")}
                  className="icon1 tns90"
                >
                  1
                </span>
              </div>
              <div className="bullet current">
                <span
                  onClick={() => changeForm("verify")}
                  className="icon1 tns90"
                >
                  2
                </span>
              </div>
              <div className="bullet next future current">
                <span
                  onClick={() => changeForm("create")}
                  className="icon1 tns90"
                >
                  3
                </span>
              </div>
              <div className="bullet future current">
                <span
                  onClick={() => changeForm("skill")}
                  className="icon1 tns90"
                >
                  4
                </span>
              </div>
              <div className="bullet future">
                <span className="icon1 tns90">5</span>
              </div>
            </div>
          </div>
          <div className="col-md-8 mt-3">
            <div className="col-md-12 font-weight-bold text-center h1 text-light">
              Create an Account!
            </div>
            <div className="col-md-12 text-center text-light">( As a Job Seeker )</div>
            <div className="col-md-12 text-center text-light">
              It only takes a couple of minutes to get started!
            </div>
            <div className="col-md-12 text-center">
              <span className="px-3 py-1 backcolor">It's free</span>
            </div>
            <div className="col-md-12 mt-3 text-center d-flex align-items-center justify-content-center">
              <b className="midil backcolor px-3 py-1 text-light">
                Please Enter Your Technical Skills
              </b>
            </div>
            <div className="container mt-3">
              <form onSubmit={formik3.handleSubmit}>
                <div className="form-row d-flex align-items-center justify-content-center">
                  <div className="col-md-8 mb-3">
                    <label htmlFor="currentCompany">
                      Current Company
                    </label>
                    <Select
                      onChange={(e) => (formik3.values.currentCompany = e)}
                      options={data}
                      isClearable={true}
                      isSearchable={true}
                      defaultValue={formik3.values.currentCompany}
                      name="currentCompany"
                      id="currentCompany"
                      classNamePrefix="react-select"
                      placeholder="Select Your Company"
                      noOptionsMessage={() => "No Company Found"}
                    />
                    {formik3.errors.currentCompany && (
                      <p className="inp-alert">
                        {formik3.errors.currentCompany}
                      </p>
                    )}
                  </div>
                  <div className="col-md-8 mb-3">
                    <label htmlFor="technicalSkills">Technical Skills</label>

                    <Select
                      onChange={(e) => (formik3.values.technicalSkills = e)}
                      options={data1}
                      isClearable={true}
                      isSearchable={true}
                      defaultValue={formik3.values.technicalSkills}
                      name="technicalSkills"
                      id="technicalSkills"
                      classNamePrefix="react-select"
                      placeholder="Select Your Technical Skill"
                      noOptionsMessage={() => "No Technical Skill Found"}
                    />
                    {formik3.errors.technicalSkills && (
                      <p className="inp-alert">
                        {formik3.errors.technicalSkills}
                      </p>
                    )}
                  </div>

                  <div className="col-md-8 mb-3">
                    <label htmlFor="fresher">Fresher</label>
                    <select
                      className="form-control form-control-lg"
                      name="fresher"
                      id="fresher"
                      onChange={formik3.handleChange}
                      value={formik3.values.fresher}
                    >
                      <option value="">--Select--</option>
                      <option value="No">No</option>
                      <option value="Yes">Yes</option>
                    </select>
                    {formik3.errors.fresher && (
                      <p className="inp-alert">{formik3.errors.fresher}</p>
                    )}
                  </div>
                  <div className="col-md-8 mb-3">
                    <label htmlFor="experienceYear">Experience In Year</label>

                    <Select
                      onChange={(e) => (formik3.values.ExperienceInYear = e)}
                      options={data2}
                      isClearable={true}
                      isSearchable={true}
                      defaultValue={
                        (formik3.values.ExperienceInYear =
                          formik3.values.fresher === "Yes"
                            ? ""
                            : formik3.values.ExperienceInYear)
                      }
                      name="ExperienceInYear"
                      id="experienceYear"
                      classNamePrefix="react-select"
                      placeholder="Select Experience In Year"
                      noOptionsMessage={() => "No Experience Found"}
                      isDisabled={
                        formik3.values.fresher === "Yes" ? true : false
                      }
                    />

                    {formik3.errors.ExperienceInYear && (
                      <p className="inp-alert">
                        {formik3.errors.ExperienceInYear}
                      </p>
                    )}
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-center mt-3">
                  <button
                    className="btn btn-pink mb-5 px-5"
                    type="submit"
                    style={{ fontWeight: "600", fontSize: "16px" }}
                  >
                    Continue
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* registration tech skills end */}

      {/* registration status start */}
      { }
      <div className="container" id="status" style={{ display: "none" }}>
        <div className="row">
          <div className="col-md-4 dn">
            <div className="pindicator">
              <div className="bullet current ">
                <span
                  onClick={() => changeForm("basic")}
                  className="icon1 tns90"
                >
                  1
                </span>
              </div>
              <div className="bullet current">
                <span
                  onClick={() => changeForm("verify")}
                  className="icon1 tns90"
                >
                  2
                </span>
              </div>
              <div className="bullet next future current">
                <span
                  onClick={() => changeForm("create")}
                  className="icon1 tns90"
                >
                  3
                </span>
              </div>
              <div className="bullet future current">
                <span
                  onClick={() => changeForm("skill")}
                  className="icon1 tns90"
                >
                  4
                </span>
              </div>
              <div className="bullet future current">
                <span
                  onClick={() => changeForm("status")}
                  className="icon1 tns90"
                >
                  5
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-8 mt-3">
            <div className="col-md-12 font-weight-bold text-center h1 text-light">
              Create an Account!
            </div>
            <div className="col-md-12 text-center text-light">( As a Job Seeker )</div>
            <div className="col-md-12 text-center text-light">
              It only takes a couple of minutes to get started!
            </div>
            <div className="col-md-12 text-center">
              <span className="px-3 py-1 backcolor">It's free</span>
            </div>
            <div className="col-md-12 mt-3 text-center d-flex align-items-center justify-content-center">
              <b className="midil backcolor px-3 py-1 text-light">
                Please Verify yourself
              </b>
            </div>
            <div className="container">
              <form onSubmit={formik4.handleSubmit}>
                <div className="form-row d-flex align-items-center justify-content-center">
                  <div className="col-md-8 mb-3">
                    <label htmlFor="lookingJob">Looking For Job</label>
                    <select
                      className="form-control form-control-lg"
                      name="lookingForJob"
                      id="lookingJob"
                      onChange={formik4.handleChange}
                      value={formik4.values.lookingForJob}
                    >
                      <option value="">--Select--</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                    {formik4.errors.lookingForJob && (
                      <p className="inp-alert">
                        {formik4.errors.lookingForJob}
                      </p>
                    )}
                  </div>
                  <div className="col-md-8 mb-3">
                    <label htmlFor="noticePeriod">Notice Period</label>
                    <select
                      className="form-control form-control-lg"
                      name="noticePeriod"
                      id="noticePeriod"
                      onChange={formik4.handleChange}
                      value={
                        (formik4.values.noticePeriod =
                          formik4.values.lookingForJob === "No"
                            ? "No"
                            : formik4.values.noticePeriod)
                      }
                      disabled={formik4.values.lookingForJob === "No"}
                    >
                      <option value="">--Select--</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                    {formik4.errors.noticePeriod &&
                      formik4.values.lookingForJob === "Yes" && (
                        <p className="inp-alert">
                          {formik4.errors.noticePeriod}
                        </p>
                      )}
                  </div>
                  <div className="col-md-8 mb-3">
                    <label htmlFor="immediateJoiner">Immediate Joiner</label>
                    <select
                      className="form-control form-control-lg"
                      name="immediateJoiner"
                      id="immediateJoiner"
                      onChange={formik4.handleChange}
                      value={
                        (formik4.values.immediateJoiner =
                          formik4.values.lookingForJob === "No"
                            ? "No"
                            : formik4.values.immediateJoiner)
                      }
                      disabled={formik4.values.lookingForJob === "No"}
                    >
                      <option value="">--Select--</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                    {formik4.errors.immediateJoiner &&
                      formik4.values.lookingForJob === "Yes" && (
                        <p className="inp-alert">
                          {formik4.errors.immediateJoiner}
                        </p>
                      )}
                  </div>
                  <div
                    className="col-md-8 d-flex"
                    style={{ justifyContent: "space-around" }}
                  >
                    <label>
                      <input
                        className="icon-radio"
                        type="radio"
                        name="test"
                        onClick={(e) => {
                          setEmployeeStatus(e.target.value);
                          setStatusIcon("img/icon/status.png");
                        }}
                        value="One Year Jobless"
                      />
                      <img src="img/icon/status.png" className="icon-reg"></img>
                    </label>
                    <label>
                      <input
                        className="icon-radio"
                        type="radio"
                        name="test"
                        onClick={(e) => {
                          setEmployeeStatus(e.target.value);
                          setStatusIcon("img/icon/icon2.png");
                        }}
                        value="Serving Notice Period"
                      />
                      <img src="img/icon/icon2.png" className="icon-reg"></img>
                    </label>
                    <label>
                      <input
                        className="icon-radio"
                        type="radio"
                        name="test"
                        onClick={(e) => {
                          setEmployeeStatus(e.target.value);
                          setStatusIcon("img/icon/icon3.png");
                        }}
                        value="Looking For Job"
                      />
                      <img src="img/icon/icon3.png" className="icon-reg"></img>
                    </label>
                    <label>
                      <input
                        className="icon-radio"
                        type="radio"
                        name="test"
                        onClick={(e) => {
                          setEmployeeStatus(e.target.value);
                          setStatusIcon("img/icon/icon4.png");
                        }}
                        value="Immediate Joiner"
                      />
                      <img src="img/icon/icon4.png" className="icon-reg"></img>
                    </label>
                  </div>
                  <div
                    className="col-md-8 d-flex"
                    style={{ justifyContent: "space-around" }}
                  >
                    <center>
                      <p className="icon-font"> 1Year Jobless</p>
                    </center>
                    <center>
                      <p className="icon-font"> Serving Notice Period</p>
                    </center>
                    <p className="icon-font"> Looking Job</p>
                    <p className="icon-font"> Immediate Joiner</p>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-center mt-5">
                  <button
                    onClick={handleApi}
                    className="btn btn-pink mb-5 px-5"
                    style={{ fontWeight: "600", fontSize: "16px" }}
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* registration status end */}
    </>
  );
}

export default RegisterJobSheeker;
