/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { WhatsappShareButton, WhatsappIcon } from "react-share";
import { RotatingTriangles } from "react-loader-spinner";
import Nav from "../../Component/Nav";
import { authentication, toaster } from "../../Controller/main_controller";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

export default function FresherJobSearch() {
  const [userDetails, SetUserDetails] = useState("");
  const [techno, setTechno] = useState("");
  const [technoData, SetTechnoData] = useState([]);
  const [experience, setExperience] = useState("");
  const [experienceData, SetExperienceData] = useState([]);
  const [location, setLocation] = useState("");
  const [locationData, SetLocationData] = useState([]);
  const [getpost, setGetpost] = useState([]);
  const [submitCv, setSubmitCv] = useState();
  const [jobDescription, setJobdescription] = useState("");
  const [jobPostId, setJobPostId] = useState("");
  const [sortDate, setSortDate] = useState("ASC");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.title = "Fresherjob Search";
  }, []);

  // Get user details
  useEffect(() => {
    const fetchUserDetails = async () => {
      const userData = await authentication();
      SetUserDetails(userData);
    };
    fetchUserDetails();
  }, []);

  //Get tech list
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/jobSearch/getTechList`,

        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      )
      .then((res) => {
        // console.log(res.data.techList)
        SetTechnoData(res.data.techList);
      })
      .catch((error) => {
        toast.error("Some Error Occurred. Please Try After Some Time", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }, []);

  //Get experience list
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/jobSearch/getFresherExpList`,

        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      )
      .then((res) => {
        SetExperienceData(res.data.expList);
      })
      .catch((error) => {
        toast.error("Some Error Occurred. Please Try After Some Time", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }, []);

  // get location list
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/jobSearch/getLocationList`,

        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      )
      .then((res) => {
        SetLocationData(res.data.locationList);
      })
      .catch((error) => {
        toast.error("Some Error Occurred. Please Try After Some Time", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }, []);

  // get job list
  useEffect(() => {
    joblist();
  }, []);

  useEffect(() => {
    joblist();
  }, [experience]);

  useEffect(() => {
    joblist();
  }, [techno]);

  useEffect(() => {
    joblist();
  }, [location]);

  useEffect(() => {
    joblist();
  }, [sortDate]);

  const joblist = () => {
    setLoading(true);
    setGetpost([]);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/jobSearch/getJobList`,
        {
          tech_code: techno,
          location_code: location,
          exp_code: experience,
          type: "Fresher",
          from_index: 0,
          sort_date: sortDate,
        },
        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      )
      .then((response) => {

        setLoading(false);
        if (response.data.status === "success") {
          setGetpost(response.data.jobsList);
        }
        if (response.data.status === "error") {
          // console.log(response.data);
        }
      })
      .catch((error) => {
        // toast.error("Some Error Occurred. Please Try After Some Time", {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
      });
  };

  // upload cv
  const handleFile = (e) => {
    setSubmitCv(e.target.files[0]);
  };

  // getting jobpostid
  const jobpostcode = (id) => {
    setJobPostId(id);
  };

  //job apply
  const jobApply = () => {
    const toastId = toaster("load");
    const formData = new FormData();
    formData.append("job_post_code", jobPostId);
    formData.append("message", jobDescription);
    formData.append("resume", submitCv);

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/jobSearch/jobApply`,
        formData,
        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      )
      .then((response) => {
        toaster(response.data.status, toastId, response.data.mssg);

        if (response.data.status === "success") {
        }
      })
      .catch((error) => {
        toaster(
          "error",
          toastId,
          "Some Error Occurred. Please Try After Some Time"
        );
      });
  };

  const getPreviousSearch = () => {
    const toastId = toaster("load");

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/jobSearch/getLastSearchDetails`,
        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      )
      .then((response) => {
        toaster(response.data.status, toastId, response.data.mssg);

        if (response.data.status === "success") {
          setLocation(response.data.employeeSearchDetails.location_code);
          setExperience(response.data.employeeSearchDetails.exp_code);
          setTechno(response.data.employeeSearchDetails.tech_code);
        }
      })
      .catch((error) => {
        toaster(
          "error",
          toastId,
          "Some Error Occurred. Please Try After Some Time"
        );
      });
  };

  const clearSearch = () => {
    setLocation("");
    setTechno("");
  };

  return (
    <>
      <Nav />
      <div class="py-1">
        <div class="container-fluid body-padding pt-40">
          <div className="search_filter_div">
            <div class="row justify-c-center my-2" >
              <div class="col-sm-3">
                <div className="form-group">
                  <label id="nameLabel" className="form-label">
                    Search By Technology
                  </label>
                  <select
                    class="form-control form-control-lg"
                    name="technoList"
                    id="technoList"
                    onChange={(e) => {
                      setTechno(e.target.value);
                    }}
                    value={techno}
                  >
                    <option value="">All</option>
                    {technoData.map((item, id) => {
                      return (
                        <>
                          <option value={item._id} key={id._id}>
                            {item.tech_name}
                          </option>
                        </>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div class="col-sm-3">
                <div className="form-group">
                  <label id="nameLabel" className="form-label">
                    Search By Experience
                  </label>
                  <select
                    class="form-control form-control-lg"
                    name="currentExperience"
                    id="currentExperience"
                    onChange={(e) => {
                      setExperience(e.target.value);
                    }}
                    value={experience}
                  >
                    {experienceData.map((item, id) => {
                      return (
                        <>
                          <option value={item._id} key={id._id}>
                            {item.experience}
                          </option>
                        </>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div class="col-sm-3">
                <div className="form-group">
                  <label id="nameLabel" className="form-label">
                    Search By Location
                  </label>
                  <select
                    class="form-control form-control-lg"
                    name="locationList"
                    id="locationList"
                    onChange={(e) => {
                      setLocation(e.target.value);
                    }}
                    value={location}
                  >
                    <option value="">All</option>
                    {locationData.map((item, id) => {
                      return (
                        <>
                          <option value={item._id} key={id._id}>
                            {`${item.area}, ${item.city}, ${item.state}`}
                          </option>
                        </>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div class="col-sm-3">
                <div className="form-group">
                  <label id="nameLabel" className="form-label">
                    Sort By
                  </label>
                  <select
                    class="form-control form-control-lg"
                    name="sortBy"
                    id="sortBy"
                    onChange={(e) => setSortDate(e.target.value)}
                  >
                    <option value="ASC">Post Date ASC</option>
                    <option value="DESC">Post Date DESC</option>
                  </select>
                </div>
              </div>
            </div>

            <div style={{ textAlign: "center", marginBottom: "10px" }}>
              {/* <span
                onClick={getPreviousSearch}
                style={{ backgroundColor: "#ff6ee0" }}
                className="button_badges"
              >
                <i class="fa-solid fa-circle-chevron-left"></i>
                &nbsp; Show Previous Search
              </span> */}
              &nbsp;&nbsp;
              <span
                onClick={clearSearch}
                style={{ backgroundColor: "#ff4d4d", color: "#FFF" }}
                className="button_badges"
              >
                <i class="fa-solid fa-magnifying-glass-minus"></i>
                &nbsp; Clear All Search
              </span>
            </div>
          </div>

          <h3 className="search_list_heading">Here Is Your Prefer Fresher Job List</h3>

          {loading == true ? (
            <center>
              <RotatingTriangles
                visible={true}
                height="80"
                width="80"
                ariaLabel="rotating-triangels-loading"
                wrapperStyle={{}}
                wrapperClass="rotating-triangels-wrapper"
              />
            </center>
          ) : (
            <div class="row mt-2">
              <table class="table table-m-view bg-light" style={{ border: "none", fontFamily: "sans-serif" }}>
                <thead class="thead-pink" style={{ backgroundColor: "#ffd9f3" }}>
                  <tr>
                    <th scope="col">Job Name</th>
                    <th scope="col">Job posted by</th>

                    <th scope="col">Employer Email</th>

                    <th scope="col">Post Date</th>
                    <th scope="col">Job Description</th>
                    <th scope="col">Technology</th>
                    <th scope="col">Company Name</th>
                    <th scope="col">Salary</th>
                    {/* <th scope="col">Experience</th> */}
                    <th scope="col">Location</th>
                    <th scope="col">Sevice Area</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                {getpost != [] &&
                  getpost.map((data, i2) => {
                    return (
                      <tbody>
                        <tr key={i2}>
                          
                            <td style={{ color: "Highlight", fontWeight: "bold" }}>
                            <Link
                            to={`${process.env.REACT_APP_URL}/job-details/${data._id}`}
                            target="_blank"
                          >{data.job_title}
                          </Link>
                          </td>
                          
                          <td>{data.post_employee_type}</td>

                          <td>{data.email}</td>

                          <td>{data.post_datetime.slice(0, 10)}</td>
                          <td>{data.description}</td>
                          <td>{data.technology.length > 0 ? data.technology[0].tech_name : null}</td>
                          <td>{data.company_details.length > 0 ? data.company_details[0].company_name : null}</td>
                          <td>{data.salary_range.length > 0 ? data.salary_range[0].salary_range : null}</td>
                          {/* <td>{data.experience_master.length>0?data.experience_master[0].experience:null}</td> */}
                         <td> {data.location.map((item1, id) => {
                            return (
                              <div
                                key={id}
                              >{`${item1.area},${item1.city},${item1.state}`}</div>
                            );
                          })}
                          </td>
                          <td>{data.service_area_details.length > 0 ? data.service_area_details[0].service_area : null}</td>
                          <td style={{ display: "block" }}>
                            {userDetails.employee_type === "Hr" ||
                              data.post_employee_type != "Hr" ? null : (
                              <button
                                type="button"
                                data-toggle="modal"
                                data-target="#staticBackdrop1"
                                onClick={() => jobpostcode(data._id)}
                                class="btn btn-outline-info"
                                style={{ fontWeight: "500",whiteSpace:"nowrap" }}
                              >

                                Apply Now
                              </button>
                            )}
                            <button class="share-btn">
                              <WhatsappShareButton
                                title="Sharing Content"
                                url={`${process.env.REACT_APP_URL}/job-details/${data._id}`}
                              >
                                <WhatsappIcon
                                  logoFillColor="white"
                                  round={true}
                                  size={35}
                                ></WhatsappIcon>
                              </WhatsappShareButton>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    );
                  })}
              </table>
            </div>
          )}

          {/* modal body */}

          <div
            class="modal fade"
            id="staticBackdrop1"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="staticBackdropLabel">
                    Do you want to Apply?
                  </h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="row justify-c-center my-2">
                    <div class="col-sm-12">
                      <div className="form-group">
                        <label id="nameLabel" className="form-label">
                          Upload Your CV Here
                        </label>
                        <input
                          type="file"
                          id="file"
                          name="file"
                          onChange={handleFile}
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div class="col-sm-12">
                      <div className="form-group">
                        <label id="nameLabel" className="form-label">
                          Message
                        </label>
                        <textarea
                          type="text"
                          placeholder="Enter your message"
                          rows={5}
                          cols="60"
                          value={jobDescription}
                          onChange={(e) => setJobdescription(e.target.value)}
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>

                  <button
                    class="btn btn-success"
                    style={{ width: "100%", borderRadius: "20px" }}
                    onClick={jobApply}
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
