/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Rsidebar from "../../Component/Rasidebar";
import Lsidebar from "../../Component/Lasidebar";
import Nav from "../../Component/Nav";
import { authentication } from "../../Controller/main_controller";


export default function Training(props) {
  const [userDetails, SetUserDetails] = useState("");

  const [frstName, setFrstName] = useState("");

  useEffect(() => {
    document.title = "Thank you";
  });

  // Get user details
  useEffect(() => {
    const fetchUserDetails = async () => {
      const userData = await authentication();
      SetUserDetails(userData);

      setFrstName(userData.first_name);
    };
    fetchUserDetails();
  }, []);





  return (
    <>
      <Nav />
      <div className="py-2">
        <div className="container-fluid pt-150">
          <div className="row justify-content-around">
            <main className="col col-xl-8 order-xl-2 col-lg-12 order-lg-1 col-md-12 col-sm-12 col-12">
              <div className="box shadow-sm border rounded bg-white mb-3 osahan-share-post">
                <center>
                  <h1 style={{ fontSize: 45, marginTop: "20px" }}>Thank You !</h1>
                  <p className="font-sm-15">
                    Thank you{" "}
                    <Link to="/my-profile">
                      <span style={{ fontSize: 23, color: "blue" }}>
                        {frstName}
                      </span>{" "}
                    </Link>
                    for posting a new job
                  </p>
                  <br />
                  <img src="img/icon/right.png" className="thank-you-img" style={{width:"110px"}} />
                  <br /><br />
                  {/* <br />
                  <br />
                  <h1 style={{ fontSize: 35 }}>Check Your Email</h1>
                  <p style={{ fontSize: 22 }}>
                    If you didn't receive any mail contact{" "}
                    <Link to="mailto:info@redprismgroup.com">
                    <span style={{ fontSize: 28, color: "green" }}>
                      {" "}
                      info@redprismgroup.com{" "}
                    </span>
                    </Link>
                  </p> */}
                </center>
              </div>
            </main>

            <Lsidebar userDetails={userDetails} />
            <Rsidebar />
          </div>
        </div>
      </div>
    </>
  );
}
