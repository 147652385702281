/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import axios from "axios";

import { toast } from "react-toastify";
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import { LiaComments } from "react-icons/lia";
import { IoSend } from "react-icons/io5";
import { AiFillCloseSquare } from "react-icons/ai";
import { MdDeleteForever } from "react-icons/md";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { WhatsappShareButton, WhatsappIcon } from "react-share";
import {
  _,
  __,
  authentication,
  toaster,
} from "../../Controller/main_controller";
import { Link } from "react-router-dom";
import EmojiPicker from "emoji-picker-react";
import { FaUserTag } from "react-icons/fa";

import { BsFillEmojiSmileFill } from "react-icons/bs";

export default function FeedPost({ feed_post_data, refreshPost, feed_num }) {
  const data = feed_post_data;


  let dataMyLike = "No";
  if (data.user_like.length > 0) {
    dataMyLike = "Yes";
  }

  const [employeeId, setEmployeeId] = useState(data.employee_details[0]._id);
  const [userDetails, SetUserDetails] = useState("");
  const [comment, setComment] = useState(""); // this is for comment value
  const [comentList, setcomentList] = useState({});
  const [myLike, setmyLike] = useState(dataMyLike); //total comment & like
  const [totalLike, settotalLike] = useState(data.totalLike); //total comment & like
  const [totalComment, settotalComment] = useState(data.totalComments); //total comment & like
  const [myTagList, setMyTagList] = useState([]);

  //comment tag list
  const [myCommentTagList, setMyCommentTagList] = useState([]);
  const [tagBuddies, settagBuddies] = useState([]);
  const [buddiesSearchDetails, setbuddiesSearchDetails] = useState("");
  const [emojiStore, setEmojiStore] = useState("");

  // Get user details
  useEffect(() => {
    // console.log("hsajasj",data)
    const fetchUserDetails = async () => {
      const userData = await authentication();
      SetUserDetails(userData);
    };
    fetchUserDetails();
    
  }, []);




  // Delete Feedpost
  const deleteFeedpost = (feedpostId) => {
    const toastId = toaster("load");
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/profileDetails/deleteMyFeedPost`,
        {
          feeds_post_code: feedpostId,
        },
        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      )
      .then((response) => {
        toaster(response.data.status, toastId, response.data.mssg);
        if (response.data.status === "success") {
          refreshPost();
        }
        if (response.data.status === "error") {
          // toast.error(`${response.data.mssg}`);
        }
      })
      .catch((error) => {
        toaster(
          "error",
          toastId,
          "Some Error Occurred. Please Try After Some Time"
        );
      });
  };


  // Like section with backend

  const handellike = (postId) => {
    const toastId = toaster("load");
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/home/saveFeedsPostLikeDislike`,
        { feeds_post_code: postId, type: "Like" },
        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      )
      .then((response) => {
        toaster(response.data.status, toastId, response.data.mssg);

        if (response.data.status === "success") {
          setmyLike("Yes");
          getPostTotalLikeComments(postId);
        }
      })
      .catch((error) => {
        toaster(
          "error",
          toastId,
          "Some Error Occurred. Please Try After Some Time"
        );
      });
  };

  const getPostTotalLikeComments = (postId) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/home/getTotalLikeComments`,
        { feeds_post_code: postId },
        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      )
      .then((response) => {
        if (response.data.status === "success") {
          settotalLike(response.data.totalLikes);
          settotalComment(response.data.totalComments);
        }
        if (response.data.status === "error") {
          toast.error(`${response.data.mssg}`);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // //  Dislike section with backend

  const handelDislike = (postId) => {
    const toastId = toaster("load");
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/home/saveFeedsPostLikeDislike`,
        { feeds_post_code: postId, type: "Dislike" },
        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      )
      .then((response) => {
        toaster(response.data.status, toastId, response.data.mssg);

        if (response.data.status === "success") {
          setmyLike("No");
          getPostTotalLikeComments(postId);
        }
      })
      .catch((error) => {
        toaster(
          "error",
          toastId,
          "Some Error Occurred. Please Try After Some Time"
        );
      });
  };

  // comment section api

  const handleComent = (id) => {
    const toastId = toaster("load");
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/home/saveFeedsComment`,
        {
          feeds_post_code: id,
          comment: comment,
          tag_buddies: tagBuddies,
        },
        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      )
      .then((response) => {
        toaster(response.data.status, toastId, response.data.mssg);
        if (response.data.status === "success") {
          for (
            let index = 0;
            index < __("cmmnt_tag_buddies_" + feed_num).length;
            index++
          ) {
            __("cmmnt_tag_buddies_" + feed_num)[index].checked = false;
          }
          settagBuddies([]);
          disblk(id);
          getPostTotalLikeComments(id);
          setComment("");
        }
      })
      .catch((error) => {
        toaster(
          "error",
          toastId,
          "Some Error Occurred. Please Try After Some Time"
        );
      });
  };

  const handleThoughtsChange1 = (e) => {
    setComment(e.target.value);
  };

  const disnan = (postId) => {
    document.getElementById("open_" + postId).style.display = "none";
    document.getElementById("open1_" + postId).style.display = "none";
  };

  // comment list section api

  const disblk = (postId) => {
    document.getElementById("open_" + postId).style.display = "block";
    document.getElementById("open1_" + postId).style.display = "block";
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/home/getFeedsPostCommentsList`,
        {
          feeds_post_code: postId,
        },
        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      )
      .then((response) => {
        if (response.data.status === "success") {
          setcomentList({
            feedId: postId,
            commentlist: response.data.feedsCommentsList,
          });
        }
        if (response.data.status === "error") {
          // toast.error(`${response.data.mssg}`);
        }
      })
      .catch((error) => { });
  };

  // comment delete section api
  const removeActivity = (commentId, feedpostId) => {
    const toastId = toaster("load");
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/home/deleteFeedsComment`,
        {
          feeds_comment_code: commentId,
        },
        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      )
      .then((response) => {
        toaster(response.data.status, toastId, response.data.mssg);
        if (response.data.status === "success") {
          disblk(feedpostId);
          getPostTotalLikeComments(feedpostId);
        }
      })
      .catch((error) => {
        toaster(
          "error",
          toastId,
          "Some Error Occurred. Please Try After Some Time"
        );
      });
  };

  const formatDate = (date) => {
    const dateString = date; // ISO8601 compliant dateString
    const D = new Date(dateString);
    const result =
      D.getDate() +
      " " +
      D.toLocaleString("en-us", { month: "short" }) +
      " " +
      D.getFullYear() +
      "," +
      D.getHours() +
      ":" +
      D.getMinutes();
    return result;
  };

  const [showPicker, setShowPicker] = useState(false);

  const onEmojiClick = (e) => {
    // console.log(e);
    const sym = e.unified.split("-");
    const codeArray = [];
    sym.forEach((el) => {
      codeArray.push("0x" + el);
    });
    let emoji = String.fromCodePoint(...codeArray);

    setEmojiStore(emoji);
    // console.log(emojiObject);
    // setmssg((prevInput) => prevInput + emojiObject.emoji);
    // setShowPicker(false);
  };

  useEffect(() => {
    if (emojiStore != "") {
      setComment(comment + emojiStore);
    }
  }, [emojiStore]);

  const getTagList = (postid) => {
    setMyTagList([]);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/tagDetails/showFeedTagDetails`,
        {
          feeds_post_code: postid,
        },
        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      )
      .then((res) => {
        if (res.data.tagEmployeeList.length > 0) {
          setMyTagList(res.data.tagEmployeeList);
        } else {
          setMyTagList([]);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getCommentTagList = (postid) => {
    setMyTagList([]);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/tagDetails/showCommentsTagDetails`,
        {
          comments_code: postid,
        },
        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      )
      .then((res) => {
        if (res.data.tagEmployeeList.length > 0) {
          setMyTagList(res.data.tagEmployeeList);
        } else {
          setMyTagList([]);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getMyBuddiesList();
  }, [buddiesSearchDetails]);

  const getMyBuddiesList = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/tagDetails/showMyBuddysList`,
        {
          search_details: buddiesSearchDetails,
        },
        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      )
      .then((res) => {
        if (res.data.employeeList.length > 0) {
          setMyCommentTagList(res.data.employeeList);
        } else {
          setMyCommentTagList([]);
        }
      })
      .catch((error) => {
        // console.error(error);
      });
  };

  const cmmntPostTagBuddies = () => {
    let tagBuddiesArray = [];
    for (
      let index = 0;
      index < __("cmmnt_tag_buddies_" + feed_num).length;
      index++
    ) {
      if (__("cmmnt_tag_buddies_" + feed_num)[index].checked === true) {
        tagBuddiesArray.push({
          employee_code: __("cmmnt_tag_buddies_" + feed_num)[
            index
          ].getAttribute("data-employee-id"),
        });
      }
    }
    settagBuddies(tagBuddiesArray);
  };

  return (
    <>
      <div class="box border rounded bg-white mb-3 osahan-post">
        <div class="p-3 d-flex align-items-center border-bottom osahan-post-header">
          <Link
            to={`${process.env.REACT_APP_URL}/profile-details/${employeeId}`}
            class="dropdown-list-image mr-3"
          >
            <img
              class="rounded-circle"
              src={`${process.env.REACT_APP_API_URL}/${data.employee_details[0].employee_image}`}
              alt=""
            />
            <div class="status-indicator bg-success"></div>
          </Link>
          <div class="font-weight-bold">
            <Link
              to={`${process.env.REACT_APP_URL}/profile-details/${employeeId}`}
              class="text-dark"
            >
              {`${data.employee_details[0].first_name} ${data.employee_details[0].last_name} (${data.employee_details[0].employee_type})`}
            </Link>

            <div class="small text-gray-500">
              {formatDate(data.post_datetime)}
              {data.totalTag > 0 ? (
                <span
                  data-toggle="modal"
                  data-target={`#myTagList_${feed_num}`}
                  onClick={() => getTagList(data._id)}
                  style={{
                    marginLeft: 15,
                    fontSize: 16,
                    fontWeight: "bold",
                    color: "#888edd",
                    cursor: "pointer",
                  }}
                >
                  #{data.totalTag}BuddiesTag
                </span>
              ) : null}
            </div>
          </div>
          <span class="ml-auto small" style={{ cursor: "pointer" }}>
            {userDetails._id === data.employee_details[0]._id ? (
              <RiDeleteBin6Fill
                size={19}
                className="text-danger"
                data-toggle="modal"
                data-target={`#deletPostModal_${feed_num}`}
              // onClick={() => deleteFeedpost(data._id)}
              />
            ) : null}
          </span>
        </div>
        <div class="p-3 border-bottom osahan-post-body">
          <p
            class="mb-0"
            // key={i}
            style={{ fontSize: 20 }}
          >
            <div>{data.post_details}</div>
          </p>
        </div>
        <div class="p-3 osahan-post-footer text-center d-flex jcc">
          <button
            class={`mr-3 text-secondary btn btn-link feed_like_btn_` + data._id}
            onClick={
              myLike === "Yes"
                ? () => handelDislike(data._id)
                : () => handellike(data._id)
            }
          >
            {myLike === "Yes" ? (
              <AiFillHeart
                size={18}
                className="text-danger"
                style={{ cursor: "pointer" }}
              />
            ) : (
              <AiOutlineHeart size={18} style={{ cursor: "pointer" }} />
            )}
            {totalLike}
          </button>
          <button
            class="mr-3 text-secondary btn btn-link"
            onClick={() => disblk(data._id)}
          >
            <LiaComments size={19} className="text-primary" />
            {totalComment}
          </button>
          <WhatsappShareButton
            title="Post Details Here"
            url={`${process.env.REACT_APP_URL}/post-details/${data._id}`}
          >
            <WhatsappIcon
              logoFillColor="white"
              round={true}
              size={25}
            ></WhatsappIcon>
          </WhatsappShareButton>
        </div>
        {/* modal body */}
        <div
          class="modal fade"
          id={`deletPostModal_${feed_num}`}
          data-backdrop="static"
          data-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">
                  Do you want to delete your post ?
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <br />
                <form className=" d-sm-inline-block form-inline m-auto my-md-0 mw-100 navbar-search">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <button
                      class=" btn btn-danger"
                      data-dismiss="modal"
                      onClick={() => deleteFeedpost(data._id)}
                      style={{
                        marginLeft: 30,
                        marginRight: 20,
                        width: "80px",
                        borderRadius: "20px",
                      }}
                    >
                      Yes
                    </button>
                    <button
                      class="btn btn-success "
                      data-dismiss="modal"
                      style={{ width: "80px", borderRadius: "20px" }}
                    >
                      No
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* comment list  */}
        <div id={`open1_` + data._id}>
          {comentList.feedId === data._id &&
            comentList.commentlist.map((list, listid) => {
              return (
                <>
                  <div class="border m-3 p-2 rounded">
                    <div class="dropdown-list-image mr-3 d-flex pl-3">
                      {/* <Link to={`${process.env.REACT_APP_URL}/profile-details/${employeeId}`}> */}
                      <img
                        class="rounded-circle"
                        src={`${process.env.REACT_APP_API_URL}/${list.employee_details[0].employee_image}`}
                        alt=""
                      />
                      {/* </Link> */}
                      <div class="font-weight-bold pl-1" style={{whiteSpace:"nowrap"}}>
                        {/* <Link
                          to={`${process.env.REACT_APP_URL}/profile-details/${employeeId}`}
                          class="text-truncate"
                        > */}
                          {`${list.employee_details[0].first_name} ${list.employee_details[0].last_name}`}
                        {/* </Link> */}
                        <div
                          class="small text-gray-500 pl-1"
                          style={{ textWrap: "nowrap" }}
                        >
                          {list.employee_details[0].employee_type}
                          <span
                            data-toggle="modal"
                            data-target={`#myTagList_${feed_num}`}
                            onClick={() => getCommentTagList(list._id)}
                            style={{
                              marginLeft: 20,
                              fontSize: 12,
                              fontWeight: "bold",
                              color: "#888edd",
                              cursor: "pointer",
                            }}
                          >
                            {list.totalTag > 0
                              ? `#${list.totalTag}BuddiesTag`
                              : null}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        paddingLeft: 20,
                        paddingRight: 20,
                      }}
                    >
                      <p
                        class="my-3 px-2 py-1"
                        key={listid}
                        style={{
                          // fontWeight: "bold",
                          fontSize: 15,
                        }}
                      >
                        {list.comment}
                      </p>

                      {userDetails._id === list.employee_details[0]._id ? (
                        <button
                          style={{
                            fontWeight: "bold",
                            fontSize: "25",
                            border: "none",
                          }}
                          onClick={() => removeActivity(list._id, data._id)}
                        >
                          <MdDeleteForever size={20} className="text-danger" />
                        </button>
                      ) : null}
                    </div>
                  </div>
                </>
              );
            })}
        </div>

        <section
          id={`open_` + data._id}
          style={{ backgroundColor: "#eee", display: "none" }}
        >
          <div class="container m-0 p-0">
            <div class="row d-flex justify-content-center">
              <div class="col-md-12">
                <div class="card">
                  <div
                    class="card-footer py-3 border-0"
                    style={{ backgroundColor: "#fff" }}
                  >
                    <div class="d-flex flex-start w-100">
                      <img
                        src={`${process.env.REACT_APP_API_URL}/${userDetails.employee_image}`}
                        style={{
                          height: "30px",
                          borderRadius: "50%",
                          marginRight: "10px",
                        }}
                      />
                      <div class="form-outline w-100">
                        <textarea
                          class="form-control"
                          id="textAreaExample"
                          rows="3"
                          placeholder="Comment...."
                          style={{ background: "#fff" }}
                          value={comment}
                          onChange={handleThoughtsChange1}
                        ></textarea>
                      </div>
                      <button
                        type="button"
                        style={{
                          background: "none",

                          border: "none",

                          cursor: "pointer",
                          outline: "inherit",
                        }}
                        onClick={() => {
                          handleComent(data._id);
                          // fetchComment(data._id);
                        }}
                      >
                        <IoSend size={35} className="text-primary" />
                      </button>
                    </div>
                    <div
                      class="float-end mt-2 pt-1 d-flex justify-content-between"
                      style={{
                        marginLeft: 15,
                        fontSize: 12,
                        fontWeight: "bold",
                        color: "#888edd",
                        cursor: "pointer",
                      }}
                    >
                      <div>
                        <a
                          className="text-link mr-2"
                          data-toggle="modal"
                          data-target={`#myCommentTagList_${feed_num}`}
                        >
                          <FaUserTag className="text-primary" size={25} />
                        </a>

                        <div className="btn-group dropup">
                          <a
                            style={{ cursor: "pointer" }}
                            className="text-link mr-2"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            data-bs-auto-close="outside"
                          >
                            <BsFillEmojiSmileFill
                              className="text-success"
                              size={25}
                            />
                          </a>
                          <div
                            className="dropdown-menu"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <EmojiPicker
                              pickerStyle={{ width: "100%" }}
                              onEmojiClick={onEmojiClick}
                            />
                          </div>
                        </div>
                        {tagBuddies.length > 0
                          ? "#" + tagBuddies.length + "BuddiesTag"
                          : null}
                      </div>
                      <div>
                        <button
                          className="btn btn-outline-danger"
                          onClick={() => disnan(data._id)}
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* comment section end */}

        {/* Show My Buddies List Modal  */}

        <div
          class="modal fade"
          id={`myTagList_${feed_num}`}
          data-backdrop="static"
          data-keyboard="true"
          tabindex="-1"
          aria-labelledby={`myBuddiesListLabel_${feed_num}`}
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div className="modal-header" style={{ padding: "10px 15px" }}>
                <h5 class="modal-title" id={`myBuddiesListLabel_${feed_num}`}>
                  Tagged buddies
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                {myTagList.length > 0 &&
                  myTagList.map((data, i3) => {
                    return (
                      <div
                        className="p-3 d-flex align-items-center border-bottom osahan-post-header"
                        key={i3}
                      >
                        <label className="tag_list_label">
                          <Link
                            target="_blank"
                            to={`${process.env.REACT_APP_URL}/profile-details/${data.employee_details[0]._id}`}
                            class="text-truncate"
                          >
                            <img
                              className="rounded-circle show-image"
                              src={`${process.env.REACT_APP_API_URL}/${data.employee_details[0].employee_image}`}
                              alt
                             
                            />
                          </Link>
                        </label>
                        <label className="font-weight-bold">
                          <Link
                            to={`${process.env.REACT_APP_URL}/profile-details/${data.employee_details[0]._id}`}
                            class="text-truncate"
                            target="_blank"
                          >
                            {data.employee_details[0].first_name}{" "}
                            {data.employee_details[0].last_name}
                          </Link>
                          <div className="small text-gray-500">
                            {data.employee_details[0].employee_type}
                          </div>
                        </label>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
        <div
          class="modal fade"
          id={`myCommentTagList_${feed_num}`}
          data-backdrop="static"
          data-keyboard="true"
          tabindex="-1"
          aria-labelledby={`myCommentTagListLabel_${feed_num}`}
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div className="modal-header" style={{ padding: "10px 15px" }}>
                <h5
                  class="modal-title"
                  id={`myCommentTagListLabel_${feed_num}`}
                >
                  Here you can tag your buddies
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <input
                  style={{ display: "none" }}
                  type="text"
                  onChange={(e) => setbuddiesSearchDetails(e.target.value)}
                  className="form-control"
                  placeholder="Search Buddies"
                />

                {myCommentTagList.length > 0 &&
                  myCommentTagList.map((data, i3) => {
                    return (
                      <div
                        className="p-3 d-flex align-items-center border-bottom osahan-post-header"
                        key={i3}
                      >
                        <a>
                          <input
                            type="checkbox"
                            className={`mr-3 cmmnt_tag_buddies_${feed_num}`}
                            id={`cmmnt_tag_buddies_${i3}_${feed_num}`}
                            data-employee-id={data.employee_details[0]._id}
                            onChange={cmmntPostTagBuddies}
                          />
                        </a>
                        <label
                          for={`cmmnt_tag_buddies_${i3}_${feed_num}`}
                          className="tag_list_label"
                        >
                          <a className="dropdown-list-image">
                            <img
                              className="rounded-circle show-image"
                              src={`${process.env.REACT_APP_API_URL}/${data.employee_details[0].employee_image}`}
                              alt
                            />
                          </a>
                        </label>
                        <label
                          className="font-weight-bold"
                          for={`cmmnt_tag_buddies_${i3}_${feed_num}`}
                        >
                          <a className="text-truncate">
                            {data.employee_details[0].first_name}{" "}
                            {data.employee_details[0].last_name}
                          </a>
                          <div className="small text-gray-500">
                            {data.employee_details[0].employee_type}
                          </div>
                        </label>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
