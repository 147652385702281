/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useRef, useContext } from "react";
import axios from "axios";

import { toast } from "react-toastify";
import { _, authentication, toaster } from "../../Controller/main_controller";
import { RotatingTriangles } from "react-loader-spinner";
import { GrAttachment } from "react-icons/gr";
import { Link } from "react-router-dom";
import { MdDeleteForever } from "react-icons/md";
import EmojiPicker from "emoji-picker-react";
import Settings from "../Settings/Settings";
import { BsFillEmojiSmileFill } from "react-icons/bs";

const MainChat = ({ chatEmployeeCode, userDetails, getChatList }) => {
  const [employeeDetails, setemployeeDetails] = useState([]);
  const [mssgList, setmssgList] = useState([]);
  const [mssg, setmssg] = useState("");
  const [currentMssgLength, setcurrentMssgLength] = useState(0);
  const [fileupload, setFileUpload] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [fileName, setfileName] = useState("File Uploaded");
  const [emojiStore, setEmojiStore] = useState("");

  useEffect(() => {
    getMessages();
  }, []);

  useEffect(() => {
    if (mssgList.length > currentMssgLength) {
      scrollToBottom();
      setcurrentMssgLength(mssgList.length);
    }
  }, [mssgList]);
  // changing color for chat & setting local storage

  //Get Message list
  const getMessages = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/message/showMessages`,
        {
          chat_employee_code: chatEmployeeCode,
          from_index: 0,
        },
        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      )
      .then((res) => {
        if (res.data.chatUserDetails) {
          setemployeeDetails(res.data.chatUserDetails);
        }

        if (res.data.messageList) {
          setmssgList(res.data.messageList);
        }

        setTimeout(function () {
          getMessages();
        }, 1000);
      })
      .catch((error) => {
        toast.error("Some Error Occurred. Please Try After Some Time", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };
  const fileEmpty = () => {
    setFileUpload(null);
    _("#attach_file").value = "";
    setUploadedFile("");
  };
  //Send Message File
  const handleFile = (e) => {
    setFileUpload(e.target.files[0]);
    setfileName(e.target.files[0].name);

    setUploadedFile("file");
  };

  const sendMessage = () => {
    const toastId = toaster("load");
    const formdata = new FormData();
    formdata.append("chat_employee_code", chatEmployeeCode);
    formdata.append("mssg", mssg);
    formdata.append("attachment", fileupload);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/message/sentMessage`,
        formdata,

        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      )
      .then((res) => {
        toaster(res.data.status, toastId, res.data.mssg);
        if (res.data.status === "success") {
          fileEmpty();
          setmssg("");
          getChatList();
        }
      })
      .catch((error) => {
        toast.error("Some Error Occurred. Please Try After Some Time", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const bottomEl = useRef(null);

  const scrollToBottom = () => {
    bottomEl?.current?.scrollIntoView({ behavior: "smooth" });
  };

  const formatDate = (date) => {
    const dateString = date; // ISO8601 compliant dateString
    const D = new Date(dateString);
    const result =
      D.getDate() +
      "-" +
      (D.getMonth() + 1) +
      "-" +
      D.getFullYear() +
      " " +
      D.getHours() +
      ":" +
      D.getMinutes();
    return result;
  };
  const [showPicker, setShowPicker] = useState(false);

  const onEmojiClick = (e) => {
    const sym = e.unified.split("-");
    const codeArray = [];
    sym.forEach((el) => {
      codeArray.push("0x" + el);
    });
    let emoji = String.fromCodePoint(...codeArray);
    setEmojiStore(emoji);
    // setmssg(mssg + emoji);
    // console.log(emojiObject);
    // setmssg((prevInput) => prevInput + emojiObject.emoji);
    // setShowPicker(false);
  };

  useEffect(() => {
    if (emojiStore != "") {
      setmssg(mssg + emojiStore);
      
    }
  }, [emojiStore]);

  // color change main chat
  const [mainClassChat, setmainClassChat] = useState(
    localStorage.getItem("themeNameChat")
      ? localStorage.getItem("themeNameChat")
      : "yellow"
  );
  return (
    <>
      <div style={{ display: "none" }}>
        <Settings
          mainClassChat={mainClassChat}
          setmainClassChat={setmainClassChat}
        />
      </div>
      {employeeDetails ? (
        <>
          <div className="p-1 d-flex align-items-center  border-bottom osahan-post-header">
            <div className="font-weight-bold d-flex mr-1 p-1 overflow-hidden">
              <div className="dropdown-list-image mr-1 ml-1 mb-auto">
                <img
                  className="rounded-circle show-image"
                  src={`${process.env.REACT_APP_API_URL}/${employeeDetails.employee_image}`}
                  alt=""
                />
              </div>
              <div className="mr-1">
                <div className="text-truncate h6 mt-2">
                  {employeeDetails.first_name} {employeeDetails.last_name}
                </div>
              </div>
            </div>
          </div>
          <div
            className={`${"osahan-chat-box p-3 border-top border-bottom bg-light"} ${mainClassChat}`}
          >
            {mssgList.length > 0 &&
              mssgList.map((data, p) => {
                return (
                  <div
                    key={p}
                    className={`align-items-center osahan-post-header mssg-item ${
                      data.from_employee_code === userDetails._id
                        ? `${"right-item"} ${mainClassChat} `
                        : `${"left-item "} ${mainClassChat}`
                    } `}
                  >
                    <p>
                      {data.attachment != "" ? (
                        <a
                          className="text-danger"
                          download
                          target="_blank"
                          href={`${process.env.REACT_APP_API_URL}/${data.attachment}`}
                        >
                          Click Here to Download Your Attchment
                        </a>
                      ) : null}

                      <br />

                      {data.mssg}
                    </p>
                    <span className="ml-auto mb-auto">
                      <div className="text-right text-muted pt-1 small mr-2">
                        {formatDate(data.mssg_datetime)}
                      </div>
                    </span>
                  </div>
                );
              })}
            <div ref={bottomEl}></div>
          </div>

          <div
            className="w-100 border-top border-bottom"
            // style={{ position: "relative", width: "300px" }}
          >
            <textarea
              placeholder="Write a message...."
              className="form-control border-0 p-3 shadow-none"
              rows="2"
              onChange={(e) => setmssg(e.target.value)}
              value={mssg}
              style={{
                padding: "7px 30px 7px 10px",
                width: "calc(100% - 40px)",
                borderRadius: "3px",
                border: "1px solid #999",
                marginBottom: "10px",
              }}
            ></textarea>
          </div>

          <div className="p-3 d-flex justify-content-between">
            <div className="d-flex">
            <div className="btn-group dropup">
                      <a
                        style={{ cursor: "pointer" }}
                        className="text-link mr-2"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        data-bs-auto-close="outside"
                      >
                        <BsFillEmojiSmileFill
                          className="text-success"
                          size={25}
                        />
                      </a>
                      <div
                        className="dropdown-menu"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <EmojiPicker
                          pickerStyle={{ width: "100%" }}
                          onEmojiClick={onEmojiClick}
                        />
                      </div>
                    </div>
              <div className="image-upload">
                <label for="attach_file">
                  <GrAttachment
                    size={23}
                    className="text-success"
                    
                  />
                </label>

                <input id="attach_file" type="file" onChange={handleFile} />
                {uploadedFile === "file" ? (
                  <p
                    style={{
                      display: "inline",
                      backgroundColor: "#90cbff",
                      padding: "7px",
                      borderRadius: "10px",
                    }}
                    id="none"
                  >
                    {fileName}{" "}
                    <button className="btn btn-sm" onClick={fileEmpty}>
                      <MdDeleteForever size={25} className="text-danger pb-1" />
                    </button>
                  </p>
                ) : null}
              </div>
            </div>
            <button
              type="button"
              className="btn btn-info btn-sm rounded text-right"
              onClick={sendMessage}
            >
              <i className="feather-send"></i> Send
            </button>
          </div>
        </>
      ) : (
        <>
          <h2 className="not-data">Not Found any Data</h2>
        </>
      )}
    </>
  );
};

export default MainChat;
