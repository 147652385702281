/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";
import { _, authentication, toaster } from "../../Controller/main_controller";
import { toast } from "react-toastify";
import { FaEdit } from "react-icons/fa";
import { RiDeleteBin3Fill } from "react-icons/ri";
import { SiPostman } from "react-icons/si";
import { BsCardChecklist } from "react-icons/bs";
import Rsidebar from "../../Component/Rasidebar";
import Lsidebar from "../../Component/Lasidebar";
import Nav from "../../Component/Nav";
import { AiFillCloseSquare } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

const JobPost = () => {

  const navigate = useNavigate();

  // Get user details

  const [userDetails, SetUserDetails] = useState("");

  useEffect(() => {
    const fetchUserDetails = async () => {
      const userData = await authentication();
      SetUserDetails(userData);
    };
    fetchUserDetails();
  }, []);

  // Title
  useEffect(() => {
    document.title = "Job Post";
  }, []);

  const [title, setTitle] = useState("");
  const [designation, setDesignation] = useState("");
  const [email, setEmail] = useState("");
  const [phnumber, setPhNumber] = useState("");
  const [description, setDescription] = useState("");
  const [getLocation, setgetLocation] = useState([]);
  const [getLocationList, setgetLocationList] = useState([]);

  const [company, setCompany] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const [technicalSkills, setTechnicalSkills] = useState("");
  const [technicalSkillsList, setTechnicalSkillsList] = useState([]);
  const [salaryRange, setSalaryRange] = useState("");
  const [salaryRangeList, setSalaryRangeList] = useState([]);
  const [experienceYear, setExperienceYear] = useState("");
  const [experienceYearList, setExperienceYearList] = useState([]);
  const [targetedEmployee, SetTargetedEmployee] = useState("");
  const [serviceAreaCode, SetServiceAreaCode] = useState("");
  const [serviceAreaCodeList, SetServiceAreaCodeList] = useState([]);
  const [getJobPostList, setGetJobPostList] = useState([]);
  const [mode, setMode] = useState("Submit");
  const [jobPostCode, setjobPostCode] = useState("");
  const [locationshow, setlocationshow] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/jobPost/getMyJobPost`, {
        headers: {
          "auth-token": localStorage.getItem("authToken"),
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          setGetJobPostList(res.data.jobsList);
          setlocationshow(res.data.jobsList[0].location);
        }
      })
      .catch((error) => {
        toast.error("Some Error Occurred. Please Try After Some Time", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }, []);

  const reload = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/jobPost/getMyJobPost`, {
        headers: {
          "auth-token": localStorage.getItem("authToken"),
        },
      })
      .then((res) => {
        setGetJobPostList(res.data.jobsList);
      })
      .catch((error) => {
        toast.error("Some Error Occurred. Please Try After Some Time", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  //Fetch location list
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/jobPost/getLocationList`, {
        headers: {
          "auth-token": localStorage.getItem("authToken"),
        },
      })
      .then((res) => {
        let LocationList = res.data.locationList;

        setgetLocationList([]);
        LocationList.map((item) => {
          setgetLocationList((pre) => [
            ...pre,
            {
              value: item._id,
              label: item.state + ", " + item.city + ", " + item.area,
            },
          ]);
        });
      })
      .catch((error) => {
        toast.error("Some Error Occurred. Please Try After Some Time", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }, []);

  //Fetch Technology list
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/jobPost/getTechList`, {
        headers: {
          "auth-token": localStorage.getItem("authToken"),
        },
      })
      .then((res) => {
        setTechnicalSkillsList(res.data.techList);
      })
      .catch((error) => {
        toast.error("Some Error Occurred. Please Try After Some Time", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }, []);

  //Fetch Company list
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/jobPost/getCompanyList`, {
        headers: {
          "auth-token": localStorage.getItem("authToken"),
        },
      })
      .then((res) => {
        setCompanyList(res.data.companyList);
      })
      .catch((error) => {
        toast.error("Some Error Occurred. Please Try After Some Time", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }, []);

  //Fetch Experience list
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/jobPost/getExpList`, {
        headers: {
          "auth-token": localStorage.getItem("authToken"),
        },
      })
      .then((res) => {
        setExperienceYearList(res.data.expList);
      })
      .catch((error) => {
        toast.error("Some Error Occurred. Please Try After Some Time", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }, []);

  //Fetch salary list
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/jobPost/getSalaryRange`, {
        headers: {
          "auth-token": localStorage.getItem("authToken"),
        },
      })
      .then((res) => {
        setSalaryRangeList(res.data.salaryRangeList);
      })
      .catch((error) => {
        toast.error("Some Error Occurred. Please Try After Some Time", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }, []);

  //Fetch services list
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/jobPost/getServiceAreaList`, {
        headers: {
          "auth-token": localStorage.getItem("authToken"),
        },
      })
      .then((res) => {
        SetServiceAreaCodeList(res.data.serviceAreaList);
      })
      .catch((error) => {
        toast.error("Some Error Occurred. Please Try After Some Time", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }, []);

  // Save job post

  const handleApi = (e) => {
    const toastId = toaster("load");

    let locvalue = [];
    for (let index = 0; index < getLocation.length; index++) {
      var element = getLocation[index].value;
      locvalue.push({ location_code: element });
    }

    e.preventDefault();
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/jobPost/saveJobPost`,
        {
          tech_code: technicalSkills,
          locations: locvalue,
          company_code: company,
          salary_range_code: salaryRange,
          exp_code: experienceYear,
          service_area_code: serviceAreaCode,
          targeted_employee: targetedEmployee,
          job_title: title,
          designation: designation,
          description: description,
          email: email,
          ph_num: phnumber,
        },

        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      )
      .then((response) => {
        toaster(response.data.status, toastId, response.data.mssg);
        if (response.data.status === "success") {
          setEmail("");
          setTitle("");
          setDescription("");
          setDesignation("");
          setCompany("");
          setPhNumber("");
          setgetLocation("");
          setExperienceYear("");
          setSalaryRange("");
          setTechnicalSkills("");
          SetServiceAreaCode("");
          SetTargetedEmployee("");
          setMode("Submit");
          reload();
          navigate("/thankyou");
        }
        if (response.data.status === "error") {
        }
      })
      .catch((error) => {
        toaster(
          "error",
          toastId,
          "Some Error Occurred. Please Try After Some Time"
        );
      });
  };

  // Edit job post

  const handleEdit = (jobpostcode) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/jobPost/getJobPostDetails`,
        {
          job_post_code: jobpostcode,
        },

        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      )
      .then((response) => {
        if (response.data.status === "success") {
          let jobDetails = response.data.jobsDetails[0];

          setEmail(response.data.jobsDetails[0].email);
          setTitle(response.data.jobsDetails[0].job_title);
          setDescription(response.data.jobsDetails[0].description);
          setDesignation(response.data.jobsDetails[0].designation);
          response.data.jobsDetails[0].company_details.length > 0
            ? setCompany(jobDetails.company_details[0]._id)
            : setCompany("");
          response.data.jobsDetails[0].technology.length > 0
            ? setTechnicalSkills(jobDetails.technology[0]._id)
            : setTechnicalSkills("");
          response.data.jobsDetails[0].salary_range.length > 0
            ? setSalaryRange(jobDetails.salary_range[0]._id)
            : setSalaryRange("");
          response.data.jobsDetails[0].experience_master.length > 0
            ? setExperienceYear(jobDetails.experience_master[0]._id)
            : setExperienceYear("");
          response.data.jobsDetails[0].service_area_details.length > 0
            ? SetServiceAreaCode(jobDetails.service_area_details[0]._id)
            : SetServiceAreaCode("");

          const locationArray = [];
          for (let id = 0; id < jobDetails.location.length; id++) {
            const item = jobDetails.location[id];

            locationArray.push({
              label: item.state + ", " + item.city + ", " + item.area,
              value: item._id,
            });
          }

          setgetLocation(locationArray);
          setPhNumber(response.data.jobsDetails[0].ph_num);
          SetTargetedEmployee(response.data.jobsDetails[0].targeted_employee);
          var element = document.getElementById("home-tab");
          element.classList.add("active");
          document.getElementById("profile-tab").classList.remove("active");
          var elementMain = document.getElementById("home");
          elementMain.classList.add("active", "show");
          document.getElementById("profile").classList.remove("active");
          setMode("Update");
          setjobPostCode(jobpostcode);
        }
        if (response.data.status === "error") {
          toast.error(`${response.data.mssg}`);
        }
      })
      .catch((error) => {
        toast.error("Some Error Occurred. Please Try After Some Time", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  // close job
  const handleClose = (item) => {
    const toastId = toaster("load");
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/jobPost/closeJob`,
        {
          job_post_code: item,
        },
        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      )
      .then((response) => {
        toaster(response.data.status, toastId, response.data.mssg);
        if (response.data.status === "success") {
          reload();
        }
      })
      .catch((error) => {
        toaster(
          "error",
          toastId,
          "Some Error Occurred. Please Try After Some Time"
        );
      });
  };

  // Update data api
  const handleUpdate = (e) => {
    const toastId = toaster("load");
    e.preventDefault();

    let locvalue = [];
    for (let index = 0; index < getLocation.length; index++) {
      var element = getLocation[index].value;
      locvalue.push({ location_code: element });
    }
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/jobPost/updateJobPostDetails`,
        {
          job_post_code: jobPostCode,
          tech_code: technicalSkills,
          locations: locvalue,
          company_code: company,
          salary_range_code: salaryRange,
          exp_code: experienceYear,
          service_area_code: serviceAreaCode,
          targeted_employee: targetedEmployee,
          job_title: title,
          designation: designation,
          description: description,
          email: email,
          ph_num: phnumber,
        },

        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      )
      .then((response) => {
        toaster(response.data.status, toastId, response.data.mssg);

        if (response.data.status === "success") {
          setjobPostCode("");
          setEmail("");
          setTitle("");
          setDescription("");
          setDesignation("");
          setCompany("");
          setPhNumber("");
          setgetLocation(null);
          setExperienceYear("");
          setSalaryRange("");
          setTechnicalSkills("");
          SetServiceAreaCode("");
          SetTargetedEmployee("");
          reload();
        }
        if (response.data.status === "error") {
          toast.error(`${response.data.mssg}`);
        }
      })
      .catch((error) => {
        toaster(
          "error",
          toastId,
          "Some Error Occurred. Please Try After Some Time"
        );
      });
  };

  // delete data api
  const handleDelete = (jobpostcode) => {
    const toastId = toaster("load");
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/jobPost/deleteJobsPost`,
        {
          job_post_code: jobpostcode,
        },

        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      )
      .then((response) => {
        toaster(response.data.status, toastId, response.data.mssg);
        if (response.data.status === "success") {
          reload();
        }
        if (response.data.status === "error") {
        }
      })
      .catch((error) => {
        toaster(
          "error",
          toastId,
          "Some Error Occurred. Please Try After Some Time"
        );
      });
  };

  return (
    <>
      <Nav />
      {/* html start */}
      <div className="container-fluid">
        <div className="row justify-content-around mt-2">
          <main
            className="col rounded col-xl-8 order-xl-2 col-lg-12 order-lg-1 col-md-12 col-sm-12 col-12 mt-54"
            style={{ backgroundColor: "#fff" }}
          >
            <ul
              className="nav nav-justified border-bottom osahan-line-tab mb-5 job-post-text-dark"
              id="myTab"
              role="tablist"
            >
              <li className="nav-item">
                <a
                  className="nav-link active"
                  id="home-tab"
                  data-toggle="tab"
                  href="#home"
                  role="tab"
                  aria-controls="home"
                  aria-selected="false"
                >
                  <SiPostman size={30} className="text-primary" />
                  &nbsp; Post Your Job
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  id="profile-tab"
                  data-toggle="tab"
                  href="#profile"
                  role="tab"
                  aria-controls="profile"
                  aria-selected="true"
                >
                  <BsCardChecklist size={30} className="text-primary" />
                  &nbsp; Job Post List
                </a>
              </li>
            </ul>
            <div className="tab-content" id="myTab p-3">
              <div
                className="tab-pane fade  active show"
                id="home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">Post a Job</h3>
                  </div>
                  <div className="card-body">
                    <form>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="title">Job Title</label>
                            <input
                              type="text"
                              className="form-control"
                              id="title"
                              placeholder="Enter the job title"
                              value={title}
                              onChange={(e) => setTitle(e.target.value)}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="title">Job Designation</label>
                            <input
                              type="text"
                              className="form-control"
                              id="title"
                              placeholder="Enter Your Job Designation "
                              value={designation}
                              onChange={(e) => setDesignation(e.target.value)}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="title">Email</label>
                            <input
                              type="email"
                              className="form-control"
                              id="title"
                              placeholder="Enter Your Email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="title">Contact Number</label>
                            <input
                              type="number"
                              className="form-control"
                              id="title"
                              placeholder="Enter Your Ph number"
                              value={phnumber}
                              onChange={(e) => setPhNumber(e.target.value)}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="description"
                              style={{ paddingLeft: "8px" }}
                            >
                              Location
                            </label>

                            <Select
                              onChange={(e) => setgetLocation(e)}
                              value={getLocation}
                              id="salaryRange"
                              classNamePrefix="react-select"
                              options={getLocationList}
                              isClearable={true}
                              isSearchable={true}
                              isMulti={true}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <b
                              htmlFor="description"
                              style={{ paddingLeft: "8px" }}
                            >
                              Technology
                            </b>
                            <div className="form-group">
                              <select
                                className="form-control"
                                value={technicalSkills}
                                onChange={(e) =>
                                  setTechnicalSkills(e.target.value)
                                }
                              >
                                <option>-- Select Technology --</option>
                                {technicalSkillsList.map((item, id) => {
                                  return (
                                    <>
                                      <option value={item._id} key={id._id}>
                                        {item.tech_name}
                                      </option>
                                    </>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="experienceYear">Company</label>
                            <select
                              className="form-control"
                              id="experienceYear"
                              value={company}
                              onChange={(e) => setCompany(e.target.value)}
                              required
                            >
                              <option>-- Select Company --</option>
                              {companyList.map((item, id) => {
                                return (
                                  <>
                                    <option value={item._id} key={id._id}>
                                      {item.company_name}
                                    </option>
                                  </>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="experienceYear">
                              Experience in Year
                            </label>
                            <select
                              className="form-control"
                              id="experienceYear"
                              value={experienceYear}
                              onChange={(e) =>
                                setExperienceYear(e.target.value)
                              }
                              required
                            >
                              <option>-- Select Experience in Year --</option>
                              {experienceYearList.map((item, id) => {
                                return (
                                  <>
                                    <option value={item._id} key={id._id}>
                                      {item.experience}
                                    </option>
                                  </>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="salaryRange">Salary Range</label>
                            <select
                              className="form-control"
                              id="salaryRange"
                              value={salaryRange}
                              onChange={(e) => setSalaryRange(e.target.value)}
                              required
                            >
                              <option>-- Select Salary Range --</option>
                              {salaryRangeList.map((item, id) => {
                                return (
                                  <>
                                    <option value={item._id} key={id._id}>
                                      {item.salary_range}
                                    </option>
                                  </>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="salaryRange">Service Area</label>
                            <select
                              className="form-control"
                              id="salaryRange"
                              value={serviceAreaCode}
                              onChange={(e) =>
                                SetServiceAreaCode(e.target.value)
                              }
                              required
                            >
                              <option>-- Select Service Area --</option>
                              {serviceAreaCodeList.map((item, id) => {
                                return (
                                  <>
                                    <option value={item._id} key={id._id}>
                                      {item.service_area}
                                    </option>
                                  </>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="salaryRange">
                              Targeted Employee
                            </label>
                            <select
                              className="form-control"
                              id="salaryRange"
                              onChange={(e) =>
                                SetTargetedEmployee(e.target.value)
                              }
                              value={targetedEmployee}
                              required
                            >
                              <option>-- Select Targeted Employee --</option>
                              <option>All</option>
                              <option>Fresher</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label htmlFor="description">Job Description</label>
                            <textarea
                              className="form-control"
                              id="description"
                              placeholder="Enter the job description"
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                              rows="5"
                              required
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="btn apply-btn"
                        onClick={mode === "Submit" ? handleApi : handleUpdate}
                      >
                        {mode === "Submit" ? "Submit" : "Update"}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="profile"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
              >
                <div className="table-responsive">
                  <table
                    className="table table-hover"
                    style={{ fontFamily: "sans-serif" }}
                  >
                    <thead>
                      <tr>
                        <th scope="col">TargetedEmployee</th>
                        <th scope="col">Jobtitle</th>
                        <th scope="col">Designation</th>
                        <th scope="col">Email</th>
                        <th scope="col">Phnum</th>
                        <th scope="col">PostDate&time</th>
                        <th scope="col">Technology</th>
                        <th scope="col">Location</th>
                        <th scope="col">Company</th>
                        <th scope="col">Salaryrange</th>
                        <th scope="col">Experience</th>
                        <th scope="col">ServiceArea</th>
                        <th scope="col">Description</th>
                        <th scope="col">Status</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    {getJobPostList &&
                      getJobPostList.map((item, id) => {
                        return (
                          <tbody>
                            <tr>
                              <td>{item.targeted_employee}</td>
                              <td>{item.job_title}</td>
                              <td>{item.designation}</td>
                              <td>{item.email}</td>
                              <td>{item.ph_num}</td>
                              <td>{item.post_datetime.slice(0, 10)}</td>
                              <td key={id._id}>
                                {item.technology.length > 0
                                  ? item.technology[0].tech_name
                                  : ""}
                              </td>
                              <td key={id._id}>
                                {item.location.map((item1) => {
                                  return (
                                    <div
                                      key={id}
                                    >{`${item1.area},${item1.city},${item1.state}`}</div>
                                  );
                                })}
                                {/* {item.location.length > 0
                                  ? `${item.location[0].area},${item.location[0].city},${item.location[0].state}`
                                  : ""} */}
                              </td>
                              <td key={id._id}>
                                {item.company_details.length > 0
                                  ? item.company_details[0].company_name
                                  : ""}
                              </td>
                              <td key={id._id}>
                                {item.salary_range.length > 0
                                  ? item.salary_range[0].salary_range
                                  : ""}
                              </td>
                              <td key={id._id}>
                                {item.experience_master.length > 0
                                  ? item.experience_master[0].experience
                                  : ""}
                              </td>
                              <td key={id._id}>
                                {item.service_area_details.length > 0
                                  ? item.service_area_details[0].service_area
                                  : ""}
                              </td>
                              <td>{item.description}</td>
                              <td>{item.status}</td>
                              <td
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {item.status == "Closed" ? null : (
                                  <>
                                    <FaEdit
                                      className="text-success mr-2"
                                      style={{ cursor: "pointer" }}
                                      size={20}
                                      onClick={() => handleEdit(item._id)}
                                    />
                                    <RiDeleteBin3Fill
                                      className="text-danger"
                                      style={{ cursor: "pointer" }}
                                      size={20}
                                      onClick={() => handleDelete(item._id)}
                                    />

                                    <button
                                      onClick={() => handleClose(item._id)}
                                      className="btn"
                                    >
                                      <AiFillCloseSquare
                                        className="text-warning"
                                        size={21}
                                      />
                                    </button>
                                  </>
                                )}
                              </td>
                            </tr>
                          </tbody>
                        );
                      })}
                  </table>
                </div>
              </div>
            </div>
          </main>
          <Lsidebar userDetails={userDetails} />
          <Rsidebar />
        </div>
      </div>

      {/* html start */}
    </>
  );
};

export default JobPost;
