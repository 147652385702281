/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { WhatsappShareButton, WhatsappIcon } from "react-share";
import { RotatingTriangles } from "react-loader-spinner";
import Nav from "../../Component/Nav";
import { authentication, toaster } from "../../Controller/main_controller";
import { toast } from "react-toastify";

const InternshipJobSearch = () => {
  const [userDetails, SetUserDetails] = useState("");

  const [experience, setExperience] = useState("");
  const [experienceData, SetExperienceData] = useState([]);
  const [location, setLocation] = useState("");
  const [locationData, SetLocationData] = useState([]);
  const [getpost, setGetpost] = useState([]);

  const [sortDate, setSortDate] = useState("ASC");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.title = "internship Job Search";
  }, []);

  // Get user details
  useEffect(() => {
    const fetchUserDetails = async () => {
      const userData = await authentication();
      SetUserDetails(userData);
    };
    fetchUserDetails();
  }, []);

  //Get ServiceArea list
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/internshipSearch/getServiceAreaList`,

        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      )
      .then((res) => {
        SetExperienceData(res.data.searviceAreaList);
      })
      .catch((error) => {
        toast.error("Some Error Occurred. Please Try After Some Time", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }, []);

  // get location list
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/internshipSearch/getLocationList`,

        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      )
      .then((res) => {
        SetLocationData(res.data.locationList);
      })
      .catch((error) => {
        toast.error("Some Error Occurred. Please Try After Some Time", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }, []);

  // get job list
  useEffect(() => {
    joblist();
  }, []);

  useEffect(() => {
    joblist();
  }, [experience]);

  useEffect(() => {
    joblist();
  }, [location]);

  useEffect(() => {
    joblist();
  }, [sortDate]);

  const joblist = () => {
    setLoading(true);
    setGetpost([]);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/internshipSearch/getInternshipList`,
        {
          service_area_code: experience,
          location_code: location,
          from_index: 0,
          sort_date: sortDate,
        },
        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      )
      .then((response) => {
        setLoading(false);
        if (response.data.status === "success") {
          setGetpost(response.data.intershipList);
        }
        if (response.data.status === "error") {
          // console.log(response.data);
        }
      })
      .catch((error) => {
        // toast.error("Some Error Occurred. Please Try After Some Time", {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
      });
  };

  const clearSearch = () => {
    setLocation("");
    setExperience("");
  };

  return (
    <>
      <Nav />
      <div class="py-1 rounded">
        <div class="container-fluid body-padding pt-40 rounded">
          <div className="search_filter_div">
            <div class="row justify-c-center my-2">
              <div class="col-sm-3">
                <div className="form-group">
                  <label id="nameLabel" className="form-label">
                    Search By Service Area
                  </label>
                  <select
                    class="form-control form-control-lg"
                    name="currentExperience"
                    id="currentExperience"
                    onChange={(e) => {
                      setExperience(e.target.value);
                    }}
                    value={experience}
                  >
                    <option value="">All</option>
                    {experienceData.map((item, id) => {
                      return (
                        <>
                          <option value={item._id} key={id._id}>
                            {item.service_area}
                          </option>
                        </>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div class="col-sm-3">
                <div className="form-group">
                  <label id="nameLabel" className="form-label">
                    Search By Location
                  </label>
                  <select
                    class="form-control form-control-lg"
                    name="locationList"
                    id="locationList"
                    onChange={(e) => {
                      setLocation(e.target.value);
                    }}
                    value={location}
                  >
                    <option value="">All</option>
                    {locationData.map((item, id) => {
                      return (
                        <>
                          <option value={item._id} key={id._id}>
                            {`${item.area}, ${item.city}, ${item.state}`}
                          </option>
                        </>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div class="col-sm-3">
                <div className="form-group">
                  <label id="nameLabel" className="form-label">
                    Sort By
                  </label>
                  <select
                    class="form-control form-control-lg"
                    name="sortBy"
                    id="sortBy"
                    onChange={(e) => setSortDate(e.target.value)}
                  >
                    <option value="ASC">Post Date ASC</option>
                    <option value="DESC">Post Date DESC</option>
                  </select>
                </div>
              </div>
            </div>

            <div style={{ textAlign: "center", marginBottom: "10px" }}>
              &nbsp;&nbsp;
              <span
                onClick={clearSearch}
                style={{ backgroundColor: "#ff4d4d", color: "#FFF" }}
                className="button_badges"
              >
                <i class="fa-solid fa-magnifying-glass-minus"></i>
                &nbsp; Clear All Search
              </span>
            </div>
          </div>

          <h3 className="search_list_heading">
            Here Is Your preferred internship Job List
          </h3>

          {loading == true ? (
            <center>
              <RotatingTriangles
                visible={true}
                height="80"
                width="80"
                ariaLabel="rotating-triangels-loading"
                wrapperStyle={{}}
                wrapperClass="rotating-triangels-wrapper"
              />
            </center>
          ) : (
            <div class="row mt-2">
              <table
                class="table table-m-view bg-light"
                style={{ border: "none", fontFamily: "sans-serif" }}
              >
                <thead class="thead-pink" style={{ backgroundColor: "#ffd9f3" }}>
                  <tr>
                    <th scope="col">Job posted by</th>

                    <th scope="col">Employer Name</th>
                    <th scope="col">Employer Email</th>
                    {/* <th scope="col">Fresher</th> */}

                    <th scope="col">Location</th>
                    <th scope="col">Service Area</th>
                    <th scope="col">Job Description</th>
                  </tr>
                </thead>
                {getpost != [] &&
                  getpost.map((data, i2) => {
                    return (
                      <tbody>
                        <tr key={i2}>
                          <td>{data.employee_details[0].employee_type}</td>

                          <td>{`${data.employee_details[0].first_name} ${data.employee_details[0].last_name}`}</td>

                          <td>{data.employee_details[0].email_id}</td>
                          {/* <td>{data.employee_details[0].fresher}</td> */}

                          <td>
                            {data.location.map((item1, id) => {
                              return (
                                <div
                                  key={id}
                                >{`${item1.area},${item1.city},${item1.state}`}</div>
                              );
                            })}
                          </td>
                          <td>
                            {data.service_area_details.map((item2, id2) => {
                              return (
                                <div
                                  key={id2}
                                >{`${item2.service_area}`}</div>
                              );
                            })}
                          </td>
                          <td>{data.description}</td>
                        </tr>
                      </tbody>
                    );
                  })}
              </table>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default InternshipJobSearch;
