/* eslint-disable jsx-a11y/no-distracting-elements */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Link } from "react-router-dom";

const LandingMainPage = () => {

  return (
    <>
      <div className="container-fluie bg-blue">
        <div className="row justify-content-center align-items-center display-mobile-none">
          <div className="col-lg-12 text-center text-light">
            <h3 className="pt-3">Welcome To JobsNMore</h3>
          </div>

          <div className="col-md-6">
            <h2
              className=" text-center font-jav p-1 job_reg_heding"
              style={{ marginTop: "15px" }}
            >
              <span className="h1">Are</span> <b>you an job</b>
              <span className="h1"> Seeker</span>?
            </h2>
            <div className="font-15 text-center font-myr mt-2 text-light">
              "The harder you work , The luckier you get."
            </div>
          </div>
          <div className="col-md-6">
            <h2
              className=" text-center font-jav p-1 job_reg_heding hr_reg_heding"
              style={{ marginTop: "18px" }}
            >
              <span className="h1">Are</span> <b>you an</b>
              <span className="h1"> HR</span>?
            </h2>
            <div className="font-13 text-center font-myr mt-2 text-light">
              "Without the right succession planning put to play <br /> in human resources, we build for the future without a future."
            </div>
          </div>
          <div className="col-md-12 text-center">
            <marquee width="95%" className="marque-text">Its Not Only A Job Portal, Its A Portal Where You Can Share Your Knowledge, Share Your Ideas Also. Knowledge Can Be Filtered Based
              Upon Your Technology Like Java, Salesforce, Devops Etc. Its A Free Portal Where Employees Can Find Lots Of Jobs And Also Its Free For
              Recruiters So That They Can Find Their Right Candidate. There Are Also Sections For Freshers , Experienced People, Walkin Jobs And
              Feeds Section Where Anyone Can Post Any Content. Its Easy To Use And Very Effective, So Try It Out...</marquee>
          </div>
          <div className="col-md-6">
            <div className="d-flex align-items-center justify-content-center ">
              <img
                src="img/map.png"
                alt="" className="map-img"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="d-flex align-items-center justify-content-center ">
              <img
                src="img/map1.png"
                alt="" className="map-img"
              />
            </div>
          </div>
          

          <div className="col-md-6">
            <div className="d-flex align-items-center justify-content-center ">
              <img
                src="img/image/work_2.png"
                alt="" className="img-hight-landing-page"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="d-flex align-items-center justify-content-center ">
              <img
                src="img/image/bg-preview.png"
                alt="" className="img-hight-landing-page"
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="d-flex align-items-center justify-content-center mt-1">
              <Link
                to="/job-seeker-register"
                className="btn border shadow btn-landing"
              >
                Register as an job Seeker
              </Link>
            </div>
            <div className="d-flex align-items-center justify-content-center mt-2">
              <h6 className="text-light" style={{ marginTop: "4px" }}>or</h6>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <a href="#" className="h6 text-light">
                Already have an account?{" "}
              </a>
              <Link to="/job-seeker-sign-in" className="text-danger h6">
                {" "}
                &nbsp; Login as an Job Seeker
              </Link>
            </div>
          </div>
          <div className="col-md-6">
            <div className="d-flex align-items-center justify-content-center mt-1">
              <Link
                to="/hr-register"
                className="btn border shadow btn-landing bg-pink"
              >
                Register as an HR
              </Link>
            </div>
            <div className="d-flex align-items-center justify-content-center mt-2">
              <h6 className="text-light" style={{ marginTop: "4px" }}>or</h6>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <Link to="#" className="h6 text-light">
                Already have an account?{" "}
              </Link>
              <Link to="/hr-sign-in" className="text-danger h6">
                {" "}
                &nbsp; Login as an HR
              </Link>
            </div>
          </div>

        </div>
        <div className="row justify-content-center align-items-center mobile-show">
          <div className="col-lg-12 text-center text-light pb-4">
            <h3 className="pt-3">Welcome To JobsNMore</h3>
          </div>
          <marquee width="95%" className="marque-text">Its Not Only A Job Portal, Its A Portal Where You Can Share Youe Knowledge, Share Your Ideas Also. Knowledge Can Be Filtered Based
            Upon Your Technology Like Java, Salesforce, Devops Etc. Its A Free Portal Where Employees Can Find Lots Of Jobs And Also Its Free For
            Recruiters So That They Can Find Their Right Candidate. There Are Also Sections For Freshers , Experienced People, Walkin Jobs And
            Feeds Section Where Anyone Can Post Any Content. Its Easy To Use And Very Effective, So Try It Out...</marquee>
          <div className="col-md-6 mt-3">
            <div className="d-flex align-items-center justify-content-center ">
              <img
                src="img/image/work_2.png"
                alt="" className="img-hight-landing-page"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="d-flex align-items-center justify-content-center mt-1">
              <Link
                to="/job-seeker-register"
                className="btn border shadow btn-landing"
              >
                Register as an job Seeker
              </Link>
            </div>
            <div className="d-flex align-items-center justify-content-center mt-2">
              <h6 className="text-light" style={{ marginTop: "4px" }}>or</h6>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <a href="#" className="h6 text-light">
                Already have an account?{" "}
              </a>
              <Link to="/job-seeker-sign-in" className="text-danger h6">
                {" "}
                &nbsp; Login as an Job Seeker
              </Link>
            </div>
          </div>
          <div className="col-md-6 mt-3">
            <div className="d-flex align-items-center justify-content-center ">
              <img
                src="img/image/bg-preview.png"
                alt="" className="img-hight-landing-page"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="d-flex align-items-center justify-content-center mt-1">
              <Link
                to="/hr-register"
                className="btn border shadow btn-landing bg-pink"
              >
                Register as an HR
              </Link>
            </div>
            <div className="d-flex align-items-center justify-content-center mt-2">
              <h6 className="text-light" style={{ marginTop: "4px" }}>or</h6>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <Link to="#" className="h6 text-light">
                Already have an account?{" "}
              </Link>
              <Link to="/hr-sign-in" className="text-danger h6">
                {" "}
                &nbsp; Login as an HR
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default LandingMainPage;
