/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from "react";
import { _, authentication, toaster } from "../../Controller/main_controller";
import axios from "axios";
import Nav from "../../Component/Nav";
import Rsidebar from "../../Component/Rasidebar";
import Lsidebar from "../../Component/Lasidebar";

const JobDetails = () => {
  const [userDetails, SetUserDetails] = useState([]);
  const [getJobPostList, setGetJobPostList] = useState("");
  const [techCode, setTechCode] = useState("");
  const [comapanyCode, setCompanyCode] = useState("");
  const [salaryRange, setSalaryRange] = useState("");
  const [experienceYear, setExperienceYear] = useState("");
  const [serviceAreaCode, SetServiceAreaCode] = useState("");
  const [location, setLocation] = useState([]);
  const [submitCv, setSubmitCv] = useState();
  const [jobDescription, setJobdescription] = useState("");
  const [employeeName, setEmployeeName] = useState("");
  const [employeeImage, setEmployeeImage] = useState("");
  const [employeeType, setEmployeeType] = useState("");
  const [employeeMsg, setEmployeeMsg] = useState("");
  const [employeeApplyDate, setEmployeeApplyDate] = useState("");
  const [employeeCv, setEmployeeCv] = useState("");

  useEffect(() => {
    const fetchUserDetails = async () => {
      const userData = await authentication();
      SetUserDetails(userData);
    };
    fetchUserDetails();
  }, []);

  //Download a file
  const fileUrl = `${process.env.REACT_APP_API_URL}/${employeeCv}`;
  const downloadFile = (url) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const blobURL = window.URL.createObjectURL(new Blob([blob]));
        const fileName = url.split("/").pop();
        const aTag = document.createElement("a");
        aTag.href = blobURL;
        aTag.setAttribute("download", fileName);
        document.body.appendChild(aTag);
        aTag.click();
        aTag.remove();
      });
  }

  const formatDate = (date) => {
    const dateString = date; // ISO8601 compliant dateString
    const D = new Date(dateString);
    const result =
      D.getDate() +
      "-" +
      (D.getMonth() + 1) +
      "-" +
      D.getFullYear() +
      ", " +
      D.getHours() +
      ":" +
      D.getMinutes();
    return result;
  };

  useEffect(() => {
    var path = window.location.pathname;
    var parts = path.split("/");
    var desiredValue = parts[2]; // The value you're looking for
    console.log(desiredValue);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/jobDetails/getJobDetails`,
        {
          job_post_code: desiredValue,
        },
        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      )
      .then((res) => {
        // console.log("fjhjhgjhkjk", res.data.jobsList[0].location[0]);
        if (res.data.status === "success") {
          setGetJobPostList(res.data.job_details[0]);
          setTechCode(res.data.job_details[0].technology[0].tech_name);
          setCompanyCode(
            res.data.job_details[0].company_details[0].company_name
          );
          setSalaryRange(res.data.job_details[0].salary_range[0].salary_range);
          setExperienceYear(
            res.data.job_details[0].experience_master[0].experience
          );
          SetServiceAreaCode(
            res.data.job_details[0].service_area_details[0].service_area
          );
          setLocation(res.data.job_details[0].location);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // upload cv
  const handleFile = (e) => {
    setSubmitCv(e.target.files[0]);
    console.log(e.target.files[0]);
  };

  //job apply
  const jobApply = () => {
    var path = window.location.pathname;
    var parts = path.split("/");
    var desiredValue = parts[2]; // The value you're looking for
    console.log(desiredValue);
    const toastId = toaster("load");
    const formData = new FormData();
    formData.append("job_post_code", desiredValue);
    formData.append("message", jobDescription);
    formData.append("resume", submitCv);

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/jobDetails/jobApply`,
        formData,
        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      )
      .then((response) => {
        toaster(response.data.status, toastId, response.data.mssg);
        console.log(response.data);
        if (response.data.status === "success") {
        }
        if (response.data.status === "error") {
          // console.log(response.data.mssg);
        }
      })
      .catch((error) => {
        toaster(
          "error",
          toastId,
          "Some Error Occurred. Please Try After Some Time"
        );
      });
  };

  useEffect(() => {
    var path = window.location.pathname;
    var parts = path.split("/");
    var desiredValue = parts[2]; // The value you're looking for
    console.log(desiredValue);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/jobDetails/getJobAplliedEmployeeList`,
        {
          job_post_code: desiredValue,
        },
        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      )
      .then((res) => {
        // console.log("fjhjhgjhkjk", res.data.jobApplyList[0].employee_details[0].first_name);
        if (res.data.status === "success") {
          setEmployeeName(
            `${res.data.jobApplyList[0].employee_details[0].first_name} ${res.data.jobApplyList[0].employee_details[0].last_name}`
          );
          setEmployeeImage(
            res.data.jobApplyList[0].employee_details[0].employee_image
          );
          setEmployeeType(
            res.data.jobApplyList[0].employee_details[0].employee_type
          );
          setEmployeeMsg(res.data.jobApplyList[0].message);
          setEmployeeApplyDate(
            res.data.jobApplyList[0].apply_datetime.slice(0, 10)
          );
          setEmployeeCv(res.data.jobApplyList[0].resume);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <Nav />
      <div className="py-2">
        <div className="container-fluid">
          <div className="row justify-content-around">
            <main className="col col-xl-8 order-xl-2 col-lg-12 order-lg-1 col-md-12 col-sm-12 col-12">
            {userDetails.employee_type === "Job Seeker" ? null : (
                <div class="box shadow-sm border rounded bg-white mb-3">
                  <div class="box-title border-bottom p-3">
                    <h6 class="m-0">Applied Employee List</h6>
                  </div>
                  <div class="box-body p-0">
                    <div class="p-3 d-flex align-items-center bg-light border-bottom osahan-post-header">
                      <div class="dropdown-list-image mr-3 mb-5">
                        <img
                          class="rounded-circle"
                          src={`${process.env.REACT_APP_API_URL}/${employeeImage}`}
                          alt=""
                          style={{
                            width: "100px",
                            maxWidth: "100%",
                            
                          }}
                        />
                      </div>
                      <div class="font-weight-bold mr-3">
                        <div class="text-truncate">{employeeName}</div>
                        <div class="small mb-2">{employeeType}</div>
                        <div class="small mb-3">{employeeMsg}</div>
                        <a
                          onClick={() => downloadFile(fileUrl)}
                          className="btn btn-block apply-btn"
                        >
                          Download CV
                        </a>
                      </div>
                      <span class="ml-auto mb-auto">
                        <div class="text-right text-muted pt-1">
                          {formatDate(employeeApplyDate)}
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              )}
              <div class="box shadow-sm border rounded bg-white mb-3">
                <div class="box-title border-bottom p-3">
                  <h2 class="m-0">Job Details</h2>
                </div>
                <div class="box-body ">
                  <table class="table table-borderless mb-0">
                    <tbody>
                      <tr class="border-bottom text-left">
                        <td class="p-3">Employee Type</td>
                        <td class="p-3">{getJobPostList.post_employee_type}</td>
                      </tr>
                      <tr class="border-bottom text-left">
                        <td class="p-3">Job Title</td>
                        <td class="p-3">{getJobPostList.job_title}</td>
                      </tr>
                      <tr class="border-bottom text-left">
                        <td class="p-3">Posting Date & Time</td>
                        <td class="p-3">
                          {formatDate(getJobPostList.post_datetime)}
                        </td>
                      </tr>
                      <tr class="border-bottom text-left">
                        <td class="p-3">Designation</td>
                        <td class="p-3">{getJobPostList.designation}</td>
                      </tr>
                      <tr class="border-bottom text-left">
                        <td class="p-3">Email</td>
                        <td class="p-3">{getJobPostList.email}</td>
                      </tr>
                      <tr class="border-bottom text-left">
                        <td class="p-3">Ph Number</td>
                        <td class="p-3">{getJobPostList.ph_num}</td>
                      </tr>
                      <tr class="border-bottom text-left">
                        <td class="p-3">Location</td>
                        <td class="p-3">
                          {location.map((item, id) => {
                            return (
                              <div
                                key={id}
                              >{`${item.area},${item.city},${item.state}`}</div>
                            );
                          })}
                        </td>
                      </tr>
                      <tr class="border-bottom text-left">
                        <td class="p-3">Technology</td>
                        <td class="p-3">{techCode}</td>
                      </tr>
                      <tr class="border-bottom text-left">
                        <td class="p-3">Company</td>
                        <td class="p-3">{comapanyCode}</td>
                      </tr>
                      <tr class="border-bottom text-left">
                        <td class="p-3">Salaryrange</td>
                        <td class="p-3">{salaryRange}</td>
                      </tr>
                      <tr class="border-bottom text-left">
                        <td class="p-3">Experience</td>
                        <td class="p-3">{experienceYear}</td>
                      </tr>
                      <tr class="border-bottom text-left">
                        <td class="p-3">Service Area</td>
                        <td class="p-3">{serviceAreaCode}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="box shadow-sm border rounded bg-white mb-3">
                <div class="box-title border-bottom p-3">
                  <h6 class="m-0">Job Description</h6>
                </div>
                <div class="box-body p-3">
                  <p>{getJobPostList.description}</p>
                </div>
                <br />
                {userDetails.employee_type === "Hr" ? null : (
                  <button
                    type="button"
                    data-toggle="modal"
                    data-target="#staticBackdrop1"
                    // onClick={() => jobpostcode(data._id)}
                    class="btn btn-outline-primary ml-2 mb-3 p-2 d-none d-lg-inline color-black upload-btn-text  upload-btn"
                  >
                    {" "}
                    Apply Now
                  </button>
                )}
              </div>
            
            </main>

            <Lsidebar userDetails={userDetails} />
            <Rsidebar />
            {/* modal body */}

            <div
              class="modal fade"
              id="staticBackdrop1"
              data-backdrop="static"
              data-keyboard="false"
              tabindex="-1"
              aria-labelledby="staticBackdropLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">
                      Do you want to Apply?
                    </h5>
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <div class="row justify-c-center my-2">
                      <div class="col-sm-12">
                        <div className="form-group">
                          <label id="nameLabel" className="form-label">
                            Upload Your CV Here
                          </label>
                          <input
                            type="file"
                            id="file"
                            name="file"
                            onChange={handleFile}
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div class="col-sm-12">
                        <div className="form-group">
                          <label id="nameLabel" className="form-label">
                            Message
                          </label>
                          <textarea
                            type="text"
                            placeholder="Enter your message"
                            rows={5}
                            cols="60"
                            value={jobDescription}
                            onChange={(e) => setJobdescription(e.target.value)}
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>

                    <button
                      class="btn btn-success"
                      style={{ width: "100%", borderRadius: "20px" }}
                      onClick={jobApply}
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobDetails;
