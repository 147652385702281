/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import axios from "axios";
import { _, authentication, toaster } from "../../Controller/main_controller";
import Rsidebar from "../../Component/Rasidebar";
import Lsidebar from "../../Component/Lasidebar";
import Nav from "../../Component/Nav";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const Dashboard = () => {
  const [userDetails, SetUserDetails] = useState([]);
  const [getJobPostList, setGetJobPostList] = useState([]);

  useEffect(() => {
    document.title = "Dashboard";
  }, []);

  useEffect(() => {
    const fetchUserDetails = async () => {
      const userData = await authentication();
      SetUserDetails(userData);
    };
    fetchUserDetails();
  }, []);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/hrDashboard/getApprovedJobList`,
        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      )
      .then((res) => {
        // console.log("Area",res.data.jobsList[0].location[1].state)
        if (res.data.status === "success") {
          setGetJobPostList(res.data.jobsList);
        }
      })
      .catch((error) => {
        toast.error("Some Error Occurred. Please Try After Some Time", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }, []);

  return (
    <>
      <Nav />

      <div className="mt-54">
        <div className="container-fluid">
          <div className="row justify-content-between">
            <main className="col col-xl-8 order-xl-2 col-lg-12 order-lg-1 col-md-12 col-sm-12 col-12">
              <div className="box shadow-sm border rounded bg-white mb-3 osahan-share-post">

                <ul className="nav nav-justified border-bottom osahan-line-tab sample-resume-text-dark" id="myTab" role="tablist">
                  <li className="nav-item">
                    <a className="nav-link active" id="activeTab-tab" data-toggle="tab" href="#activeTab" role="tab" aria-controls="Settings" aria-selected="true">
                      <h4 style={{ textAlign: 'left' }}> Dashboard :</h4>
                    </a>
                  </li>
                  <li className="nav-item" />
                </ul>

                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="activeTab"
                    role="tabpanel"
                    aria-labelledby="activeTab-tab"
                    style={{
                      padding: "15px",
                    }}
                  >
                    <h5
                      style={{
                        textAlign: "center",
                        margin: "13px 0px",
                        color: "#ff4d4d",
                      }}
                    >
                      Your Approved Job List and Total Applied Employee
                    </h5>

                    <div className="table-responsive">
                      <table className="table table-hover" style={{ fontFamily: "sans-serif" }}>
                        <thead>
                          <tr>
                            <th scope="col" style={{ whiteSpace: "nowrap" }}>Job title</th>
                            <th scope="col" style={{ whiteSpace: "nowrap" }}>
                              Job posted by
                            </th>
                            <th scope="col">Designation</th>
                            <th scope="col">Email</th>
                            <th scope="col" style={{ whiteSpace: "nowrap" }}>Ph number</th>
                            <th scope="col" style={{ whiteSpace: "nowrap" }}>
                              Post Date & time
                            </th>
                            <th scope="col">Technology</th>
                            <th scope="col">Location</th>
                            <th scope="col">Company</th>
                            <th scope="col" style={{ whiteSpace: "nowrap" }}>Salary Range</th>
                            <th scope="col">Experience</th>
                            <th scope="col" style={{ whiteSpace: "nowrap" }}>Service Area</th>
                            <th scope="col">Description</th>
                            <th scope="col" style={{ whiteSpace: "nowrap" }}>
                              Total Applicants
                            </th>
                          </tr>
                        </thead>
                        {getJobPostList &&
                          getJobPostList.map((item, id) => {
                            return (
                              <tbody style={{ fontFamily: "sans-serif" }}>
                                <tr key={id}>

                                  <td
                                    style={{
                                      fontWeight: "bold",
                                    }}
                                  >
                                    <Link
                                      to={`${process.env.REACT_APP_URL}/job-details/${item._id}`}
                                      target="_blank"
                                      className="a_link"
                                    >
                                      {item.job_title}
                                    </Link>
                                  </td>

                                  <td>{item.post_employee_type}</td>
                                  <td>{item.designation}</td>
                                  <td>{item.email}</td>
                                  <td>{item.ph_num}</td>
                                  <td>{item.post_datetime.slice(0, 10)}</td>
                                  <td key={id._id}>
                                    {item.technology.length > 0
                                      ? item.technology[0].tech_name
                                      : ""}
                                  </td>
                                  <td key={id._id}>
                                    {item.location.map((item1) => {
                                      return (
                                        <div
                                          key={id}
                                        >{`${item1.area},${item1.city},${item1.state}`}</div>
                                      );
                                    })}
                                  </td>
                                  <td key={id._id}>
                                    {item.company_details.length > 0
                                      ? item.company_details[0].company_name
                                      : ""}
                                  </td>
                                  <td key={id._id}>
                                    {item.salary_range.length > 0
                                      ? item.salary_range[0].salary_range
                                      : ""}
                                  </td>
                                  <td key={id._id}>
                                    {item.experience_master.length > 0
                                      ? item.experience_master[0].experience
                                      : ""}
                                  </td>
                                  <td key={id._id}>
                                    {item.service_area_details.length > 0
                                      ? item.service_area_details[0]
                                        .service_area
                                      : ""}
                                  </td>
                                  <td>{item.description}</td>

                                  <td style={{
                                    fontWeight: "bold",
                                  }}>
                                    <Link
                                      to={`${process.env.REACT_APP_URL}/job-details/${item._id}`}
                                      target="_blank"
                                      className="a_link"
                                    >
                                      {item.totalJobApplied}
                                    </Link>
                                  </td>

                                </tr>
                              </tbody>
                            );
                          })}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </main>
            <Lsidebar userDetails={userDetails} />
            <Rsidebar />
          </div>
        </div >
      </div >
    </>
  );
};

export default Dashboard;
