/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { authentication, toaster } from "../../Controller/main_controller";
import ChatBox from "./ChatBox";
import MainChat from "./MainChat";
import Rsidebar from "../../Component/Rasidebar";
import Lsidebar from "../../Component/Lasidebar";
import Nav from "../../Component/Nav";
import { toast } from "react-toastify";

export default function Message() {

  const params = useParams();
  const { chat_employee_code } = params;

  const [userDetails, SetUserDetails] = useState("");
  const [chatList, setchatList] = useState([]);
  const [chatEmployeeCode, setchatEmployeeCode] = useState(chat_employee_code === undefined ? "" : chat_employee_code);

  useEffect(() => {
    const fetchUserDetails = async () => {
      const userData = await authentication();
      SetUserDetails(userData);
    };
    fetchUserDetails();

    document.title = "Message";
    getChatList();

  }, []);

  //Get Chat list
  const getChatList = () => {
    setchatList([]);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/message/getChatList`,
        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      )
      .then((res) => {

        if (res.data.messageRoomList) {
          setchatList(res.data.messageRoomList);
        }

      })
      .catch((error) => {
        toast.error('Some Error Occurred. Please Try After Some Time', {
          position: toast.POSITION.TOP_RIGHT
        });
      });
  }

  return (
    <>
      <Nav />
      <div className="py-2 pt-80 mb-175">
        <div className="container-fluid">
          <div className="row justify-content-around">

            <Rsidebar />

            <main className="col col-xl-8 order-xl-2 col-lg-12 order-lg-1 col-md-12 col-sm-12 col-12">
              <div className="box shadow-sm rounded bg-white mb-3 osahan-chat">
                <h5 onClick={getChatList} className="pl-3 pt-3 pr-3 border-bottom mb-0 pb-3">
                  Messaging
                </h5>
                <div className="row m-0">
                  <div className="border-right col-lg-5 col-xl-4 px-0">
                    <div className="osahan-chat-left">

                      <div className="osahan-chat-list">
                        {chatList.length > 0 &&
                          chatList.map((data, i) => {
                            return (
                              <ChatBox
                                chatUserDetails={data.employee_details[0]}
                                getLastMessage={data.message[0]}
                                chat_employee_code={data.from_employee_code === userDetails._id ? data.to_employee_code : data.from_employee_code}
                                userDetails={userDetails}
                                setchatEmployeeCode={setchatEmployeeCode}
                                key={i}
                              />
                            )
                          })}
                      </div>

                    </div>
                  </div>
                  <div className="col-lg-7 col-xl-8 px-0">

                    {chatEmployeeCode === "" ?
                      <>
                        <h2 className="no-data">Please Select A Employee or Chat to Start Chat</h2>
                      </>
                      :
                      <>
                        <MainChat chatEmployeeCode={chatEmployeeCode} userDetails={userDetails} getChatList={getChatList} />
                      </>

                    }


                  </div>
                </div>
              </div>
            </main>

            <Lsidebar userDetails={userDetails} />


          </div>
        </div>
      </div>
    </>
  );
}
