/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";

import axios from "axios";
import Rsidebar from "../../Component/Rasidebar";
import Lsidebar from "../../Component/Lasidebar";
import Nav from "../../Component/Nav";
import { authentication } from "../../Controller/main_controller";
import { toast } from "react-toastify";
import { RotatingTriangles } from "react-loader-spinner";

export default function SampleResume(props) {
  const [userDetails, SetUserDetails] = useState("");
  const [cv, SetCv] = useState([]);
  const [loading, setLoading] = useState(false);

  // Get user details
  useEffect(() => {
    const fetchUserDetails = async () => {
      const userData = await authentication();
      SetUserDetails(userData);
    };
    fetchUserDetails();
  }, []);

  useEffect(() => {
    document.title = "Sample Resume";
  }, []);

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/sampleResume/getSampleResumeList`,
        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      )
      .then((res) => {
        setLoading(false);
        SetCv(res.data.sampleResumeList);
      })
      .catch((error) => {
        toast.error("Some Error Occurred. Please Try After Some Time", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }, []);

  return (
    <>
      <Nav />
      <div className="my-2">
        <div className="container-fluid">
          <div className="row justify-content-between">
            <main className="col col-xl-8 order-xl-2 col-lg-12 order-lg-1 col-md-12 col-sm-12 col-12 mt-54">
              <div className="box shadow-sm border rounded bg-white mb-3 osahan-share-post">
                <ul
                  className="nav nav-justified border-bottom osahan-line-tab sample-resume-text-dark"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="activeTab-tab"
                      data-toggle="tab"
                      href="#activeTab"
                      role="tab"
                      aria-controls="Settings"
                      aria-selected="true"
                    >
                      <h4 style={{ textAlign: "left" }}> Sample Resume :</h4>
                    </a>
                  </li>

                  <li className="nav-item"></li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="activeTab"
                    role="tabpanel"
                    aria-labelledby="activeTab-tab"
                    style={{
                      padding: "15px",
                    }}
                  >
                    {loading === true ? (
                      <center>
                        <RotatingTriangles
                          visible={true}
                          height="80"
                          width="80"
                          ariaLabel="rotating-triangels-loading"
                          wrapperStyle={{}}
                          wrapperclassName="rotating-triangels-wrapper"
                        />
                      </center>
                    ) : (
                      <>
                        <div className="row">
                          {cv.length === 0 ? (
                            <div className="col-md-12">
                              <center>
                                <h2>There is no sample resume</h2>
                              </center>
                            </div>
                          ) : null}

                          {cv.map((item, id) => {
                            return (
                              <div className="col-md-4 mb-4" key={id}>
                                <div className="card" style={{ display: 'block', textAlign: 'center', paddingTop: '7px', }} >

                                  <div style={{ height: '202px', }}>
                                    <img
                                      src={`${process.env.REACT_APP_API_URL}/${item.resume_image}`}
                                      className="card-img-top crdimg"
                                      alt="..."
                                      style={{
                                        width: 'auto',
                                        height: 'auto',
                                        maxHeight: '200px',
                                        minWidth: 'auto',
                                      }}
                                    />
                                  </div>

                                  <div className="card-body">
                                    <h5 className="card-title">{item.title}</h5>
                                    <p className="card-text">
                                      {item.details.substring(0, 100)}...
                                    </p>
                                  </div>
                                  <div className="card-footer">
                                    <a
                                      href={`${process.env.REACT_APP_API_URL}/${item.resume_file}`}
                                      download
                                      target="_blank"
                                      className="btn btn-block apply-btn"
                                    >
                                      Download
                                    </a>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </main>
            <Lsidebar userDetails={userDetails} />
            <Rsidebar />
          </div>
        </div>
      </div>
    </>
  );
}
