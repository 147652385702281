import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { authentication } from "../../Controller/main_controller";
import Rsidebar from "../../Component/Rasidebar";
import Lsidebar from "../../Component/Lasidebar";
import Nav from "../../Component/Nav";
import MyBuddies from "./MyBuddies";
import AllBuddies from "./AllBuddies";
import BuddiesRequest from "./BuddiesRequest";
import SentRequest from "./SentRequest";

export default function Buddies() {

  const params = useParams();
  const { search_employee_details } = params;

  const [userDetails, SetUserDetails] = useState("");
  const [type, setType] = useState(search_employee_details === undefined ? "My Buddies" : "All Buddies");

  useEffect(() => {
    const fetchUserDetails = async () => {
      const userData = await authentication();
      SetUserDetails(userData);
    };
    fetchUserDetails();
    document.title = "Buddies";
  }, []);


  return (
    <>
      <Nav page="buddies" />
      <div class="py-2 pt-80">
        <div class="container-fluid">
          <div class="row justify-content-around">
            <main class="col col-xl-8 order-xl-2 col-lg-12 order-lg-1 col-md-12 col-sm-12 col-12">
              <div class="box shadow-sm border rounded bg-white mb-3 osahan-share-post">

                <ul
                  class="nav nav-justified border-bottom osahan-line-tab buddies-nav"
                  id="myTab"
                  role="tablist"
                >
                  <li class="nav-item">
                    <a
                      class={`nav-link ${type === "My Buddies" ? "active" : null} `}
                      id="buddies-tab"
                      data-toggle="tab"
                      href="#buddies"
                      role="tab"
                      aria-controls="My Buddies"
                      aria-selected="true"
                      onClick={() => setType("My Buddies")}
                    >
                      {" "}
                      My Buddies
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class={`nav-link ${type === "All Buddies" ? "active" : null} `}
                      id="buddies-tab"
                      data-toggle="tab"
                      href="#buddies"
                      role="tab"
                      aria-controls="All Buddies"
                      aria-selected="false"
                      onClick={() => setType("All Buddies")}
                    >
                      {" "}
                      All Buddies
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="buddies-tab"
                      data-toggle="tab"
                      href="#buddies"
                      role="tab"
                      aria-controls="Buddies Request"
                      aria-selected="false"
                      onClick={() => setType("Buddies Request")}
                    >
                      {" "}
                      Buddies Request
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="buddies-tab"
                      data-toggle="tab"
                      href="#buddies"
                      role="tab"
                      aria-controls="send_request"
                      aria-selected="false"
                      onClick={() => setType("Send Request")}
                    >
                      Send Request
                    </a>
                  </li>
                </ul>
                <div class="tab-content" id="myTabContent">

                  <div
                    class="tab-pane fade show active"
                    id="buddies"
                    role="tabpanel"
                    aria-labelledby="buddies-tab"
                  >
                    {(() => {
                      if (type === "My Buddies") {
                        return (<MyBuddies userDetails={userDetails} />);
                      }
                      else if (type === "All Buddies") {
                        return (<AllBuddies userDetails={userDetails} search_employee_details={search_employee_details} />);
                      }
                      else if (type === "Buddies Request") {
                        return (<BuddiesRequest userDetails={userDetails} />);
                      }
                      else if (type === "Send Request") {
                        return (<SentRequest userDetails={userDetails} />);
                      }
                    })()}


                  </div>

                </div>
              </div>
            </main>

            <Lsidebar userDetails={userDetails} />
            <Rsidebar />

          </div>
        </div>
      </div>
    </>
  );
}
