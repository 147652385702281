/* eslint-disable react/jsx-no-target-blank */
import { BrowserRouter, Navigate, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import React, { useState } from "react";
import CookieConsent from "react-cookie-consent";
// Landing Page
import LandingPage from "./Pages/LandingPage/LandingPage";
// import { _ } from "./Controller/main_controller";

// // Sign Pages
import SigninAsJobSeeker from "./Pages/SigninAsJobSeeker/SigninAsJobSeeker";
import SigninAsHr from "./Pages/SigninAsHr/SigninAsHr";

// // Register Pages
import HrRegister from "./Pages/HrRegister/HrRegister";
import JobSeekerRegister from "./Pages/JobSeekerRegister/JobSeekerRegister";

// // Forgot Password Pages
import HrForgotPassword from "./Pages/HrForgotPassword/HrForgotPassword";

import Home from "./Pages/Home/Home";
import JobSeekerForgotPassword from "./Pages/JobSeekerForgotPassword/JobSeekerForgotPassword";
import MyProfile from "./Pages/MyProfile/MyProfile";
import Buddies from "./Pages/Buddies/Buddies";
import ProfileEdit from "./Pages/ProfileEdit/ProfileEdit";
import JobPost from "./Pages/JobPost/JobPost";
import WalkinJobPost from "./Pages/WalkinJobPost/WalkinJobPost";
import JobSearch from "./Pages/JobSearch/JobSearch";
import FresherJobSearch from "./Pages/FresherJobSearch/FresherJobSearch";
import SampleResume from "./Pages/SampleResume/SampleResume";
import Training from "./Pages/Training/Training";
import Thankyou from "./Pages/ThankYou/Thankyou";
import Message from "./Pages/Message/Message";
import Settings from "./Pages/Settings/Settings";
import PostDetails from "./Pages/PostDetails/PostDetails";
import JobDetails from "./Pages/JobDetails/JobDetails";
import ProfileDetails from "./Pages/ProfileDetails/ProfileDetails";
import WalkInJobSearch from "./Pages/WalkInJobSearch/WalkInJobSearch";
import InternshipJobSearch from "./Pages/Internship/InternshipJobSearch";
import InternshipJobPost from "./Pages/Internship/InternshipJobPost";
import Dashboard from "./Pages/Dashboard/Dashboard";
import SearchEmployee from "./Pages/Searchemployee/SearchEmployee";
import LandingMainPage from "./Pages/LandingMainPage/LandingMainPage";

function App() {
  const hello = "Hello";
  const [login, SetLogin] = useState(
    localStorage.getItem("authToken") ? "Yes" : "No"
  );
  const [mainClass, setMainClass] = useState(
    localStorage.getItem("themeName")
      ? localStorage.getItem("themeName")
      : "black"
  );
  const [mainClassChat, setmainClassChat] = useState(
    localStorage.getItem("themeNameChat")
      ? localStorage.getItem("themeNameChat")
      : "yellow"
  );
  return (
    <>
      <div
        id="loader"
        style={{
          height: "100vh",
          width: "100%",
          zIndex: 10,
          position: "fixed",
          backgroundColor: "#0000005e",
          justifyContent: "center",
          alignItems: "center",
          display: "none",
        }}
      ></div>

      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />

      <BrowserRouter>
        <Routes>

        <Route
            path="/"
            element={
              login === "Yes" ? (
                <Navigate to="/home" replace />
              ) : (
                <LandingMainPage />
              )
            }
          /> 

          {/* <Route
            path="/"
            element={
              login === "Yes" ? (
                <Navigate to="/home" replace />
              ) : (
                <LandingPage />
              )
            }
          /> */}

          <Route
            path="/job-seeker-sign-in"
            element={
              login === "Yes" ? (
                <Navigate to="/home" replace />
              ) : (
                <SigninAsJobSeeker SetLogin={SetLogin} />
              )
            }
          />

          <Route
            path="/hr-sign-in"
            element={
              login === "Yes" ? (
                <Navigate to="/home" replace />
              ) : (
                <SigninAsHr SetLogin={SetLogin} />
              )
            }
          />

          <Route
            path="/hr-register"
            element={
              login === "Yes" ? <Navigate to="/home" replace /> : <HrRegister />
            }
          />

          <Route
            path="/job-seeker-register"
            element={
              login === "Yes" ? (
                <Navigate to="/home" replace />
              ) : (
                <JobSeekerRegister />
              )
            }
            // eta te status part ta baki ache
          />

          <Route
            path="/hr-forgot-password"
            element={
              login === "Yes" ? (
                <Navigate to="/home" replace />
              ) : (
                <HrForgotPassword />
              )
            }
          />

          <Route
            path="/job-seeker-forgot-password"
            element={
              login === "Yes" ? (
                <Navigate to="/home" replace />
              ) : (
                <JobSeekerForgotPassword />
              )
            }
          />

          <Route
            path="/home"
            element={
              login === "Yes" ? (
                <div className={mainClass}>
                  <Home />
                </div>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />

          <Route
            path="/buddies"
            element={
              login === "Yes" ? (
                <div className={mainClass}>
                  <Buddies />
                </div>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />

          <Route
            path="/buddies/:search_employee_details"
            element={
              login === "Yes" ? (
                <div className={mainClass}>
                  <Buddies />
                </div>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/dashboard"
            element={
              login === "Yes" ? (
                <div className={mainClass}>
                  <Dashboard />
                </div>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />

          <Route
            path="/my-profile"
            element={
              login === "Yes" ? (
                <div className={mainClass}>
                  <MyProfile />
                </div>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />

          <Route
            path="/edit-profile"
            element={
              login === "Yes" ? (
                <div className={mainClass}>
                  <ProfileEdit />
                </div>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />

          <Route
            path="/job-post"
            element={
              login === "Yes" ? (
                <div className={mainClass}>
                  <JobPost />
                </div>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />

          <Route
            path="/walkin-job-post"
            element={
              login === "Yes" ? (
                <div className={mainClass}>
                  <WalkinJobPost />
                </div>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />

          <Route
            path="/job-search"
            element={
              login === "Yes" ? (
                <div className={mainClass}>
                  <JobSearch />
                </div>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />

          <Route
            path="/setting"
            element={
              login === "Yes" ? (
                <div className={mainClass}>
                  <Settings
                    setMainClass={setMainClass}
                    mainClass={mainClass}
                    mainClassChat={mainClassChat}
                    setmainClassChat={setmainClassChat}
                  />
                </div>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />

          <Route
            path="/sample-resume"
            element={
              login === "Yes" ? (
                <div className={mainClass}>
                  <SampleResume />
                </div>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />

          <Route
            path="/training"
            element={
              login === "Yes" ? (
                <div className={mainClass}>
                  <Training />
                </div>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />

          <Route
            path="/fresher-job"
            element={
              login === "Yes" ? (
                <div className={mainClass}>
                  <FresherJobSearch />
                </div>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />

          <Route
            path="/walkin-job-search"
            element={
              login === "Yes" ? (
                <div className={mainClass}>
                  <WalkInJobSearch />
                </div>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/internship-job-search"
            element={
              login === "Yes" ? (
                <div className={mainClass}>
                  <InternshipJobSearch />
                </div>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/internship-job-post"
            element={
              login === "Yes" ? (
                <div className={mainClass}>
                  <InternshipJobPost />
                </div>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/search-employee"
            element={
              login === "Yes" ? (
                <div className={mainClass}>
                  <SearchEmployee />
                </div>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />

          <Route
            path="/post-details/:post_details_code"
            element={
              login === "Yes" ? (
                <div className={mainClass}>
                  <PostDetails />
                </div>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />

          <Route
            path="/job-details/:job_details_code"
            element={
              login === "Yes" ? (
                <div className={mainClass}>
                  <JobDetails />
                </div>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />

          <Route
            path="/profile-details/:profile_details_code"
            element={
              login === "Yes" ? (
                <div className={mainClass}>
                  <ProfileDetails />
                </div>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />

          <Route
            path="/message/:chat_employee_code"
            element={
              login === "Yes" ? (
                <div className={mainClass}>
                  <Message />
                </div>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />

          <Route
            path="/message/"
            element={
              login === "Yes" ? (
                <div className={mainClass}>
                  <Message />
                </div>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/thankyou"
            element={
              login === "Yes" ? (
                <div className={mainClass}>
                  <Thankyou />
                </div>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
        </Routes>
      </BrowserRouter>
      {!localStorage.getItem(
        "Accept was triggered by clicking the Accept button"
      ) && (
        <CookieConsent
          debug={true}
          buttonText="I Accept"
          buttonStyle={{ backgroundColor: "yellow" }}
          expires={365}
          style={{ backgroundColor: "GrayText", textAlign: "justify" }}
          onAccept={(acceptedByScrolling) => {
            if (acceptedByScrolling) {
              // triggered if user scrolls past threshold
              // alert("Accept was triggered by user scrolling");
            } else {
              localStorage.setItem(
                "Accept was triggered by clicking the Accept button",
                hello
              );
            }
          }}
        >
          We use cookies to enhance your user experience. By using oue
          website,you agree to our use of cookies. See our{" "}
          <a
            href="https://www.redprismgroup.com/privacy-policy"
            target="_blank"
            style={{ fontSize: "18px" }}
          >
            {" "}
            Privacy policy{" "}
          </a>{" "}
          for more.
        </CookieConsent>
      )}
    </>
  );
}

export default App;
