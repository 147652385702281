import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AiOutlineSetting, AiOutlineFileDone } from "react-icons/ai";
import { PiCertificateDuotone } from "react-icons/pi";
import { BsBagCheck } from "react-icons/bs";
import { FiSearch } from "react-icons/fi";
import { authentication } from "../Controller/main_controller";

export default function Rsidebar() {
  const [userDetails, SetUserDetails] = useState([]);

  useEffect(() => {
    const fetchUserDetails = async () => {
      const userData = await authentication();
      SetUserDetails(userData);
    };
    fetchUserDetails();
  }, []);

  return (
    <aside className="col col-xl-2 order-xl-3 col-lg-6 order-lg-3 col-md-6 col-sm-6 col-12 aside-tag">
      <div className="border rounded mb-3 aside_bar" >
        <div
          className="shadow-sm"
          style={{ paddingTop: "9px", paddingBottom: "9px",}}
        >
          {userDetails.employee_type === "Hr" ?(
          <h4 className="mt-4 mb-4 text-center">Other Option</h4>) : (
          <h4 className="mt-5 mb-4 text-center">Other Option</h4>)}
          <Link
            to="/setting"
            className="dropdown-item d-flex align-items-center"
          >
            <div>
              <div className="icon-circle-profile">
                <AiOutlineSetting size={20} className="asidebar_icon" />
              </div>
            </div>
            <div>
              <span className="font-weight-bold">Settings</span>
            </div>
          </Link>
          <Link
            to="/sample-resume"
            className="dropdown-item d-flex align-items-center"
          >
            <div>
              <div className="icon-circle-profile">
                <AiOutlineFileDone size={20} className="asidebar_icon" />
              </div>
            </div>
            <div>
              <span className="font-weight-bold">Sample Resume</span>
            </div>
          </Link>
          <Link
            to="/training"
            className="dropdown-item d-flex align-items-center"
          >
            <div>
              <div className="icon-circle-profile">
                <PiCertificateDuotone size={20} className="asidebar_icon" />
              </div>
            </div>
            <div>
              <span className="font-weight-bold">Trainings</span>
            </div>
          </Link>
          <Link
            to="/job-search"
            className="dropdown-item d-flex align-items-center"
          >
            <div>
              <div className="icon-circle-profile ">
                <FiSearch size={20} className="asidebar_icon" />
              </div>
            </div>
            <div>
              <span className="font-weight-bold">Job Search</span>
            </div>
          </Link>
          <Link
            to="/fresher-job"
            className="dropdown-item d-flex align-items-center"
          >
            <div>
              <div className="icon-circle-profile">
                <BsBagCheck size={20} className="asidebar_icon" />
              </div>
            </div>
            <div>
              <span className="font-weight-bold">Fresher Jobs Search</span>
            </div>
          </Link>
          <Link
            to="/walkin-job-search"
            className="dropdown-item d-flex align-items-center"
          >
            <div>
              <div className="icon-circle-profile">
                <FiSearch size={22} className="asidebar_icon" />
              </div>
            </div>
            <div>
              <span className="font-weight-bold">Walk-In Job Search</span>
            </div>
          </Link>
          <Link
            to="/internship-job-search"
            className="dropdown-item d-flex align-items-center"
          >
            <div>
              <div className="icon-circle-profile">
                <PiCertificateDuotone size={20} className="asidebar_icon" />
              </div>
            </div>
            <div>
              <span className="font-weight-bold">Internship Job Search</span>
            </div>
          </Link>
          {userDetails.employee_type === "Hr" ? (
            <Link
              to="/search-employee"
              className="dropdown-item d-flex align-items-center"
              style={{ borderBottom: "0px" }}
            >
              <div>
                <div className="icon-circle-profile">
                  <FiSearch size={22} className="asidebar_icon" />
                </div>
              </div>
              <div>
                <span className="font-weight-bold">Employee Search</span>
              </div>
            </Link>
          ) : null}
        </div>
      </div>

      <p className="left_right_pane_content" style={{textAlign:"justify"}}>
        Gone are the days, when it was difficult to find dream jobs
        in your area. Now in this 21st Century, time has been
        changed a lot. Latest technology with its interesting
        branches made everything easier. JobsNmore is one of the
        best creations in this way.
      </p>

    </aside>
  );
}
