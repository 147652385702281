/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { _, authentication, toaster } from "../../Controller/main_controller";
import Nav from "../../Component/Nav";
import { Link, useNavigate } from "react-router-dom";

export default function ProfileEdit() {
  useEffect(() => {
    document.title = "Profile Edit";
  }, []);
  //Protecting this page
  const [userDetails, SetUserDetails] = useState("");

  const fetchUserDetails = async () => {
    const userData = await authentication();
    SetUserDetails(userData);
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  const navigate = useNavigate();
  const [LastName, SetLastName] = useState("");
  const [FirstName, setFirstName] = useState("");
  const [username, setUsername] = useState("");
  const [Email, setEmail] = useState("");
  const [mobile, SetMobile] = useState("");
  const [Location, setLocation] = useState("");
  const [LocationList, setLocationList] = useState([]);
  const [Experience, SetExperience] = useState("");
  const [technology, setTechnology] = useState("");
  const [technologyList, setTechnologyList] = useState([]);
  const [company, SetCompany] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const [ExperienceList, SetExperienceList] = useState([]);
  const [employeeImage, setEmployeeImage] = useState("");
  const [employeeType, setEmployeeType] = useState("");
  const [employeeStatus, setEmployeeStatus] = useState("");
  const [employeeStatusIcon, setEmployeeStatusIcon] = useState("");
  const [lookingjob, setLookingjob] = useState("");
  const [noticePeriod, setnoticePeriod] = useState("");
  const [immediateJoiner, setimmediateJoiner] = useState();
  // const [ImmediateJoinerList, setImmediateJoinerList] = useState([]);
  const [freasher, setFreasher] = useState("");
  const [oldpassword, setOldPassword] = useState("");
  const [newpassword, setNewPassword] = useState("");

  // password section api
  const deletephoto = () => {
    const toastId = toaster("load");
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/profileDetails/removeProfilePhoto`,
        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      )
      .then((res) => {
        toaster(res.data.status, toastId, res.data.mssg);
        toaster("close", toastId);
        if (res.data.status === "success") {
          getMyProfileDetails();
        }
      })
      .catch((error) => {
        toaster(
          "error",
          toastId,
          "Some Error Occurred. Please Try After Some Time"
        );
      });
  };

  const passwordset = () => {
    const toastId = toaster("load");
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/profileDetails/changePassword`,
        {
          old_password: oldpassword,
          new_password: newpassword,
        },
        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      )
      .then((res) => {
        toaster(res.data.status, toastId, res.data.mssg);
      })
      .catch((error) => {
        toaster(
          "error",
          toastId,
          "Some Error Occurred. Please Try After Some Time"
        );
      });
  };
  //Fetch Company list
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/jobPost/getCompanyList`, {
        headers: {
          "auth-token": localStorage.getItem("authToken"),
        },
      })
      .then((res) => {
        setCompanyList(res.data.companyList);
      })
      .catch((error) => {
        toast.error("Some Error Occurred. Please Try After Some Time", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }, []);
  //location list
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/jobPost/getLocationList`, {
        headers: {
          "auth-token": localStorage.getItem("authToken"),
        },
      })
      .then((res) => {
        setLocationList(res.data.locationList);
      })
      .catch((error) => {
        toast.error("Some Error Occurred. Please Try After Some Time", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }, []);

  // fatch experience list
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/jobPost/getExpList`, {
        headers: {
          "auth-token": localStorage.getItem("authToken"),
        },
      })
      .then((res) => {
        SetExperienceList(res.data.expList);
      })
      .catch((error) => {
        toast.error("Some Error Occurred. Please Try After Some Time", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }, []);

  // fatch technology list
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/jobseekerRegister/getTechList`,
        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      )
      .then((res) => {
        setTechnologyList(res.data.techList);
      })
      .catch((error) => {
        toast.error("Some Error Occurred. Please Try After Some Time", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }, []);

  // Get profile details
  useEffect(() => {
    getMyProfileDetails();
  }, []);
  const getMyProfileDetails = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/profileDetails/getMyProfileDetails`,
        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      )
      .then((res) => {
        let profileDetails = res.data.profileDetails[0];

        setUsername(profileDetails.user_name);
        setFirstName(profileDetails.first_name);
        SetLastName(profileDetails.last_name);
        // setTechnology(res.data.profileDetails[0].technology[0].tech_name);
        setEmployeeImage(profileDetails.employee_image);
        setEmail(profileDetails.email_id);
        SetMobile(profileDetails.ph_num);
        setEmployeeType(profileDetails.employee_type);
        setEmployeeStatus(profileDetails.employee_status);
        setEmployeeStatusIcon(profileDetails.status_icon);
        setLookingjob(profileDetails.looking_job);
        setnoticePeriod(profileDetails.notice_period);
        setimmediateJoiner(profileDetails.immediate_joinner);
        setFreasher(profileDetails.fresher);
        profileDetails.location.length > 0
          ? setLocation(profileDetails.location[0]._id)
          : setLocation("");
        profileDetails.company_details.length > 0
          ? SetCompany(profileDetails.company_details[0]._id)
          : SetCompany("");
        profileDetails.experience_master.length > 0
          ? SetExperience(profileDetails.experience_master[0]._id)
          : SetExperience("");

        if (profileDetails.employee_type === "Hr") {
          document.getElementById("tech").style.display = "none";
          document.getElementById("experience").style.display = "none";
          document.getElementById("location").style.display = "none";
        }
        profileDetails.technology.length > 0
          ? setTechnology(profileDetails.technology[0]._id)
          : setTechnology("");
      })
      .catch((error) => {
        toast.error("Some Error Occurred. Please Try After Some Time", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleFile = (e) => {
    // document.getElementById("progress").style.display = "flex";
    const toastId = toaster("load");

    const file = e.target.files[0];
    const formdata = new FormData();
    formdata.append("employee_image", file);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/profileDetails/updateProfilePhoto`,
        formdata,
        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
            "Content-Type": "multipart/form-data",
          },
          // onUploadProgress: (event) => {
          // setProgressBar(Math.round(100 * event.loaded) / event.total);
          // },
        }
      )
      .then((res) => {
        toaster(res.data.status, toastId, res.data.mssg);

        if (res.data.status === "success") {
          getMyProfileDetails();
        }
      })
      .catch((err) => {
        toaster(
          "error",
          toastId,
          "Some Error Occurred. Please Try After Some Time"
        );
      });
  };

  const savehr = (e) => {
    e.preventDefault();

    const toastId = toaster("load");

    if (Email.match(/gmail\.com|yahoo\.com|hotmail\.com|outlook\.com/i)) {
         
          toaster('error', toastId,  "Yahoo ,Gmail, Hotmail & Outlook is not allowed for HR registration");
      return false;
    }

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/profileDetails/updateHrProfileDetails`,
        {
          first_name: FirstName,
          last_name: LastName,
          user_name: username,
          ph_num: mobile,
          email_id: Email,
          company_code: company,
        },
        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      )
      .then((res) => {
        toaster(res.data.status, toastId, res.data.mssg);
        if(res.data.status==="success"){
          navigate("/my-profile");
        }


        
      })
      .catch((err) => {
        toaster(
          "error",
          toastId,
          "Some Error Occurred. Please Try After Some Time"
        );
      });
  };

  const saveJobSeeker = (e) => {
    e.preventDefault();
    const toastId = toaster("load");

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/profileDetails/updateJobSeekerProfileDetails`,

        {
          first_name: FirstName,
          last_name: LastName,
          user_name: username,
          ph_num: mobile,
          email_id: Email,
          company_code: company,
          employee_status: employeeStatus,
          status_icon: employeeStatusIcon,
          tech_code: technology,
          exp_code: Experience,
          location_code: Location,
          looking_job: lookingjob,
          notice_period: noticePeriod,
          immediate_joinner: immediateJoiner,
          fresher: freasher,
        },
        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      )
      .then((res) => {
        toaster(res.data.status, toastId, res.data.mssg);
        navigate("/my-profile");
      })
      .catch((err) => {
        toaster(
          "error",
          toastId,
          "Some Error Occurred. Please Try After Some Time"
        );
      });
  };
  return (
    <>
      <Nav />{" "}
      <div className="my-4 mb-175 pt-40">
        <div className="container-fluid">
          <div className="row">
            <aside className="col-md-4">
              <div
                className="mb-3 border rounded  profile-box text-center w-10"
                style={{ backgroundColor: "#ffd9f3" }}
              >
                <div className="p-4 ">
                  {employeeImage && (
                    <img
                      src={`http://testredprism.co/${employeeImage}`}
                      className="img-fluid mt-2 mb-3 "
                      alt="Responsive image"
                      style={{
                        width: "auto",
                        maxWidth: "100%",
                        height: "150px",
                        borderRadius: "10%",
                      }}
                    />
                  )}

                  <div className="d-flex justify-content-center align-items-center mb-2">
                    <label
                      data-toggle="tooltip"
                      data-placement="top"
                      data-original-title="Upload New Picture"
                      className="btn btn-info m-0"
                      for="fileAttachmentBtn"
                    >
                      <i className="feather-image"></i>
                      <input
                        id="fileAttachmentBtn"
                        name="file-attachment"
                        type="file"
                        accept=".jpg, .jpeg, .png"
                        className="d-none"
                        onChange={handleFile}
                      />
                      &nbsp; Upload
                    </label>
                    <button
                      data-toggle="tooltip"
                      data-placement="top"
                      data-original-title="Delete"
                      type="submit"
                      className="btn btn-danger ml-2"
                      onClick={deletephoto}
                    >
                      <i className="feather-trash-2"></i>
                      &nbsp; Delete
                    </button>
                  </div>
                  <em
                    style={{
                      color: "GrayText",
                    }}
                  >
                    *Only .jpg, .png, .jpeg file accepted
                  </em>
                </div>
              </div>
              <div
                className=" py-3 px-3 rounded mb-m5"
                style={{ backgroundColor: "#ffd9f3" }}
              >
                <label id="nameLabel" className="form-label">
                  Old Password
                  <span className="text-danger">*</span>
                </label>
                <div className="form-group">
                  <input
                    onChange={(e) => setOldPassword(e.target.value)}
                    value={oldpassword}
                    type="password"
                    className="form-control"
                    name="name"
                    placeholder="Enter your name"
                    aria-label="Enter your name"
                    required
                    aria-describedby="nameLabel"
                    data-msg="Please enter your name."
                    data-error-className="u-has-error"
                    data-success-className="u-has-success"
                  />
                </div>
                <label id="nameLabel" className="form-label">
                  New Password
                  <span className="text-danger">*</span>
                </label>
                <div className="form-group">
                  <input
                    onChange={(e) => setNewPassword(e.target.value)}
                    value={newpassword}
                    type="password"
                    className="form-control"
                    name="name"
                    placeholder="Enter your name"
                    aria-label="Enter your name"
                    required
                    aria-describedby="nameLabel"
                    data-msg="Please enter your name."
                    data-error-className="u-has-error"
                    data-success-className="u-has-success"
                  />
                </div>
                <button
                  className="btn ml-2 apply-btn text-center"
                  onClick={passwordset}
                >
                  Set Password
                </button>
              </div>
            </aside>

            <main className="col-md-8">
              <div
                className="border rounded mb-3"
                style={{ backgroundColor: "#ffd9f3" }}
              >
                <div className="box-title border-bottom p-3">
                  <h4 className="m-0">Edit Basic Info</h4>
                </div>
                <div className="box-body p-3">
                  <form
                    // onSubmit={handleSubmit}
                    className="js-validate"
                    novalidate="novalidate"
                  >
                    <div className="row">
                      <div className="col-sm-6 mb-2">
                        <div className="js-form-message">
                          <label id="nameLabel" className="form-label">
                            First Name
                            <span className="text-danger">*</span>
                          </label>
                          <div className="form-group">
                            <input
                              onChange={(e) => setFirstName(e.target.value)}
                              value={FirstName}
                              type="text"
                              className="form-control"
                              name="name"
                              placeholder="Enter your name"
                              aria-label="Enter your name"
                              required
                              aria-describedby="nameLabel"
                              data-msg="Please enter your name."
                              data-error-className="u-has-error"
                              data-success-className="u-has-success"
                            />
                            <small className="form-text text-muted">
                              Displayed on your public profile, notifications
                              and other places.
                            </small>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 mb-2">
                        <div className="js-form-message">
                          <label id="nameLabel" className="form-label">
                            Last Name
                            <span className="text-danger">*</span>
                          </label>
                          <div className="form-group">
                            <input
                              onChange={(e) => SetLastName(e.target.value)}
                              value={LastName}
                              type="text"
                              className="form-control"
                              name="name"
                              placeholder="Enter your name"
                              aria-label="Enter your name"
                              required
                              aria-describedby="nameLabel"
                              data-msg="Please enter your name."
                              data-error-className="u-has-error"
                              data-success-className="u-has-success"
                            />
                            <small className="form-text text-muted">
                              Displayed on your public profile, notifications
                              and other places.
                            </small>
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-6 mb-2">
                        <div className="js-form-message">
                          <label id="emailLabel" className="form-label">
                            Email address
                            <span className="text-danger">*</span>
                          </label>
                          <div className="form-group">
                            <input
                              type="email"
                              className="form-control"
                              name="email"
                              onChange={(e) => setEmail(e.target.value)}
                              value={Email}
                              placeholder="Enter your email address"
                              aria-label="Enter your email address"
                              required
                              aria-describedby="emailLabel"
                              data-msg="Please enter a valid email address."
                              data-error-className="u-has-error"
                              data-success-className="u-has-success"
                            />
                            <small className="form-text text-muted">
                              We'll never share your email with anyone else.
                            </small>
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-6 mb-2" id="location">
                        <div className="js-form-message">
                          <label id="locationLabel" className="form-label">
                            Location
                            <span className="text-danger">*</span>
                          </label>
                          <div className="form-group">
                            <select
                              className="form-control"
                              id="experienceYear"
                              value={Location}
                              onChange={(e) => setLocation(e.target.value)}
                              required
                            >
                              {/* <option>{Company}</option> */}
                              {LocationList.map((item, id) => {
                                return (
                                  <>
                                    <option value={item._id} key={id._id}>
                                      {`${item.area} , ${item.city} , ${item.state}`}
                                    </option>
                                  </>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-6 mb-2">
                        <div className="js-form-message">
                          <label id="organizationLabel" className="form-label">
                            Organization
                            <span className="text-danger">*</span>
                          </label>
                          <select
                            className="form-control"
                            id="experienceYear"
                            value={company}
                            onChange={(e) => SetCompany(e.target.value)}
                            required
                          >
                            {/* <option>{Company}</option> */}
                            {companyList.map((item, id) => {
                              return (
                                <>
                                  <option value={item._id} key={id._id}>
                                    {item.company_name}
                                  </option>
                                </>
                              );
                            })}
                          </select>
                        </div>
                      </div>

                      <div className="col-sm-6 mb-2" id="experience">
                        <div className="js-form-message">
                          <label id="websiteLabel" className="form-label">
                            Experience
                          </label>
                          <div className="form-group">
                            <select
                              className="form-control"
                              id="experienceYear"
                              onChange={(e) => SetExperience(e.target.value)}
                              value={Experience}
                              required
                            >
                              {/* <option>{Experience}</option> */}
                              {ExperienceList.map((item, id) => {
                                return (
                                  <>
                                    <option value={item._id} key={id._id}>
                                      {item.experience}
                                    </option>
                                  </>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-6 mb-2">
                        <div className="js-form-message">
                          <label id="phoneNumberLabel" className="form-label">
                            Mobile number
                            <span className="text-danger">*</span>
                          </label>
                          <div className="form-group">
                            <input
                              className="form-control"
                              type="tel"
                              name="phoneNumber"
                              onChange={(e) => SetMobile(e.target.value)}
                              value={mobile}
                              placeholder="Enter your phone number"
                              aria-label="Enter your phone number"
                              required
                              aria-describedby="phoneNumberLabel"
                              data-msg="Please enter a valid phone number"
                              data-error-className="u-has-error"
                              data-success-className="u-has-success"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 mb-2">
                        <div className="js-form-message">
                          <label id="usernameLabel" className="form-label">
                            Username
                            <span className="text-danger">*</span>
                          </label>
                          <div className="form-group">
                            <input
                              value={username}
                              onChange={(e) => setUsername(e.target.value)}
                              type="text"
                              className="form-control"
                              name="username"
                              placeholder="Enter your username"
                              aria-label="Enter your username"
                              required
                              aria-describedby="usernameLabel"
                              data-msg="Please enter your username."
                              data-error-className="u-has-error"
                              data-success-className="u-has-success"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 mb-2" id="tech">
                        <div className="js-form-message">
                          <label id="websiteLabel" className="form-label">
                            Skills
                          </label>
                          <div className="form-group">
                            <select
                              className="form-control"
                              onChange={(e) => setTechnology(e.target.value)}
                              required
                              value={technology}
                            >
                              {/* <option>{technology}</option> */}
                              {technologyList.map((item, id) => {
                                return (
                                  <>
                                    <option value={item._id} key={id._id}>
                                      {item.tech_name}
                                    </option>
                                  </>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 mb-2">
                        <div className="js-form-message">
                          <label id="websiteLabel" className="form-label">
                            Employee Type
                          </label>
                          <div className="form-group">
                            <select
                              className="form-control"
                              id="experienceYear"
                              disabled
                            >
                              <option>{employeeType}</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 mb-2" id="tech">
                        <div className="js-form-message">
                          <label id="websiteLabel" className="form-label">
                            Immediate Joinner
                          </label>
                          <div className="form-group">
                            <select
                              className="form-control"
                              onChange={(e) =>
                                setimmediateJoiner(e.target.value)
                              }
                              value={immediateJoiner}
                              required
                            >
                              <option>{immediateJoiner}</option>
                              <option>
                                {immediateJoiner === "Yes"
                                  ? "No"
                                  : immediateJoiner === "No"
                                  ? "Yes"
                                  : ""}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 mb-2" id="tech">
                        <div className="js-form-message">
                          <label id="websiteLabel" className="form-label">
                            Fresher
                          </label>
                          <div className="form-group">
                            <select
                              className="form-control"
                              value={freasher}
                              onChange={(e) => setFreasher(e.target.value)}
                              // value={freasher==="No"?document.getElementById("yes").innerText="Yes":document.getElementById("no").innerText="No"}
                              required
                            >
                              <option id="yes">{freasher}</option>
                              <option id="no">
                                {freasher === "Yes"
                                  ? "No"
                                  : freasher === "No"
                                  ? "Yes"
                                  : ""}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 mb-2" id="tech">
                        <div className="js-form-message">
                          <label id="websiteLabel" className="form-label">
                            Notice Period
                          </label>
                          <div className="form-group">
                            <select
                              className="form-control"
                              onChange={(e) => setnoticePeriod(e.target.value)}
                              value={noticePeriod}
                              required
                            >
                              <option>{noticePeriod}</option>
                              <option>
                                {noticePeriod === "Yes"
                                  ? "No"
                                  : noticePeriod === "No"
                                  ? "Yes"
                                  : ""}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 mb-2" id="tech">
                        <div className="js-form-message">
                          <label id="websiteLabel" className="form-label">
                            Looking Job
                          </label>
                          <div className="form-group">
                            <select
                              className="form-control"
                              value={lookingjob}
                              onChange={(e) => setLookingjob(e.target.value)}
                              // value={freasher==="No"?document.getElementById("yes").innerText="Yes":document.getElementById("no").innerText="No"}
                              required
                            >
                              <option id="yes">{lookingjob}</option>
                              <option id="no">
                                {lookingjob === "Yes"
                                  ? "No"
                                  : lookingjob === "No"
                                  ? "Yes"
                                  : ""}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div className="mb-3 text-right d-flex-mobile">
                <button
                  className="font-weight-bold btn rounded p-3  savechanges btn-light btn-profile-edit"
                  href="#"
                >
                  {" "}
                  Cancel{" "}
                </button>
                <button
                  onClick={
                    employeeType === "Job Seeker" ? saveJobSeeker : savehr
                  }
                  className="font-weight-bold btn rounded p-3  savechanges btn-warning btn-profile-edit"
                  style={{whiteSpace:'nowrap',width:"150px"}}
                >
                  Save Changes{" "}
                </button>
              </div>
            </main>
          </div>
        </div>
      </div>
    </>
  );
}
