/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import axios from "axios";
import Rsidebar from "../../Component/Rasidebar";
import Lsidebar from "../../Component/Lasidebar";
import Nav from "../../Component/Nav";

import { authentication, toaster } from "../../Controller/main_controller";

export default function Settings({
  setMainClass,
  mainClass,
  mainClassChat,
  setmainClassChat,
}) {
  const [userDetails, SetUserDetails] = useState("");

  // Get user details
  useEffect(() => {
    const fetchUserDetails = async () => {
      const userData = await authentication();
      SetUserDetails(userData);
    };
    fetchUserDetails();
    document.title = "Settings";
  }, []);

  const setTheme = (themeName) => {
    setMainClass(themeName);
    localStorage.setItem("themeName", themeName);
  };

  // chat theme part

  const setChatTheme = (themeNameChat) => {
    setmainClassChat(themeNameChat);
    localStorage.setItem("themeNameChat", themeNameChat);
  };

  return (
    <>
      <Nav />
      <div class="my-2">
        <div class="container-fluid">
          <div class="row justify-content-between">
            <main class="col col-xl-8 order-xl-2 col-lg-12 order-lg-1 col-md-12 col-sm-12 col-12 mt-54">

              <div class="box shadow-sm border rounded bg-white mb-3 osahan-share-post">
                <ul
                  class="nav nav-justified border-bottom osahan-line-tab setting-text-dark"
                  id="myTab"
                  role="tablist"
                >
                  <li class="nav-item">
                    <a
                      class="nav-link active"
                      id="activeTab-tab"
                      data-toggle="tab"
                      href="#activeTab"
                      role="tab"
                      aria-controls="Settings"
                      aria-selected="true"
                    >
                      <h4 style={{ textAlign: "left" }}>
                        {" "}
                        Change Your Settings
                      </h4>
                    </a>
                  </li>

                  <li class="nav-item display-none-mobile"></li>
                </ul>
                <div class="tab-content" id="myTabContent">
                  <div
                    class="tab-pane fade show active"
                    id="activeTab"
                    role="tabpanel"
                    aria-labelledby="activeTab-tab"
                    style={{
                      padding: "15px",
                    }}
                  >
                    <h5>Choose Your Theme :</h5>

                    <div className="row">
                      <div className="col-md-4">
                        <div className="card text-white bg-dark mb-3 theme_div">
                          <div className="card-header">
                            <h5
                              style={{ margin: "0px" }}
                              className="card-title"
                            >
                              Black Theme
                            </h5>
                          </div>
                          <div className="card-body">
                            <p className="card-text text-white">
                              Here you get black theme
                            </p>
                            <center>
                              {mainClass === "black" ? (
                                <button type="button" class="btn btn-info">
                                  This is your current theme
                                </button>
                              ) : (
                                <button
                                  onClick={() => setTheme("black")}
                                  type="button"
                                  class="btn btn-warning"
                                >
                                  Active This Theme
                                </button>
                              )}
                            </center>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div
                          className="card text-white mb-3 theme_div"
                          style={{ backgroundColor: "#f88484" }}
                        >
                          <div className="card-header">
                            <h5
                              style={{ margin: "0px" }}
                              className="card-title"
                            >
                              Red Theme
                            </h5>
                          </div>
                          <div className="card-body">
                            <p className="card-text text-white">
                              Here you get red theme
                            </p>
                            <center>
                              {mainClass === "red" ? (
                                <button type="button" class="btn btn-info">
                                  This is your current theme
                                </button>
                              ) : (
                                <button
                                  onClick={() => setTheme("red")}
                                  type="button"
                                  class="btn btn-warning"
                                >
                                  Active This Theme
                                </button>
                              )}
                            </center>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div
                          className="card text-white mb-3 theme_div"
                          style={{ backgroundColor: "rgb(132 141 248)" }}
                        >
                          <div className="card-header">
                            <h5
                              style={{ margin: "0px" }}
                              className="card-title"
                            >
                              Blue Theme
                            </h5>
                          </div>
                          <div className="card-body">
                            <p className="card-text text-white">
                              Here you get blue theme
                            </p>
                            <center>
                              {mainClass === "blue" ? (
                                <button type="button" class="btn btn-info">
                                  This is your current theme
                                </button>
                              ) : (
                                <button
                                  onClick={() => setTheme("blue")}
                                  type="button"
                                  class="btn btn-warning"
                                >
                                  Active This Theme
                                </button>
                              )}
                            </center>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="box shadow-sm border rounded bg-white mb-3 osahan-share-post">
                <ul
                  class="nav nav-justified border-bottom osahan-line-tab setting-text-dark"
                  id="myTab"
                  role="tablist"
                >
                  <li class="nav-item">
                    <a
                      class="nav-link active"
                      id="activeTab-tab"
                      data-toggle="tab"
                      href="#activeTab"
                      role="tab"
                      aria-controls="Settings"
                      aria-selected="true"
                    >
                      <h4 style={{ textAlign: "left" }}>
                        {" "}
                        Change Your Chat Theme
                      </h4>
                    </a>
                  </li>

                  <li class="nav-item display-none-mobile"></li>
                </ul>
                <div class="tab-content" id="myTabContent">
                  <div
                    class="tab-pane fade show active"
                    id="activeTab"
                    role="tabpanel"
                    aria-labelledby="activeTab-tab"
                    style={{
                      padding: "15px",
                    }}
                  >
                    <h5>Choose Your Theme :</h5>

                    <div className="row">
                      <div className="col-md-4">
                        <div className="card text-white bg-warning mb-3 theme_div">
                          <div className="card-header">
                            <h5
                              style={{ margin: "0px" }}
                              className="card-title"
                            >
                              Yellow & Orenge Theme
                            </h5>
                          </div>
                          <div className="card-body">
                            <p className="card-text text-white">
                              Here you get Yellow & Orenge theme
                            </p>
                            <center>
                              {mainClassChat === "yellow" ? (
                                <button type="button" class="btn btn-info">
                                  This is your current theme
                                </button>
                              ) : (
                                <button
                                  onClick={() => setChatTheme("yellow")}
                                  type="button"
                                  class="btn btn-warning"
                                >
                                  Active This Theme
                                </button>
                              )}
                            </center>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="card text-white mb-3 theme_div bg-success">
                          <div className="card-header">
                            <h5
                              style={{ margin: "0px" }}
                              className="card-title"
                            >
                              Green & Yellow Theme
                            </h5>
                          </div>
                          <div className="card-body">
                            <p className="card-text text-white">
                              Here you get Green & Yellow theme
                            </p>
                            <center>
                              {mainClassChat === "Green" ? (
                                <button type="button" class="btn btn-info">
                                  This is your current theme
                                </button>
                              ) : (
                                <button
                                  onClick={() => setChatTheme("Green")}
                                  type="button"
                                  class="btn btn-warning"
                                >
                                  Active This Theme
                                </button>
                              )}
                            </center>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div
                          className="card text-white mb-3 theme_div"
                          style={{ backgroundColor: "rgb(132 141 248)" }}
                        >
                          <div className="card-header">
                            <h5
                              style={{ margin: "0px" }}
                              className="card-title"
                            >
                              Blue Theme
                            </h5>
                          </div>
                          <div className="card-body">
                            <p className="card-text text-white">
                              Here you get blue theme
                            </p>
                            <center>
                              {mainClassChat === "White" ? (
                                <button type="button" class="btn btn-info">
                                  This is your current theme
                                </button>
                              ) : (
                                <button
                                  onClick={() => setChatTheme("White")}
                                  type="button"
                                  class="btn btn-warning"
                                >
                                  Active This Theme
                                </button>
                              )}
                            </center>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </main>
            <Lsidebar userDetails={userDetails} />
            <Rsidebar />
          </div>
        </div>
      </div>


    </>
  );
}
