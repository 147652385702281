import React, { useEffect, useState } from "react";
import axios from "axios";
import { _, authentication, toaster } from "../../Controller/main_controller";
import { RotatingTriangles } from "react-loader-spinner";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { MdReport } from "react-icons/md";

export default function AllBuddies({ userDetails, search_employee_details }) {
  const [buddiesList, setbuddiesList] = useState([]);
  const [searchData, setsearchData] = useState(
    search_employee_details === undefined ? "" : search_employee_details
  );

  const [loading, setLoading] = useState(false);
  const [reportEid, setReportEid] = useState("");
  const [reportMassage, setReportmassage] = useState("");

  useEffect(() => {
    getBuddies();
  }, []);

  useEffect(() => {
    getBuddies();
  }, [searchData]);

  // report Section
  const setingupeid = (e_id) => {
    setReportEid(e_id);
    console.log(e_id);
  };
  const handelreport = () => {
    const toastId = toaster("load");
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/reportToAdmin/saveReport`,
        {
          report_to_employee_code: reportEid,
          message: reportMassage,
        },

        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      )
      .then((response) => {
        toaster(response.data.status, toastId, response.data.mssg);
        setReportmassage("");

        if (response.data.status === "error") {
          toast.error(`${response.data.mssg}`);
        }
      })
      .catch((error) => {
        toast.error("Some Error Occurred. Please Try After Some Time", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  // Get All Buddies
  const getBuddies = () => {
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/buddies/showBuddysList`,
        {
          type: "All Buddies",
          from_index: 0,
          search_details: searchData,
        },
        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      )
      .then((response) => {
        setLoading(false);

        if (response.data.employeeList) {
          setbuddiesList(response.data.employeeList);
        } else {
          setbuddiesList([]);
        }
      })
      .catch((error) => {
        // console.error(error);
        toast.error("Some Error Occurred. Please Try After Some Time", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  return (
    <>
      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Report to the Admin{" "}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <textarea
                placeholder="Report something that dosen't look right..."
                className="form-control"
                style={{ border: "1px solid gray" }}
                value={reportMassage}
                onChange={(e) => setReportmassage(e.target.value)}
              ></textarea>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={handelreport}
                data-dismiss="modal"
              >
                Report
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="p-3  align-items-center w-100" href="#">
        <div className="row pt-3 pb-3 text-center">
          <div className="col-lg-4" style={{ textAlign: "left" }}>
            <b className="h4" style={{ marginLeft: "10px" }}>
              All Buddies
            </b>{" "}
          </div>
          <div className="col-lg-3"> </div>

          <div className="col-lg-4">
            <form
              className="d-none d-sm-inline-block form-inline m-auto my-md-0 mw-100 navbar-search border"
              style={{ borderRadius: "30px" }}
            >
              <div className="input-group ">
                <input
                  type="text"
                  className="form-control shadow-none border-0 search-btn search-placeholder small-search"
                  value={searchData}
                  onChange={(e) => setsearchData(e.target.value)}
                  placeholder="Search..."
                  aria-label="Search"
                  aria-describedby="basic-addon2"
                />
                <div className="input-group-append">
                  <button
                    onClick={getBuddies}
                    className="btn search-icon"
                    type="button"
                  >
                    <i className="feather-search searchicon"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className="row justify-content-center">
          {loading == true ? (
            <RotatingTriangles
              visible={true}
              height="80"
              width="80"
              ariaLabel="rotating-triangels-loading"
              wrapperStyle={{}}
              wrapperclassName="rotating-triangels-wrapper"
            />
          ) : buddiesList.length === 0 ? (
            <h4 className="no-data">No Data Found</h4>
          ) : (
            <div className="col-md-12 justify-content-between ">
              <div className="row">
                {buddiesList.map((data, i) => {
                  return (
                    <div className="col-md-4">
                      <div className="border network-item rounded mb-3">
                        <div className="p-3 d-flex align-items-center network-item-header">
                          <div className="dropdown-list-image mr-3">
                            <img
                              className="rounded-circle"
                              src={`${process.env.REACT_APP_API_URL}/${data.employee_image}`}
                              alt
                            />
                          </div>
                          <div className="font-weight-bold">
                            <Link
                              to={`${process.env.REACT_APP_URL}/profile-details/${data._id}`}
                              className="font-weight-bold text-dark mb-0 h6"
                            >
                              {data.first_name}
                              &nbsp;
                              {data.last_name}
                            </Link>
                            <div className="small text-black-50">
                              {data.employee_type}
                            </div>
                          </div>

                          <button
                            type="button"
                            className="btn pt-0 btn-report"
                            data-toggle="modal"
                            data-target="#exampleModal"
                            onClick={() => setingupeid(data._id)}
                          >
                            <MdReport className="text-danger" />
                          </button>
                        </div>

                        <div className="network-item-footer pb-3 d-flex text-center">
                          <GetButton
                            from_details={data.from_employee_buddies}
                            to_details={data.to_employee_buddies}
                            employee_id={data._id}
                            getBuddies={getBuddies}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export function GetButton({
  from_details,
  to_details,
  employee_id,
  getBuddies,
}) {
  const cancelSentRequest = (employee_id) => {
    const toastId = toaster("load");
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/buddies/cancelSentRequest`,
        {
          to_employee_code: employee_id,
        },
        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      )
      .then((response) => {
        toaster(response.data.status, toastId, response.data.mssg);

        if (response.data.status === "success") {
          getBuddies();
        }
      })
      .catch((error) => {
        // console.error(error);
        toaster(
          "error",
          toastId,
          "Some Error Occurred. Please Try After Some Time"
        );
      });
  };

  const removeBuddie = (employee_id) => {
    const toastId = toaster("load");
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/buddies/removeFriend`,
        {
          buddie_employee_code: employee_id,
        },
        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      )
      .then((response) => {
        toaster(response.data.status, toastId, response.data.mssg);

        if (response.data.status === "success") {
          getBuddies();
        }
      })
      .catch((error) => {
        // console.error(error);
        toaster(
          "error",
          toastId,
          "Some Error Occurred. Please Try After Some Time"
        );
      });
  };

  const acceptRequest = (employee_id) => {
    const toastId = toaster("load");
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/buddies/acceptBuddyRequest`,
        {
          from_employee_code: employee_id,
        },
        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      )
      .then((response) => {
        toaster(response.data.status, toastId, response.data.mssg);

        if (response.data.status === "success") {
          getBuddies();
        }
      })
      .catch((error) => {
        // console.error(error);
        toaster(
          "error",
          toastId,
          "Some Error Occurred. Please Try After Some Time"
        );
      });
  };

  const cancelRequest = (employee_id) => {
    const toastId = toaster("load");
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/buddies/cancelFriendRequest`,
        {
          from_employee_code: employee_id,
        },
        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      )
      .then((response) => {
        toaster(response.data.status, toastId, response.data.mssg);

        if (response.data.status === "success") {
          getBuddies();
        }
      })
      .catch((error) => {
        // console.error(error);
        toaster(
          "error",
          toastId,
          "Some Error Occurred. Please Try After Some Time"
        );
      });
  };

  const sentRequest = (employee_id) => {
    const toastId = toaster("load");
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/buddies/sentBuddyRequest`,
        {
          to_employee_code: employee_id,
        },
        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      )
      .then((response) => {
        toaster(response.data.status, toastId, response.data.mssg);

        if (response.data.status === "success") {
          getBuddies();
        }
      })
      .catch((error) => {
        // console.error(error);
        toaster(
          "error",
          toastId,
          "Some Error Occurred. Please Try After Some Time"
        );
      });
  };

  if (to_details.length > 0 && to_details[0].mode === "Request") {
    // login User send request to other user
    return (
      <div className="col-12 pr-3 pl-1">
        <button
          type="button"
          onClick={() => cancelSentRequest(employee_id)}
          className="btn btn-outline-danger btn-sm btn-block"
        >
          <i className="fa-solid fa-user-xmark"></i> Delete Send request
        </button>
      </div>
    );
  }

  if (
    (to_details.length > 0 && to_details[0].mode === "Friend") ||
    (from_details.length > 0 && from_details[0].mode === "Friend")
  ) {
    // login User send request to other user
    return (
      <>
        <div className="col-6 pr-3 pl-1">
          <Link to={`/message/${employee_id}`}>
            <button type="button" className="btn btn-info btn-sm btn-block">
              <i className="feather-send"></i> Chat
            </button>
          </Link>
        </div>
        <div className="col-6 pr-3 pl-1">
          <button
            type="button"
            onClick={() => removeBuddie(employee_id)}
            className="btn btn-outline-danger btn-sm btn-block"
          >
            <i className="fa-solid fa-user-minus"></i> Unfriend
          </button>
        </div>
      </>
    );
  }

  if (from_details.length > 0 && from_details[0].mode === "Request") {
    // login User send request to other user
    return (
      <>
        <div className="col-6 pr-3 pl-1">
          <button
            type="button"
            onClick={() => acceptRequest(employee_id)}
            className="btn btn-outline-info btn-sm btn-block"
          >
            <i className="fa-regular fa-circle-check"></i> Accept
          </button>
        </div>
        <div className="col-6 pr-3 pl-1">
          <button
            type="button"
            onClick={() => cancelRequest(employee_id)}
            className="btn btn-outline-warning btn-sm btn-block"
          >
            <i className="fa-solid fa-ban"></i> Cancel
          </button>
        </div>
      </>
    );
  }

  if (from_details.length === 0 && to_details.length === 0) {
    return (
      <div className="col-12 pr-3 pl-1">
        <button
          type="button"
          onClick={() => sentRequest(employee_id)}
          className="btn btn-outline-success btn-sm btn-block"
        >
          <i className="fa-solid fa-paper-plane"></i> Send Request
        </button>
      </div>
    );
  }
}
